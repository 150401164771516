export const DEFAULT_STATE = {
  carrierSourcingListData: {},
  successSaveFiltersData: {},
  getFiltersData: {},
  deleteFiltersData: {},
  saveCarrierRatingsData: {},
  templateListDataValue: {},
  carrierSourcingDownloadExcelData: {},
  updateCarrierDetailsData: {},
  getColumnOrderFilterData: {},
  saveColumnOrderData: {},
  originSearchListData: {},
  destinationSearchListData: {},
  searchCarrierListData: {},
  downloadCarrierSourcingExcelData: {},
  originDestinationDetail: {},
  carrierDetailData: {},
  successUpdateFiltersData: {},
  successUpdateBasicDetailData: {},
  successUpdateOperationsData: {},
  successUpdateCarrierContactData: {},
  successUpdateDispatcherContactData: {},
  successUpdateAuthorityDetailData: {},
  successUpdateFocusAreaData: {},
  savenotesdata: {},
  successMyCarrierDeleteData: {},
  getMyCarrierData: {},
  updateMyCarrierDetailData: {},
  addMyCarrierDetailData: {},
  mycarrierListData: {},
  getMarketDataGraphData: {},
  additionalMarketData: {},
  headquatersListData: {},
  carrierSourcingSelectAllEmailDownloadExcelData: {},
  advanceSearchEquipmentData: {},
  advanceMarketEquipmentData: {},
  dashboardDataSorceListData: {},
  userListData: {},
  carrierUserListData: {},
  lanesAndMapData: {},
  headquartersSearchValueListData : {},
  destinationMarketSearchListData: {},

};
