// List Manage Staff
export const LIST_MANAGE_STAFF = "LIST_MANAGE_STAFF";
export const listManageStaff = (data) => ({ type: LIST_MANAGE_STAFF, data });
export const SUCCESS_LIST_MANAGE_STAFF = "SUCCESS_LIST_MANAGE_STAFF";
export const ERROR_LIST_MANAGE_STAFF = "ERROR_LIST_MANAGE_STAFF";
export const listManageStaffResponse = (type, data) => ({ type, data });

//Staff update details
export const STAFF_UPDATE_DETAILS = "STAFF_UPDATE_DETAILS";
export const StaffUpdateDetails = (data) => ({
  type: STAFF_UPDATE_DETAILS,
  data,
});
export const SUCCESS_STAFF_UPDATE_DETAILS = "SUCCESS_STAFF_UPDATE_DETAILS";
export const ERROR_STAFF_UPDATE_DETAILS = "ERROR_STAFF_UPDATE_DETAILS";
export const staffUpdateDetailsResponse = (type, data) => ({ type, data });

//Send User Credentials
export const SEND_USER_CREDENTIALS = "SEND_USER_CREDENTIALS";
export const sendUserCredentials = (data) => ({
  type: SEND_USER_CREDENTIALS,
  data,
});
export const SUCCESS_SEND_USER_CREDENTIALS = "SUCCESS_SEND_USER_CREDENTIALS";
export const ERROR_SEND_USER_CREDENTIALS = "ERROR_SEND_USER_CREDENTIALS";
export const sendUserCredentialsResponse = (type, data) => ({ type, data });

//Reset-Password-Action
export const RESET_PASSWORD_MANAGESTAFF = "RESET_PASSWORD_MANAGESTAFF";
export const resetPasswordManageStaff = (data) => ({
  type: RESET_PASSWORD_MANAGESTAFF,
  data,
});
export const SUCCESS_RESET_PASSWORD_MANAGESTAFF =
  "SUCCESS_RESET_PASSWORD_MANAGESTAFF";
export const ERROR_RESET_PASSWORD_MANAGESTAFF =
  "ERROR_RESET_PASSWORD_MANAGESTAFF";
export const resetPasswordManageStaffResponse = (type, data) => ({
  type,
  data,
});

//Delete User
export const DELETE_USER = "DELETE_USER";
export const deleteUser = (data) => ({ type: DELETE_USER, data });
export const SUCCESS_DELETE_USER = "SUCCESS_DELETE_USER";
export const ERROR_DELETE_USER = "ERROR_DELETE_USER";
export const deleteUserResponse = (type, data) => ({ type, data });

//ADD NEW USER
export const NEW_USER_MANAGESTAFF = "ADD_NEW_USER_MANAGESTAFF";
export const newUserManageStaff = (data) => ({
  type: NEW_USER_MANAGESTAFF,
  data,
});
export const SUCCESS_NEW_USER_MANAGESTAFF = "SUCCESS_NEW_USER_MANAGESTAFF";
export const ERROR_NEW_USER_MANAGESTAFF = "ERROR_NEW_USER_MANAGESTAFF";
export const newUserManageStaffResponse = (type, data) => ({ type, data });

//ADD NEW USER
export const CHECK_USER_EMAIL = "CHECK_USER_EMAIL";
export const checkUserEmail = (data) => ({ type: CHECK_USER_EMAIL, data });
export const SUCCESS_CHECK_USER_EMAIL = "SUCCESS_CHECK_USER_EMAIL";
export const ERROR_CHECK_USER_EMAIL = "ERROR_CHECK_USER_EMAIL";
export const checkUserEmailResponse = (type, data) => ({ type, data });
//Staff set permission
export const STAFF_SET_PERMISSION = "STAFF_SET_PERMISSION";
export const staffsetpermission = (data) => ({
  type: STAFF_SET_PERMISSION,
  data,
});
export const SUCCESS_STAFF_SET_PERMISSION = "SUCCESS_STAFF_SET_PERMISSION";
export const ERROR_STAFF_SET_PERMISSION = "ERROR_STAFF_SET_PERMISSION";
export const staffsetpermissionResponse = (type, data) => ({ type, data });

//Staff get permission
export const STAFF_GET_PERMISSION = "STAFF_GET_PERMISSION";
export const staffgetpermission = (data) => ({
  type: STAFF_GET_PERMISSION,
  data,
});
export const SUCCESS_STAFF_GET_PERMISSION = "SUCCESS_STAFF_GET_PERMISSION";
export const ERROR_STAFF_GET_PERMISSION = "ERROR_STAFF_GET_PERMISSION";
export const staffgetpermissionResponse = (type, data) => ({ type, data });

//Staff get permission
export const STAFF_PERMISSION_MODULE = "STAFF_PERMISSION_MODULE";
export const staffpermissionmodule = (data) => ({
  type: STAFF_PERMISSION_MODULE,
  data,
});
export const SUCCESS_STAFF_PERMISSION_MODULE =
  "SUCCESS_STAFF_PERMISSION_MODULE";
export const ERROR_STAFF_PERMISSION_MODULE = "ERROR_STAFF_PERMISSION_MODULE";
export const staffpermissionmoduleResponse = (type, data) => ({ type, data });



//Staff get permission
export const MANAGE_STAFF_GET_PERMISSION = "MANAGE_STAFF_GET_PERMISSION";
export const manageStaffGetPermission = (data) => ({
  type: MANAGE_STAFF_GET_PERMISSION,
  data,
});
export const SUCCESS_MANAGE_STAFF_GET_PERMISSION = "SUCCESS_MANAGE_STAFF_GET_PERMISSION";
export const ERROR_MANAGE_STAFF_GET_PERMISSION = "ERROR_MANAGE_STAFF_GET_PERMISSION";
export const manageStaffGetPermissionResponse = (type, data) => ({ type, data });
//Staff restore
export const STAFF_RESTORE = "STAFF_RESTORE";
export const staffRestore = (data) => ({
  type: STAFF_RESTORE,
  data,
});
export const SUCCESS_STAFF_RESTORE = "SUCCESS_STAFF_RESTORE";
export const ERROR_STAFF_RESTORE = "ERROR_STAFF_RESTORE";
export const staffRestoreResponse = (type, data) => ({ type, data });

//EDIT USER PROFILE
export const EDIT_USER_MANAGESTAFF = "ADD_EDIT_USER_MANAGESTAFF";
export const editUserManageStaff = (data) => ({
  type: EDIT_USER_MANAGESTAFF,
  data,
});
export const SUCCESS_EDIT_USER_MANAGESTAFF = "SUCCESS_EDIT_USER_MANAGESTAFF";
export const ERROR_EDIT_USER_MANAGESTAFF = "ERROR_EDIT_USER_MANAGESTAFF";
export const editUserManageStaffResponse = (type, data) => ({ type, data });
//Staff update rfp alert
export const STAFF_UPDATE_RFP_ALERT = "STAFF_UPDATE_RFP_ALERT";
export const staffUpdateRfpAlert = (data) => ({
  type: STAFF_UPDATE_RFP_ALERT,
  data,
});
export const SUCCESS_STAFF_UPDATE_RFP_ALERT = "SUCCESS_STAFF_UPDATE_RFP_ALERT";
export const ERROR_STAFF_UPDATE_RFP_ALERT = "ERROR_STAFF_UPDATE_RFP_ALERT";
export const staffUpdateRfpAlertResponse = (type, data) => ({ type, data });
