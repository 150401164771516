// CARRIER_SOURCING_LIST
export const CARRIER_SOURCING_LIST = "CARRIER_SOURCING_LIST";
export const carrierSourcingList = (data) => ({
  type: CARRIER_SOURCING_LIST,
  data,
});
export const SUCCESS_CARRIER_SOURCING_LIST = "SUCCESS_CARRIER_SOURCING_LIST";
export const ERROR_CARRIER_SOURCING_LIST = "ERROR_CARRIER_SOURCING_LIST";
export const carrierSourcingListResponse = (type, data) => ({ type, data });

//SAVE_FILTERS
export const SAVE_FILTERS = "SAVE_FILTERS";
export const saveFilters = (data) => ({ type: SAVE_FILTERS, data });
export const SUCCESS_SAVE_FILTERS = "SUCCESS_SAVE_FILTERS";
export const ERROR_SAVE_FILTERS = "ERROR_SAVE_FILTERS";
export const saveFiltersResponse = (type, data) => ({ type, data });

//GET_FILTERS
export const GET_FILTERS = "GET_FILTERS";
export const getFilters = (data) => ({ type: GET_FILTERS, data });
export const SUCCESS_GET_FILTERS = "SUCCESS_GET_FILTERS";
export const ERROR_GET_FILTERS = "ERROR_GET_FILTERS";
export const getFiltersResponse = (type, data) => ({ type, data });

//carrierFiltersDeleteApi
export const DELETE_FILTERS = "DELETE_FILTERS";
export const deleteFilters = (data) => ({ type: DELETE_FILTERS, data });
export const SUCCESS_DELETE_FILTERS = "SUCCESS_DELETE_FILTERS";
export const ERROR_DELETE_FILTERS = "ERROR_DELETE_FILTERS";
export const deleteFiltersResponse = (type, data) => ({ type, data });

//saveCarrierRatingApi
export const SAVE_CARRIER_RATING = "SAVE_CARRIER_RATING";
export const saveCarrierFilters = (data) => ({
  type: SAVE_CARRIER_RATING,
  data,
});
export const SUCCESS_SAVE_CARRIER_RATING = "SUCCESS_SAVE_CARRIER_RATING";
export const ERROR_SAVE_CARRIER_RATING = "ERROR_SAVE_CARRIER_RATING";
export const saveCarrierFiltersResponse = (type, data) => ({ type, data });

//Email Template list
export const TEMPLATE_LIST_ = "TEMPLATE_LIST_";
export const template_List_ = (data) => ({ type: TEMPLATE_LIST_, data });
export const SUCCESS_TEMPLATE_LIST_ = "SUCCESS_TEMPLATE_LIST_";
export const ERROR_TEMPLATE_LIST_ = "ERROR_TEMPLATE_LIST_";
export const templateListResponseData = (type, data) => ({ type, data });

//carrierSourcingDownloadExcelApi
export const DOWNLOAD_CARRIER_SOURCING_EXCEL =
  "DOWNLOAD_CARRIER_SOURCING_EXCEL";
export const downloadCarrierSourcingExel = (data) => ({
  type: DOWNLOAD_CARRIER_SOURCING_EXCEL,
  data,
});
export const SUCCESS_DOWNLOAD_CARRIER_SOURCING_EXCEL =
  "SUCCESS_DOWNLOAD_CARRIER_SOURCING_EXCEL";
export const ERROR_DOWNLOAD_CARRIER_SOURCING_EXCEL =
  "ERROR_DOWNLOAD_CARRIER_SOURCING_EXCEL";
export const downloadCarrierSourcingExcelResponse = (type, data) => ({
  type,
  data,
});

//carrierUpdateDetails
export const UPDATE_CARRIER_DETAILS = "UPDATE_CARRIER_DETAILS";
export const updateCarrierDetials = (data) => ({
  type: UPDATE_CARRIER_DETAILS,
  data,
});
export const SUCCESS_UPDATE_CARRIER_DETAILS = "SUCCESS_UPDATE_CARRIER_DETAILS";
export const ERROR_UPDATE_CARRIER_DETAILS = "ERROR_UPDATE_CARRIER_DETAILS";
export const updateCarrierDetialsResponse = (type, data) => ({ type, data });

//GET_COLUMN_ORDER_FILTER
export const GET_COLUMN_ORDER_FILTER = "GET_COLUMN_ORDER_FILTER";
export const getColumnOrderFilter = (data) => ({
  type: GET_COLUMN_ORDER_FILTER,
  data,
});
export const SUCCESS_GET_COLUMN_ORDER_FILTER =
  "SUCCESS_GET_COLUMN_ORDER_FILTER";
export const ERROR_GET_COLUMN_ORDER_FILTER = "ERROR_GET_COLUMN_ORDER_FILTER";
export const getColumnOrderFilterResponse = (type, data) => ({ type, data });

//Save_Column_Order
export const SAVE_COLUMN_ORDER = "SAVE_COLUMN_ORDER";
export const saveColumnOrder = (data) => ({ type: SAVE_COLUMN_ORDER, data });
export const SUCCESS_SAVE_COLUMN_ORDER = "SUCCESS_SAVE_COLUMN_ORDER";
export const ERROR_SAVE_COLUMN_ORDER = "ERROR_SAVE_COLUMN_ORDER";
export const saveColumnOrderResponse = (type, data) => ({ type, data });

// GET_ORIGIN_LIST
export const ORIGIN_SEARCH_LIST = "ORIGIN_SEARCH_LIST";
export const originSearchList = (data) => ({ type: ORIGIN_SEARCH_LIST, data });
export const SUCCESS_ORIGIN_SEARCH_LIST = "SUCCESS_ORIGIN_SEARCH_LIST";
export const ERROR_ORIGIN_SEARCH_LIST = "ERROR_ORIGIN_SEARCH_LIST";
export const originSearchListResponse = (type, data) => ({ type, data });

// GET_DEST_LIST
export const DESTINATION_SEARCH_LIST = "DESTINATION_SEARCH_LIST";
export const destinationSearchList = (data) => ({
  type: DESTINATION_SEARCH_LIST,
  data,
});
export const SUCCESS_DESTINATION_SEARCH_LIST =
  "SUCCESS_DESTINATION_SEARCH_LIST";
export const ERROR_DESTINATION_SEARCH_LIST = "ERROR_DESTINATION_SEARCH_LIST";
export const destinationSearchListResponse = (type, data) => ({ type, data });

// SEARCH_CARRIER_LIST
export const SEARCH_CARRIER_LIST = "SEARCH_CARRIER_LIST";
export const searchCarrierList = (data) => ({
  type: SEARCH_CARRIER_LIST,
  data,
});
export const SUCCESS_SEARCH_CARRIER_LIST = "SUCCESS_SEARCH_CARRIER_LIST";
export const ERROR_SEARCH_CARRIER_LIST = "ERROR_SEARCH_CARRIER_LIST";
export const searchCarrierListResponse = (type, data) => ({ type, data });

// DOWNLOAD_CARRIER_DATA_EXCEL
export const DOWNLOAD_CARRIER_DATA_EXCEL = "DOWNLOAD_CARRIER_DATA_EXCEL";
export const downloadCarrierDataExcel = (data) => ({
  type: DOWNLOAD_CARRIER_DATA_EXCEL,
  data,
});
export const SUCCESS_DOWNLOAD_CARRIER_DATA_EXCEL =
  "SUCCESS_DOWNLOAD_CARRIER_DATA_EXCEL";
export const ERROR_DOWNLOAD_CARRIER_DATA_EXCEL =
  "ERROR_DOWNLOAD_CARRIER_DATA_EXCEL";
export const downloadCarrierDataExcelResponse = (type, data) => ({
  type,
  data,
});

// ORIGIN_DESTINATION_DETAIL
export const ORIGIN_DESTINATION_DETAIL = "ORIGIN_DESTINATION_DETAIL";
export const originDestinationDetail = (data) => ({
  type: ORIGIN_DESTINATION_DETAIL,
  data,
});
export const SUCCESS_ORIGIN_DESTINATION_DETAIL =
  "SUCCESS_ORIGIN_DESTINATION_DETAIL";
export const ERROR_ORIGIN_DESTINATION_DETAIL =
  "ERROR_ORIGIN_DESTINATION_DETAIL";
export const originDestinationDetailResponse = (type, data) => ({ type, data });

// CARRIER_DETAIL
export const CARRIER_DETAIL = "CARRIER_DETAIL";
export const carrierDetail = (data) => ({ type: CARRIER_DETAIL, data });
export const SUCCESS_CARRIER_DETAIL = "SUCCESS_CARRIER_DETAIL";
export const ERROR_CARRIER_DETAIL = "ERROR_CARRIER_DETAIL";
export const carrierDetailResponse = (type, data) => ({ type, data });

//UPDATE_FILTERS
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const updateFilters = (data) => ({ type: UPDATE_FILTERS, data });
export const SUCCESS_UPDATE_FILTERS = "SUCCESS_UPDATE_FILTERS";
export const ERROR_UPDATE_FILTERS = "ERROR_UPDATE_FILTERS";
export const updateFiltersResponse = (type, data) => ({ type, data });

//SAVE-NOTES
export const SAVE_NOTES = "SAVE_NOTES";
export const saveNotes = (data) => ({ type: SAVE_NOTES, data });
export const SUCCESS_SAVE_NOTES = "SUCCESS_SAVE_NOTES";
export const ERROR_SAVE_NOTES = "ERROR_SAVE_NOTES";
export const saveNotesResponse = (type, data) => ({ type, data });

//UPDATE-BASIC-DETAIL
export const UPDATE_BASIC_DETAIL = "UPDATE_BASIC_DETAIL";
export const updateBasicDetail = (data) => ({
  type: UPDATE_BASIC_DETAIL,
  data,
});
export const SUCCESS_UPDATE_BASIC_DETAIL = "SUCCESS_UPDATE_BASIC_DETAIL";
export const ERROR_UPDATE_BASIC_DETAIL = "ERROR_UPDATE_BASIC_DETAIL";
export const updateBasicDetailResponse = (type, data) => ({ type, data });

//UPDATE-OPERATIONS
export const UPDATE_OPERATIONS = "UPDATE_OPERATIONS";
export const updateOperations = (data) => ({ type: UPDATE_OPERATIONS, data });
export const SUCCESS_UPDATE_OPERATIONS = "SUCCESS_UPDATE_OPERATIONS";
export const ERROR_UPDATE_OPERATIONS = "ERROR_UPDATE_OPERATIONS";
export const updateOperationsResponse = (type, data) => ({ type, data });

//UPDATE-CARRIER-CONTACT
export const UPDATE_CARRIER_CONTACT = "UPDATE_CARRIER_CONTACT";
export const updateCarrierContact = (data) => ({
  type: UPDATE_CARRIER_CONTACT,
  data,
});
export const SUCCESS_UPDATE_CARRIER_CONTACT = "SUCCESS_UPDATE_CARRIER_CONTACT";
export const ERROR_UPDATE_CARRIER_CONTACT = "ERROR_UPDATE_CARRIER_CONTACT";
export const updateCarrierContactResponse = (type, data) => ({ type, data });

//UPDATE-DISPATCHER-CONTACT
export const UPDATE_DISPATCHER_CONTACT = "UPDATE_DISPATCHER_CONTACT";
export const updateDispatcherContact = (data) => ({
  type: UPDATE_DISPATCHER_CONTACT,
  data,
});
export const SUCCESS_UPDATE_DISPATCHER_CONTACT =
  "SUCCESS_UPDATE_DISPATCHER_CONTACT";
export const ERROR_UPDATE_DISPATCHER_CONTACT =
  "ERROR_UPDATE_DISPATCHER_CONTACT";
export const updateDispatcherContactResponse = (type, data) => ({ type, data });

//UPDATE-AUTHORITY-DETAIL
export const UPDATE_AUTHORITY_DETAIL = "UPDATE_AUTHORITY_DETAIL";
export const updateAuthorityDetail = (data) => ({
  type: UPDATE_AUTHORITY_DETAIL,
  data,
});
export const SUCCESS_UPDATE_AUTHORITY_DETAIL =
  "SUCCESS_UPDATE_AUTHORITY_DETAIL";
export const ERROR_UPDATE_AUTHORITY_DETAIL = "ERROR_UPDATE_AUTHORITY_DETAIL";
export const updateAuthorityDetailResponse = (type, data) => ({ type, data });

//UPDATE-FOCUS-AREA
export const UPDATE_FOCUS_AREA = "UPDATE_FOCUS_AREA";
export const updateFocusArea = (data) => ({ type: UPDATE_FOCUS_AREA, data });
export const SUCCESS_UPDATE_FOCUS_AREA = "SUCCESS_UPDATE_FOCUS_AREA";
export const ERROR_UPDATE_FOCUS_AREA = "ERROR_UPDATE_FOCUS_AREA";
export const updateFocusAreaResponse = (type, data) => ({ type, data });

//myCarrierDeleteApi
export const MY_DELETE_CARRIER = "MY_DELETE_CARRIER";
export const deleteMyCarrier = (data) => ({ type: MY_DELETE_CARRIER, data });
export const SUCCESS_MY_DELETE_CARRIER = "SUCCESS_MY_DELETE_CARRIER";
export const ERROR_MY_DELETE_CARRIER = "ERROR_MY_DELETE_CARRIER";
export const deleteMyCarrierResponse = (type, data) => ({ type, data });

//GET_CARRIER
export const MY_CARRIER_GET = "MY_CARRIER_GET";
export const getMyCarrier = (data) => ({ type: MY_CARRIER_GET, data });
export const SUCCESS_MY_CARRIER_GET = "SUCCESS_MY_CARRIER_GET";
export const ERROR_MY_CARRIER_GET = "ERROR_MY_CARRIER_GET";
export const getMyCarrierResponse = (type, data) => ({ type, data });

//carrierUpdate
export const UPDATE_MY_CARRIER_DETAIL = "UPDATE_MY_CARRIER_DETAIL";
export const updateMyCarrierDetail = (data) => ({
  type: UPDATE_MY_CARRIER_DETAIL,
  data,
});
export const SUCCESS_UPDATE_MY_CARRIER_DETAIL =
  "SUCCESS_UPDATE_MY_CARRIER_DETAIL";
export const ERROR_UPDATE_MY_CARRIER_DETAIL = "ERROR_UPDATE_MY_CARRIER_DETAIL";
export const updateMyCarrierDetailResponse = (type, data) => ({ type, data });

//ADD_MY_CARRIER_DETAIL
export const MY_CARRIER_ADD = "MY_CARRIER_ADD";
export const addMyCarrier = (data) => ({ type: MY_CARRIER_ADD, data });
export const SUCCESS_MY_CARRIER_ADD = "SUCCESS_MY_CARRIER_ADD";
export const ERROR_MY_CARRIER_ADD = "ERROR_MY_CARRIER_ADD";
export const addMyCarrierDetailResponse = (type, data) => ({ type, data });

// MY_GET_CARRIER_LIST
export const GET_MY_CARRIER_BY_ID = "GET_MY_CARRIER_BY_ID";
export const getMyCarrierById = (data) => ({
  type: GET_MY_CARRIER_BY_ID,
  data,
});
export const SUCCESS_GET_MY_CARRIER_BY_ID = "SUCCESS_GET_MY_CARRIER_BY_ID";
export const ERROR_GET_MY_CARRIER_BY_ID = "ERROR_GET_MY_CARRIER_BY_ID";
export const getMyCarrierByIdResponse = (type, data) => ({ type, data });

// MARKET_DATA_GRAPH
export const GET_MARKET_DATA_GRAPH = "GET_MARKET_DATA_GRAPH";
export const getMarketDataGraph = (data) => ({
  type: GET_MARKET_DATA_GRAPH,
  data,
});
export const SUCCESS_GET_MARKET_DATA_GRAPH = "SUCCESS_GET_MARKET_DATA_GRAPH";
export const ERROR_GET_MARKET_DATA_GRAPH = "ERROR_GET_MARKET_DATA_GRAPH";
export const getMarketDataGraphResponse = (type, data) => ({ type, data });

// ADDITIONAL_MARKET_DATA
export const GET_ADDITIONAL_MARKET_DATA = "GET_ADDITIONAL_MARKET_DATA";
export const getAdditionalMarketData = (data) => ({
  type: GET_ADDITIONAL_MARKET_DATA,
  data,
});
export const SUCCESS_GET_ADDITIONAL_MARKET_DATA =
  "SUCCESS_GET_ADDITIONAL_MARKET_DATA";
export const ERROR_GET_ADDITIONAL_MARKET_DATA =
  "ERROR_GET_ADDITIONAL_MARKET_DATA";
export const getAdditionalMarketDataResponse = (type, data) => ({ type, data });

//carrierSourcingDownloadExcelApi
export const EMAIL_SELECTALL_DOWNLOAD_CARRIER_SOURCING_EXCEL =
  "EMAIL_SELECTALL_DOWNLOAD_CARRIER_SOURCING_EXCEL";
export const selectEmailALLDownloadCarrierSourcingExel = (data) => ({
  type: EMAIL_SELECTALL_DOWNLOAD_CARRIER_SOURCING_EXCEL,
  data,
});
export const SUCCESS_EMAIL_SELECTALL_DOWNLOAD_CARRIER_SOURCING_EXCEL =
  "SUCCESS_EMAIL_SELECTALL_DOWNLOAD_CARRIER_SOURCING_EXCEL";
export const ERROR_EMAIL_SELECTALL_DOWNLOAD_CARRIER_SOURCING_EXCEL =
  "ERROR_EMAIL_SELECTALL_DOWNLOAD_CARRIER_SOURCING_EXCEL";
export const selectEmailALLDownloadCarrierSourcingExcelResponse = (
  type,
  data
) => ({
  type,
  data,
});


// GET HEADQUARTERS LIST
export const HEADQUARTERS_SEARCH_LIST = "HEADQUARTERS_SEARCH_LIST";
export const headquartersSearchList = (data) => ({ type: HEADQUARTERS_SEARCH_LIST, data });
export const SUCCESS_HEADQUARTERS_SEARCH_LIST = "SUCCESS_HEADQUARTERS_SEARCH_LIST";
export const ERROR_HEADQUARTERS_SEARCH_LIST = "ERROR_HEADQUARTERS_SEARCH_LIST";
export const headquartersSearchListResponse = (type, data) => ({ type, data });

// advance search equipment type 
export const ADVANCE_SEARCH_EQUIPMENT_LIST = "ADVANCE_SEARCH_EQUIPMENT_LIST"
export const advanceSearchEquipmentList = (data) => ({ type: ADVANCE_SEARCH_EQUIPMENT_LIST, data })
export const SUCCESS_ADVANCE_SEARCH_EQUIPMENT_LIST = "SUCCESS_ADVANCE_SEARCH_EQUIPMENT_LIST"
export const ERROR_ADVANCE_SEARCH_EQUIPMENT_LIST = "ERROR_ADVANCE_SEARCH_EQUIPMENT_LIST"
export const advanceSearchEquipmentListResponse = (type, data) => ({ type, data })

// advance market search equipment type

export const ADVANCE_MARKET_EQUIPMENT_LIST = "ADVANCE_MARKET_EQUIPMENT_LIST"
export const advanceMarketEquipmentList = (data) => ({ type: ADVANCE_MARKET_EQUIPMENT_LIST, data })
export const SUCCESS_ADVANCE_MARKET_EQUIPMENT_LIST = "SUCCESS_ADVANCE_MARKET_EQUIPMENT_LIST"
export const ERROR_ADVANCE_MARKET_EQUIPMENT_LIST = "ERROR_ADVANCE_MARKET_EQUIPMENT_LIST"
export const advanceMarketEquipmentListResponse = (type, data) => ({ type, data })

// data source list

export const DASHBOARD_DATA_SOURCE_LIST = "DASHBOARD_DATA_SOURCE_LIST"
export const dashboardDataSorceList = (data) => ({ type: DASHBOARD_DATA_SOURCE_LIST, data })
export const SUCCESS_DASHBOARD_DATA_SOURCE_LIST = "SUCCESS_DASHBOARD_DATA_SOURCE_LIST"
export const ERROR_DASHBOARD_DATA_SOURCE_LIST = "ERROR_DASHBOARD_DATA_SOURCE_LIST"
export const dashboardDataSorceListResponse = (type, data) => ({ type, data })

// user list 
export const DASHBOARD_USER_LIST = "DASHBOARD_USER_LIST"
export const dashboardUserList = (data) => ({ type: DASHBOARD_USER_LIST, data })
export const SUCCESS_DASHBOARD_USER_LIST = "SUCCESS_DASHBOARD_USER_LIST"
export const ERROR_DASHBOARD_USER_LIST = "ERROR_DASHBOARD_USER_LIST"
export const dashboardUserListResonse = (type, data) => ({ type, data })

// user list for select carrier 
export const CARRIER_USER_LIST = "CARRIER_USER_LIST"
export const carrierUserList = (data) => ({ type: CARRIER_USER_LIST, data })
export const SUCCESS_CARRIER_USER_LIST = "SUCCESS_CARRIER_USER_LIST"
export const ERROR_CARRIER_USER_LIST = "ERROR_CARRIER_USER_LIST"
export const carrierUserListResonse = (type, data) => ({ type, data })
// Historical lanes & map
export const LANES_AND_MAP_LIST = "LANES_AND_MAP_LIST";
export const lanesAndMapList = (data) => ({ type: LANES_AND_MAP_LIST, data })
export const SUCCESS_LANES_AND_MAP_LIST = "SUCCESS_LANES_AND_MAP_LIST"
export const ERROR_LANES_AND_MAP_LIST = "ERROR_LANES_AND_MAP_LIST"
export const lanesAndMapListResponse = (type, data) => ({ type, data })
// notes delete action

export const NOTES_DELETE_DASHBOARD = "NOTES_DELETE_DASHBOARD"
export const notesDeleteDashboardList = (data) => ({ type: NOTES_DELETE_DASHBOARD, data })
export const SUCCESS_NOTES_DELETE_DASHBOARD = "SUCCESS_NOTES_DELETE_DASHBOARD"
export const ERROR_NOTES_DELETE_DASHBOARD = "ERROR_NOTES_DELETE_DASHBOARD"
export const notesDeleteDashboardListResponse = (type, data) => ({ type, data })


// get headquarters search action
export const HEADQUARTERS_SEARCH_VALUE_LIST = "HEADQUARTERS_SEARCH_VALUE_LIST";
export const headquartersSearchValueList = (data) => ({ type: HEADQUARTERS_SEARCH_VALUE_LIST, data });
export const SUCCESS_HEADQUARTERS_SEARCH_VALUE_LIST = "SUCCESS_HEADQUARTERS_SEARCH_VALUE_LIST";
export const ERROR_HEADQUARTERS_SEARCH_VALUE_LIST = "ERROR_HEADQUARTERS_SEARCH_VALUE_LIST";
export const headquartersSearchValueListResponse = (type, data) => ({ type, data });

//getDestinationMarket search action

export const DESTINATION_MARKET_SEARCH_LIST = "DESTINATION_MARKET_SEARCH_LIST";
export const destinationMarketSearchList = (data) => ({
  type: DESTINATION_MARKET_SEARCH_LIST,
  data,
});
export const SUCCESS_DESTINATION_MARKET_SEARCH_LIST =
  "SUCCESS_DESTINATION_MARKET_SEARCH_LIST";
export const ERROR_DESTINATION_MARKET_SEARCH_LIST = "ERROR_DESTINATION_MARKET_SEARCH_LIST";
export const destinationMarketSearchListResponse = (type, data) => ({ type, data });