import React, { useState, useEffect, useLayoutEffect } from "react";
import { preventCopyPaste } from "../../../common/commonPasswordValidation";
import {
  _,
  constants,
  fieldValidator,
  listManageStaff,
  Modal,
  message,
  PhoneInput,
  useDispatch,
  useSelector,
  usePrevious,
  Select,
  Empty
} from "./index";
import {
  handleRestoreUser
} from "./manageStaffAccessEvent/manageStaffAccessTableEvent";
import { HandleSubmit } from "./validator";
import {
  VisibilityOffOutlinedIcon,
  VisibilityOutlinedIcon,
} from "../../../common/commonMaterialIcons";

export const AddNewUser = (props) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const [country, setCountry] = useState('us'); // Default to 'us'

  // Function to change country, could be triggered by some event
  const changeCountry = (newCountryCode) => {
    setCountry(newCountryCode);
  };
  const [addNewUser, setAddNewUser] = useState({
    eventtype: "inactive",
    username: "",
    usernameCls: "",
    usernameErr: "",
    mobile: "",
    mobileCls: "",
    mobileErr: "",
    email: "",
    emailCls: "",
    emailErr: "",
    userRole: "",
    userRoleCls: "",
    userRoleErr: "",
    password: "",
    passwordCls: "",
    passwordErr: "",
    correctInput: constants.RIGHT_INPUT,
    wrongInput: constants.WRONG_INPUT,
  });
  const [passwordShown, setPasswordShown] = useState(false);
  //Add New User Data newUserManageStaff
  const newUserManageStaffData = useSelector(
    (state) => state.managestaff.newUserManageStaffData
  );
  const prevNewUserManageStaffData = usePrevious({ newUserManageStaffData });
  // On Enter Sign In
  const keyPressDownEvent = (e) => {
    if (e.key === "Enter") {
      HandleSubmit(addNewUser, setAddNewUser, props, dispatch);
    }
  };
  //password show/hide functionality
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  // Check Validation Function
  const checkValidation = (field, value, type, maxLength, minLength) => {
    return fieldValidator(
      field,
      value,
      type,
      maxLength,
      minLength,
      addNewUser.password
    );
  };
  // Set The Login Input Values
  const setInputValue = (e, type, maxLength, minLength) => {
    let value = e.target.value;
    if (type === "password" || type === "email") {
      value = e.target.value.replace(/\s+/g, "");
    }
    let error = checkValidation(
      e.target.name,
      value,
      type,
      maxLength,
      minLength
    );
    setAddNewUser({
      ...addNewUser,
      [e.target.name]: value,
      [error.fieldNameErr]: error.errorMsg,
      [error.fieldCls]: error.setClassName,
    });
  };

  const handleinputphonenumber = (mobile) => {
    let error = checkValidation("mobile", mobile, "required", 15, 10);
    setAddNewUser({
      ...addNewUser,
      mobile: mobile,
      [error.fieldNameErr]: error.errorMsg,
      [error.fieldCls]: error.setClassName,
    });
  };
  const selectHandleRoleChange = (userRole) => {
    //console.log(userRole)
    let error = userRole===''?'Select User Role': '';
    
    setAddNewUser({
      ...addNewUser,
      userRole: userRole,
      userRoleErr:error, 
      [error.fieldNameErr]: error.errorMsg,
      [error.fieldCls]: error.setClassName,
    });
  };
  
  useEffect(() => {
    if (props.NewUserModalShow) {
      props.setSearch("");
      setAddNewUser({
        ...addNewUser,
        username: "",
        usernameErr: "",
        usernameCls: "",
        mobile: "",
        mobileCls: "",
        mobileErr: "",
        email: "",
        emailCls: "",
        emailErr: "",
        userRole: "",
        userRoleCls: "",
        userRoleErr: "",
        passwordCls: "",
        passwordErr: "",
        password: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.NewUserModalShow]);

  //After add new User
  useEffect(() => {
    if (
      prevNewUserManageStaffData &&
      prevNewUserManageStaffData.newUserManageStaffData !==
      newUserManageStaffData
    ) {
      if (
        newUserManageStaffData &&
        _.has(newUserManageStaffData, "data") &&
        newUserManageStaffData.success === true
      ) {
        props.loader(false);
        props.setNewUserModalShow(false);
        dispatch(listManageStaff({ page: 1 }));
        props.setSearch("");
      }
      if( newUserManageStaffData &&
        _.has(newUserManageStaffData, "message") &&
        newUserManageStaffData.success === false &&  (newUserManageStaffData.open_confirm===1 ||  newUserManageStaffData.open_confirm==='1')){
          props.loader(false);
          props.setNewUserModalShow(false);
          message.error(newUserManageStaffData.message)
          handleRestoreUser(newUserManageStaffData.data.user_id, newUserManageStaffData.data.email, props.loader,dispatch,newUserManageStaffData.data.password,newUserManageStaffData.data.user_role);
        }
      if (
        newUserManageStaffData &&
        _.has(newUserManageStaffData, "message") &&
        newUserManageStaffData.success === false 
      ) {
        props.loader(false);
        // message.error(newUserManageStaffData.message.props.children[0].props.children[1]);
        message.error(newUserManageStaffData.message)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUserManageStaffData, prevNewUserManageStaffData]);

  // const keyPressDownEventEmail = (e) => {
  //     if (e.key === 'Enter') {
  //         const userEmailData = {
  //             email: state.email
  //         }
  //         dispatch(checkUserEmail(userEmailData));
  //     }
  // }
  // useEffect(() => {
  //     if (prevUserEmailData && prevUserEmailData.userEmailData !== userEmailData) {
  //         if (userEmailData && _.has(userEmailData, 'data') && userEmailData.success === true) {

  //         }
  //         if (userEmailData && _.has(userEmailData, 'message') && userEmailData.success === false) {
  //             setLoader(false)
  //             setEmailMessage(userEmailData.message)
  //             // message.error(userEmailData.message)
  //             if(userEmailData && _.has(userEmailData, 'message') && userEmailData.success === false && userEmailData.statusCode === 401){
  //             }else{
  //             message.error(userEmailData.message);
  //             }
  //         }
  //     }
  // }, [userEmailData, prevUserEmailData]);
  // stop the screen scroll while loader going on
  const staffRestoreData = useSelector(
    (state) => state.managestaff.staffRestoreData
  );
  const prevStaffRestoreData = usePrevious({ staffRestoreData });
   //After  User restore
   useEffect(() => {
    if (
      prevStaffRestoreData &&
      prevStaffRestoreData.staffRestoreData !==
      staffRestoreData
    ) {
      if (
        staffRestoreData &&
        _.has(staffRestoreData, "data") &&
        staffRestoreData.success === true
      ) {
        //props.loader(false);
        message.success(staffRestoreData.message)
        dispatch(listManageStaff({ page: 1 }));
        props.setSearch("");
      }
      if (
        staffRestoreData &&
        _.has(staffRestoreData, "message") &&
        staffRestoreData.success === false 
      ) {
        props.loader(false);
        message.error(staffRestoreData.message)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffRestoreData, prevStaffRestoreData]);
  useLayoutEffect(() => {
    if (props.loader) {
      //   document.getElementById("root").style.overflow = "hidden";
      //   document.getElementById("root").style.height = "100%";
    }
    if (!props.loader) {
      document.getElementById("root").style.overflow = "auto";
      document.getElementById("root").style.height = "auto";
    }
  }, [props.loader]);
  return (
    <Modal
      title="Add New User"
      maskClosable={false}
      open={props.NewUserModalShow}
      onOk={() => props.setNewUserModalShow(false)}
      onCancel={() =>{ props.setNewUserModalShow(false);setCountry('us')}}
      footer={null}
      keyboard={false}
    >
      <div className="modal-body p-0">
        <div className="row align-items-center">
          <div className="form_inn">
            <form className="app_form">
              <div className="row">
                <div className="form-group">
                  <label>
                    User Name<span className="text-danger">*</span>
                  </label>
                  <div className={"input-group post" + addNewUser.emailCls}>
                    <input
                      onKeyDown={(e) => keyPressDownEvent(e)}
                      type="text"
                      placeholder="Enter Username"
                      name="username"
                      value={addNewUser.username}
                      onChange={(e) =>
                        setInputValue(e, "symbolWithOneAlphanumeric", 20, 3)
                      }
                      autoComplete="off"
                    />
                  </div>
                  {addNewUser.usernameErr ? (
                    <span className="text-danger mt-1">
                      {addNewUser.usernameErr}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <label>
                    Mobile<span className="text-danger">*</span>
                  </label>
                  
                  <div className="input_group mobileEle position-relative">
                    <PhoneInput
                      country={country}
                      placeholder="(702) 123-4567"
                      value={addNewUser.mobile}
                      onChange={(mobile) => {handleinputphonenumber(mobile); changeCountry(mobile)}}
                      //containerStyle={{ marginTop: "15px" ,marginLeft:"10px"}}
                      inputExtraProps={{
                        prefix: "+",
                        autoFocus: true,
                      }}
                      onKeyDown={(e) => keyPressDownEvent(e)}
                      name="mobile"
                    //onChange={(e) => setInputValue(e, 'phoneNumberHyphon', null, null)}
                    />
                    {/* <div className={"input-group post" + addNewUser.mobileCls} >
                                                <input onKeyDown={(e) => keyPressDownEvent(e)} type="text" placeholder="Enter a mobile" name="mobile" value={addNewUser.mobile} onChange={(e) => setInputValue(e, 'phoneNumberHyphon', null, null)} />
                                            </div> */}
                    {addNewUser.mobileErr ? (
                      <span className="text-danger mt-1">
                        {addNewUser.mobileErr}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <label>
                    Email<span className="text-danger">*</span>
                  </label>
                  <div className={"input-group post" + addNewUser.emailCls}>
                    <input
                      onKeyDown={(e) => keyPressDownEvent(e)}
                      autoComplete="off"
                      type="text"
                      placeholder="Enter Email"
                      name="email"
                      value={addNewUser.email || ""}
                      onChange={(e) => setInputValue(e, "email", null, null)}
                    />
                  </div>
                  {addNewUser.emailErr ? (
                    <span className="text-danger mt-1">
                      {addNewUser.emailErr}
                    </span>
                  ) : (
                    ""
                  )}
                  {/* {emailMessage? <span className="text-danger mt-1"> {emailMessage}</span>: ''} */}
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <label>User Role<span className="text-danger">*</span></label>
                  <div className="input-group app_Select">
                    <Select
                      name="userRole"
                      value={
                        addNewUser.userRole
                          ? addNewUser.userRole
                          :''
                      }
                      placeholder="Click To Choose User Role"
                      onChange={(e, options) => selectHandleRoleChange(e, options)}
                    >
                        <Option
                              key={'urs'+1}
                              value={''}
                              disabled={true}
                            >
                             Click To Choose User Role
                            </Option>
                      {props.listUsersRolesAllList && props.listUsersRolesAllList.length > 0 ? (
                        _.map(props.listUsersRolesAllList, (role, i) => {
                          return (
                            <Option
                              key={i}
                              value={role.id ? role.id : ""}
                              allvalue={role}
                            >
                              {role.name}
                            </Option>
                          );
                        })
                      ) : (
                        <Option className="py-5 my-3" value="disabled" disabled>
                          <Empty />
                        </Option>
                      )}
                    </Select>
                    {addNewUser.userRoleErr ? (
                      <span className="text-danger mt-1">
                        {addNewUser.userRoleErr}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <label>
                    Password<span className="text-danger">*</span>
                  </label>
                  <div className={"input-group post" + addNewUser.passwordCls}>
                    <input
                      onKeyDown={(e) => keyPressDownEvent(e)}
                      autoComplete="new-password"
                      type={passwordShown ? "text" : "password"}
                      onCopy={(e) => preventCopyPaste(e)}
                      onPaste={(e) => preventCopyPaste(e)}
                      placeholder="Enter Password"
                      className="form-control"
                      name="password"
                      value={addNewUser.password}
                      onChange={(e) => setInputValue(e, "password", 50, 8)}
                    />
                    <button
                      className={
                        passwordShown
                          ? "btn-viewPass btn-pass"
                          : "btn-hidePass btn-pass"
                      }
                      onClick={togglePasswordVisiblity}
                      type="button"
                    >
                      {passwordShown ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                      {/* <span className="material-symbols-outlined visibility">visibility</span>
                                            <span className="material-symbols-outlined visibility_off">visibility_off</span> */}
                    </button>
                  </div>
                  {addNewUser.passwordErr ? (
                    <span className="text-danger mt-1">
                      {addNewUser.passwordErr}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <div className="btn-group d-flex w-100 position-relative">
                    <button
                      type="button"
                      onClick={() => {
                        props.setNewUserModalShow(false);
                        props.setSearch("");
                        setCountry('us');
                      }}
                      className="btn-default modal-action modal-close btn-default btn-open"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={() =>
                        HandleSubmit(addNewUser, setAddNewUser, props, dispatch)
                      }
                      className="btn-default btn-primary"
                    >
                      <span>Add User</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};
