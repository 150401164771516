// List Users Role
export const LIST_USERS_ROLES = "LIST_USERS_ROLES";
export const listUsersRoles = (data) => ({ type: LIST_USERS_ROLES, data });
export const SUCCESS_LIST_USERS_ROLES = "SUCCESS_LIST_USERS_ROLES";
export const ERROR_LIST_USERS_ROLES = "ERROR_LIST_USERS_ROLES";
export const listUsersRolesResponse = (type, data) => ({ type, data });

// List All Users Role
export const LIST_USERS_ROLES_ALL = "LIST_USERS_ROLES_ALL";
export const listUsersRolesAll = () => ({ type: LIST_USERS_ROLES_ALL });
export const SUCCESS_LIST_USERS_ROLES_ALL = "SUCCESS_LIST_USERS_ROLES_ALL";
export const ERROR_LIST_USERS_ROLES_ALL = "ERROR_LIST_USERS_ROLES_ALL";
export const listUsersRolesAllResponse = (type, data) => ({ type, data });

// ADD Users Role
export const ADD_USERS_ROLE = "ADD_USERS_ROLE";
export const addUsersRole = (data) => ({ type: ADD_USERS_ROLE, data });
export const SUCCESS_ADD_USERS_ROLE = "SUCCESS_ADD_USERS_ROLE";
export const ERROR_ADD_USERS_ROLE = "ERROR_ADD_USERS_ROLE";
export const addUsersRoleResponse = (type, data) => ({ type, data });

// Edit Users Role
export const EDIT_USERS_ROLE = "EDIT_USERS_ROLE";
export const editUsersRole = (data) => ({ type: EDIT_USERS_ROLE, data });
export const SUCCESS_EDIT_USERS_ROLE = "SUCCESS_EDIT_USERS_ROLE";
export const ERROR_EDIT_USERS_ROLE = "ERROR_EDIT_USERS_ROLE";
export const editUsersRoleResponse = (type, data) => ({ type, data });

// Delete Users Role
export const DELETE_USERS_ROLE = "DELETE_USERS_ROLE";
export const deleteUsersRole = (data) => ({ type: DELETE_USERS_ROLE, data });
export const SUCCESS_DELETE_USERS_ROLE = "SUCCESS_DELETE_USERS_ROLE";
export const ERROR_DELETE_USERS_ROLE = "ERROR_DELETE_USERS_ROLE";
export const deleteUsersRoleResponse = (type, data) => ({ type, data });

// GET Users Role
export const GET_USERS_ROLE = "GET_USERS_ROLE";
export const getUsersRole = (data) => ({ type: GET_USERS_ROLE, data });
export const SUCCESS_GET_USERS_ROLE = "SUCCESS_GET_USERS_ROLE";
export const ERROR_GET_USERS_ROLE = "ERROR_GET_USERS_ROLE";
export const getUsersRoleResponse = (type, data) => ({ type, data });

// AccessManagementBatch 
export const USERS_ROLE_ACCESS_MANAGEMENT_BATCH = "USERS_ROLE_ACCESS_MANAGEMENT_BATCH";
export const usersRoleAccessManagementBatch = (data) => ({ type: USERS_ROLE_ACCESS_MANAGEMENT_BATCH, data });
export const SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_BATCH = "SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_BATCH";
export const ERROR_USERS_ROLE_ACCESS_MANAGEMENT_BATCH = "ERROR_USERS_ROLE_ACCESS_MANAGEMENT_BATCH";
export const usersRoleAccessManagementBatchResponse = (type, data) => ({ type, data });

// USERS_ROLE_Access_Management_single
export const USERS_ROLE_ACCESS_MANAGEMENT_SINGLE = "USERS_ROLE_ACCESS_MANAGEMENT_SINGLE";
export const usersRoleAccessManagementSingle = (data) => ({ type: USERS_ROLE_ACCESS_MANAGEMENT_SINGLE, data });
export const SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_SINGLE = "SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_SINGLE";
export const ERROR_USERS_ROLE_ACCESS_MANAGEMENT_SINGLE = "ERROR_USERS_ROLE_ACCESS_MANAGEMENT_SINGLE";
export const usersRoleAccessManagementSingleResponse = (type, data) => ({ type, data });

// USERS_ROLE_ACCESS_MANAGEMENT_LIST
export const USERS_ROLE_ACCESS_MANAGEMENT_LIST = "USERS_ROLE_ACCESS_MANAGEMENT_LIST";
export const usersRoleAccessManagementList = (data) => ({ type: USERS_ROLE_ACCESS_MANAGEMENT_LIST, data });
export const SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_LIST = "SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_LIST";
export const ERROR_USERS_ROLE_ACCESS_MANAGEMENT_LIST = "ERROR_USERS_ROLE_ACCESS_MANAGEMENT_LIST";
export const usersRoleAccessManagementListResponse = (type, data) => ({ type, data });

// USERS_ROLE_ACCESS_MANAGEMENT_VERIFY
export const USERS_ROLE_ACCESS_MANAGEMENT_VERIFY = "USERS_ROLE_ACCESS_MANAGEMENT_VERIFY";
export const usersRoleAccessManagementVerify = (data) => ({ type: USERS_ROLE_ACCESS_MANAGEMENT_VERIFY, data });
export const SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_VERIFY = "SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_VERIFY";
export const ERROR_USERS_ROLE_ACCESS_MANAGEMENT_VERIFY = "ERROR_USERS_ROLE_ACCESS_MANAGEMENT_VERIFY";
export const usersRoleAccessManagementVerifyResponse = (type, data) => ({ type, data });