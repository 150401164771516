import { put, takeLatest } from "redux-saga/effects";
import _ from "lodash";
import {
  reloadcarrierApi,
  reloadDataSourceApi,
  reloadEquipmentTypeListApi,
  reloadreportdownloadApi,
  reloadcarrierdepartureApi,
  reloadreportdownloaddepartureApi,
  reloadFiltersGetApi,
  reloadFiltersSaveApi,
  reloadFiltersDeleteApi,
  reloadFiltersUpdateApi,
  reloadFiltersDefaultApi,
  reloadcarrierpcApi
} from "../../../api/sdk/reloadcarrier";
import {
  ERROR_RELOAD_CARRIER,
  ERROR_RELOAD_DATASOURCE_LIST,
  ERROR_RELOAD_EQUIPMENT_TYPE_LIST,
  ERROR_RELOAD_REPORT_DOWNLOAD,
  ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD,
  reloadCarrierListResponse,
  reloadDataSourceListResponse,
  reloadEquipmentTypeListResponse,
  reloadreportdownloadListResponse,
  RELOAD_CARRIER,
  RELOAD_DATASOURCE_LIST,
  RELOAD_EQUIPMENT_TYPE_LIST,
  RELOAD_REPORT_DOWNLOAD,
  selectAllEmailReloadreportdownloadListResponse,
  SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD,
  SUCCESS_RELOAD_CARRIER,
  SUCCESS_RELOAD_DATASOURCE_LIST,
  SUCCESS_RELOAD_EQUIPMENT_TYPE_LIST,
  SUCCESS_RELOAD_REPORT_DOWNLOAD,
  SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD,
  ERROR_RELOAD_CARRIER_DEPARTURE,
  reloadCarrierListDepartureResponse,
  RELOAD_CARRIER_DEPARTURE,
  SUCCESS_RELOAD_CARRIER_DEPARTURE,
  reloadreportdownloaddepartureListResponse,
  RELOAD_REPORT_DOWNLOAD_DEPARTURE,
  SUCCESS_RELOAD_REPORT_DOWNLOAD_DEPARTURE,
  ERROR_RELOAD_REPORT_DOWNLOAD_DEPARTURE,
  ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_DEPARTURE,
  selectAllEmailReloadreportdownloaddepartureListResponse,
  SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_DEPARTURE,
  SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_DEPARTURE,
  SAVE_RELOAD_FILTERS,
  SUCCESS_SAVE_RELOAD_FILTERS,
  ERROR_SAVE_RELOAD_FILTERS,
  saveReloadFiltersResponse,
  SUCCESS_GET_RELOAD_FILTERS,
  ERROR_GET_RELOAD_FILTERS,
  getReloadFiltersResponse,
  GET_RELOAD_FILTERS,
  DELETE_RELOAD_FILTERS,
deleteReloadFiltersResponse,
SUCCESS_DELETE_RELOAD_FILTERS,
ERROR_DELETE_RELOAD_FILTERS,
ERROR_UPDATE_RELOAD_FILTERS,
SUCCESS_UPDATE_RELOAD_FILTERS,
UPDATE_RELOAD_FILTERS,
updateReloadFiltersResponse,
DEFAULT_RELOAD_FILTERS,
defaultReloadFiltersResponse,
SUCCESS_DEFAULT_RELOAD_FILTERS,
ERROR_DEFAULT_RELOAD_FILTERS,
ERROR_RELOAD_CARRIER_PCLIST,
reloadCarrierListPCResponse,
RELOAD_CARRIER_PCLIST,
SUCCESS_RELOAD_CARRIER_PCLIST,
ERROR_RELOAD_REPORT_DOWNLOAD_PCLIST,
reloadreportdownloadPCListResponse,
RELOAD_REPORT_DOWNLOAD_PCLIST,
SUCCESS_RELOAD_REPORT_DOWNLOAD_PCLIST,
ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_PCLIST,
selectAllEmailReloadreportdownloadPCListResponse,
SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_PCLIST,
SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_PCLIST,
} from "./reloadcarrier.action";

function* reloadcarrierRequest(data) {
  let getData = yield reloadcarrierApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(reloadCarrierListResponse(SUCCESS_RELOAD_CARRIER, getData.data));
    // message.success(getData.data.message)
  } else {
    yield put(reloadCarrierListResponse(ERROR_RELOAD_CARRIER, getData.data));
    // message.error(getData.data.message)
  }
}
export function* reloadcarrierWatcher() {
  yield takeLatest(RELOAD_CARRIER, reloadcarrierRequest);
}

function* reloadreportdownloadRequest(data) {
  let getData = yield reloadreportdownloadApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      reloadreportdownloadListResponse(
        SUCCESS_RELOAD_REPORT_DOWNLOAD,
        getData.data
      )
    );
    // message.success(getData.data.message)
  } else {
    yield put(
      reloadreportdownloadListResponse(
        ERROR_RELOAD_REPORT_DOWNLOAD,
        getData.data
      )
    );
    // message.error(getData.data.message)
  }
}
export function* reloadreportdownloadWatcher() {
  yield takeLatest(RELOAD_REPORT_DOWNLOAD, reloadreportdownloadRequest);
}



function* selectAllEmailReloadreportdownloadRequest(data) {
  let getData = yield reloadreportdownloadApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      selectAllEmailReloadreportdownloadListResponse(
        SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD,
        getData.data
      )
    );
    // message.success(getData.data.message)
  } else {
    yield put(
      selectAllEmailReloadreportdownloadListResponse(
        ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD,
        getData.data
      )
    );
    // message.error(getData.data.message)
  }
}
export function* selectAllEmailReloadreportdownloadWatcher() {
  yield takeLatest(SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD, selectAllEmailReloadreportdownloadRequest);
}

function* reloadEquipmentTypeList(data) {
  let reloadEquipmentList = yield reloadEquipmentTypeListApi(data)
  if (reloadEquipmentList.success && _.has(reloadEquipmentList, "data.data")) {
    yield put(reloadEquipmentTypeListResponse(
      SUCCESS_RELOAD_EQUIPMENT_TYPE_LIST,
      reloadEquipmentList.data
    ))
  } else {
    yield put(reloadEquipmentTypeListResponse(
      ERROR_RELOAD_EQUIPMENT_TYPE_LIST,
      reloadEquipmentList.data
    ))
  }
}
export function* reloadEquipmentTypeListWatcher() {
  yield takeLatest(
    RELOAD_EQUIPMENT_TYPE_LIST,
    reloadEquipmentTypeList
  )
}
// reload carrier data source

function* reloadDataSource(data) {
  let reloadDataSourceData = yield (reloadDataSourceApi(data))
  if (reloadDataSourceData.sucess && _.has(reloadDataSourceData, "data.data")) {
    yield put(reloadDataSourceListResponse(
      SUCCESS_RELOAD_DATASOURCE_LIST, reloadDataSourceData.data
    ))
  } else {
    yield put(reloadDataSourceListResponse(
      ERROR_RELOAD_DATASOURCE_LIST, reloadDataSourceData.data
    ))
  }
}
export function* reloadDataSourceWatcher() {
  yield takeLatest(RELOAD_DATASOURCE_LIST, reloadDataSource)
}

function* reloadcarrierdepartureRequest(data) {
  let getData = yield reloadcarrierdepartureApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(reloadCarrierListDepartureResponse(SUCCESS_RELOAD_CARRIER_DEPARTURE, getData.data));
    // message.success(getData.data.message)
  } else {
    yield put(reloadCarrierListDepartureResponse(ERROR_RELOAD_CARRIER_DEPARTURE, getData.data));
    // message.error(getData.data.message)
  }
}
export function* reloadcarrierdepartureWatcher() {
  yield takeLatest(RELOAD_CARRIER_DEPARTURE, reloadcarrierdepartureRequest);
}

function* reloadreportdownloaddepartureRequest(data) {
  let getData = yield reloadreportdownloaddepartureApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      reloadreportdownloaddepartureListResponse(
        SUCCESS_RELOAD_REPORT_DOWNLOAD_DEPARTURE,
        getData.data
      )
    );
    // message.success(getData.data.message)
  } else {
    yield put(
      reloadreportdownloaddepartureListResponse(
        ERROR_RELOAD_REPORT_DOWNLOAD_DEPARTURE,
        getData.data
      )
    );
    // message.error(getData.data.message)
  }
}
export function* reloadreportdownloaddepartureWatcher() {
  yield takeLatest(RELOAD_REPORT_DOWNLOAD_DEPARTURE, reloadreportdownloaddepartureRequest);
}


function* selectAllEmailReloadreportdownloaddepartureRequest(data) {
  let getData = yield reloadreportdownloaddepartureApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      selectAllEmailReloadreportdownloaddepartureListResponse(
        SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_DEPARTURE,
        getData.data
      )
    );
    // message.success(getData.data.message)
  } else {
    yield put(
      selectAllEmailReloadreportdownloaddepartureListResponse(
        ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_DEPARTURE,
        getData.data
      )
    );
    // message.error(getData.data.message)
  }
}
export function* selectAllEmailReloadreportdownloaddepartureWatcher() {
  yield takeLatest(SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_DEPARTURE, selectAllEmailReloadreportdownloaddepartureRequest);
}

function* saveReloadFilters(userData) {
  let successSaveReloadFiltersData = yield reloadFiltersSaveApi(userData);
  if (
    successSaveReloadFiltersData.success &&
    _.has(successSaveReloadFiltersData, "data.data")
  ) {
    yield put(
      saveReloadFiltersResponse(SUCCESS_SAVE_RELOAD_FILTERS, successSaveReloadFiltersData.data)
    );
    //message.success(successSaveReloadFiltersData.data.message)
  } else {
    yield put(
      saveReloadFiltersResponse(ERROR_SAVE_RELOAD_FILTERS, successSaveReloadFiltersData.data)
    );
    //message.error(successSaveReloadFiltersData.data.message)
  }
}
export function* saveReloadFiltersWatcher() {
  yield takeLatest(SAVE_RELOAD_FILTERS, saveReloadFilters);
}

//get filters
function* getReloadFilters(userData) {
  let getReloadFiltersData = yield reloadFiltersGetApi(userData);
  if (getReloadFiltersData.success && _.has(getReloadFiltersData, "data.data")) {
    yield put(getReloadFiltersResponse(SUCCESS_GET_RELOAD_FILTERS, getReloadFiltersData.data));
    //message.success(getFiltersData.data.message)
  } else {
    yield put(getReloadFiltersResponse(ERROR_GET_RELOAD_FILTERS, getReloadFiltersData.data));
    //message.error(getFiltersData.data.message)
  }
}
export function* getReloadFiltersWatcher() {
  yield takeLatest(GET_RELOAD_FILTERS, getReloadFilters);
}

function* updateReloadFilters(userData) {
  let successUpdateReloadFiltersData = yield reloadFiltersUpdateApi(userData);
  if (
    successUpdateReloadFiltersData.success &&
    _.has(successUpdateReloadFiltersData, "data.data")
  ) {
    yield put(
      updateReloadFiltersResponse(
        SUCCESS_UPDATE_RELOAD_FILTERS,
        successUpdateReloadFiltersData.data
      )
    );
    //message.success(successSaveFiltersData.data.message)
  } else {
    yield put(
      updateReloadFiltersResponse(ERROR_UPDATE_RELOAD_FILTERS, successUpdateReloadFiltersData.data)
    );
    //message.error(successSaveFiltersData.data.message)
  }
}
export function* updateReloadFiltersWatcher() {
  yield takeLatest(UPDATE_RELOAD_FILTERS, updateReloadFilters);
}



//delete filters
function* deleteReloadFilters(userData) {
  let deleteReloadFiltersData = yield reloadFiltersDeleteApi(userData);
  if (deleteReloadFiltersData.success && _.has(deleteReloadFiltersData, "data.data")) {
    yield put(
      deleteReloadFiltersResponse(SUCCESS_DELETE_RELOAD_FILTERS, deleteReloadFiltersData.data)
    );
    //message.success(deleteFiltersData.data.message)
  } else {
    yield put(
      deleteReloadFiltersResponse(ERROR_DELETE_RELOAD_FILTERS, deleteReloadFiltersData.data)
    );
    //message.error(deleteFiltersData.data.message)
  }
}
export function* deleteReloadFiltersWatcher() {
  yield takeLatest(DELETE_RELOAD_FILTERS, deleteReloadFilters);
}

//default filters
function* defaultReloadFilters(userData) {
  let defaultReloadFiltersData = yield reloadFiltersDefaultApi(userData);
  if (defaultReloadFiltersData.success && _.has(defaultReloadFiltersData, "data.data")) {
    yield put(
      defaultReloadFiltersResponse(SUCCESS_DEFAULT_RELOAD_FILTERS,defaultReloadFiltersData.data)
    );
    //message.success(deleteFiltersData.data.message)
  } else {
    yield put(
      defaultReloadFiltersResponse(ERROR_DEFAULT_RELOAD_FILTERS, defaultReloadFiltersData.data)
    );
    //message.error(deleteFiltersData.data.message)
  }
}
export function* defaultReloadFiltersWatcher() {
  yield takeLatest(DEFAULT_RELOAD_FILTERS,defaultReloadFilters);
}

function* reloadcarrierpcRequest(data) {
  let getData = yield reloadcarrierpcApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(reloadCarrierListPCResponse(SUCCESS_RELOAD_CARRIER_PCLIST, getData.data));
    // message.success(getData.data.message)
  } else {
    yield put(reloadCarrierListPCResponse(ERROR_RELOAD_CARRIER_PCLIST, getData.data));
    // message.error(getData.data.message)
  }
}
export function* reloadcarrierpcWatcher() {
  yield takeLatest(RELOAD_CARRIER_PCLIST, reloadcarrierpcRequest);
}


function* reloadreportdownloadPCListRequest(data) {
  let getData = yield reloadcarrierpcApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      reloadreportdownloadPCListResponse(
        SUCCESS_RELOAD_REPORT_DOWNLOAD_PCLIST,
        getData.data
      )
    );
    // message.success(getData.data.message)
  } else {
    yield put(
      reloadreportdownloadPCListResponse(
        ERROR_RELOAD_REPORT_DOWNLOAD_PCLIST,
        getData.data
      )
    );
    // message.error(getData.data.message)
  }
}
export function* reloadreportdownloadPCListWatcher() {
  yield takeLatest(RELOAD_REPORT_DOWNLOAD_PCLIST, reloadreportdownloadPCListRequest);
}



function* selectAllEmailReloadreportdownloadPCListRequest(data) {
  let getData = yield reloadcarrierpcApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      selectAllEmailReloadreportdownloadPCListResponse(
        SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_PCLIST,
        getData.data
      )
    );
    // message.success(getData.data.message)
  } else {
    yield put(
      selectAllEmailReloadreportdownloadPCListResponse(
        ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_PCLIST,
        getData.data
      )
    );
    // message.error(getData.data.message)
  }
}
export function* selectAllEmailReloadreportdownloadPCListWatcher() {
  yield takeLatest(SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_PCLIST, selectAllEmailReloadreportdownloadPCListRequest);
}