import React, { useEffect,useState } from "react";
import { listUsersRoles, addUsersRole, editUsersRole, deleteUsersRole } from "../../../../redux/manageAccess/manageAccess.action";
import Swal from "sweetalert2";
import { AddNewRole } from "./AddNewRole";
import {
  Empty,
  constants,
  message,
  PaginationFooter,
  usePrevious,
  useSelector,
  validateInputs,
  useDispatch,
  AddOutlinedIcon,
  EditOutlinedIcon,
  DeleteOutlineOutlinedIcon,
  VisibilityOutlinedIcon,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  _,
} from "../index";

const ManageRoleListTable = (props) => {
  const [listRolesData, setListRolesData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [pageOrder, setPageOrder] = useState('DESC');
  const [orderColumn, setOrderColumn] = useState('id');
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [idState, setIdState] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [addRole, setAddRole] = useState(false);
  const [editRoleData, setEditRoleData] = useState({});
  const [selfLoader, setSelfLoader] = useState(false);
  const [addNewRoleState, setAddNewRoleState] = useState({
        id: "",
        roleName: "",
        roleNameCls: "",
        roleNameErr: "",
        correctInput: constants.RIGHT_INPUT,
        wrongInput: constants.WRONG_INPUT,
  });

  

  const dispatch = useDispatch();

  // On Load Get roles list
  useEffect(() => {
     roleListData(1);
     localStorage.setItem("refreshRoleData", false);
  }, []); 

  const sortTheData=($col,selPage=page, order)=>{
    setOrderColumn($col)
    setPageOrder(order)
    roleListData(selPage, $col, order);
  }
  
  const listUsersRolesDataSet = useSelector(
    (state) => state.manageAccess.listUsersRolesData
  );
  const prevListUsersRolesDataSet = usePrevious({ listUsersRolesDataSet });
  useEffect(() => {
    if (
      prevListUsersRolesDataSet &&
      prevListUsersRolesDataSet.listUsersRolesDataSet !== listUsersRolesDataSet
    ) {
      if (
        listUsersRolesDataSet &&
        _.has(listUsersRolesDataSet, "data") &&
        listUsersRolesDataSet.success === true
      ) {
        if(selfLoader){
           props.setLoader(false);
        }
        setSelfLoader(true);
        setListRolesData(listUsersRolesDataSet.data.records);
        setTotalPages(listUsersRolesDataSet.data.totalPages);
        setTotalRecord(listUsersRolesDataSet.data.totalRecord);
        setPage(listUsersRolesDataSet.data.page);
        setPerPage(listUsersRolesDataSet.data.limit);
      }
      if (
        listUsersRolesDataSet &&
        _.has(listUsersRolesDataSet, "message") &&
        listUsersRolesDataSet.success === false
      ) {
        props.setLoader(false);
        message.error(listUsersRolesDataSet.message);
      }
   } // eslint-disable-next-line
  }, [listUsersRolesDataSet, prevListUsersRolesDataSet]);
  //console.log('role', listUsersRolesDataSet);
  
  const roleListData = (pageNo, col='', order='')=> {
      props.setLoader(true);
      let data={
        page:pageNo,
        per_page:perPage,
        order: order===''?pageOrder:order,
        column:col===''?orderColumn:col,
      }
       order = order===''?pageOrder:order
       col  =  col===''?orderColumn:col
      setPageOrder(order)
      setOrderColumn(col)
      dispatch(listUsersRoles(data));
  }

  const addUsersRoleDatastate = useSelector(
    (state) => state.manageAccess.addUsersRoleData
  );
  const prevAddUsersRoleDatastate = usePrevious({ addUsersRoleDatastate });
  useEffect(() => {
    if (
      prevAddUsersRoleDatastate &&
      prevAddUsersRoleDatastate.addUsersRoleDatastate !== addUsersRoleDatastate
    ) {
      if (
        addUsersRoleDatastate &&
        _.has(addUsersRoleDatastate, "data") &&
        addUsersRoleDatastate.success === true
      ) {
        dispatch(props.listUsersRolesAll());
        roleListData(1, 'id', 'DESC');
        setIsAddModalVisible(false);
        props.setAdvanceSettingModalShow(true);
        message.success(addUsersRoleDatastate.message);
      }
      if (
        addUsersRoleDatastate &&
        _.has(addUsersRoleDatastate, "message") &&
        addUsersRoleDatastate.success === false
      ) {
        props.setLoader(false);
        message.error(addUsersRoleDatastate.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUsersRoleDatastate, prevAddUsersRoleDatastate]);

  const editUsersRoleDatastate = useSelector(
    (state) => state.manageAccess.editUsersRoleData
  );
  const prevEditUsersRoleDatastate = usePrevious({ editUsersRoleDatastate });
  useEffect(() => {
    if (
      prevEditUsersRoleDatastate &&
      prevEditUsersRoleDatastate.editUsersRoleDatastate !== editUsersRoleDatastate
    ) {
      if (
        editUsersRoleDatastate &&
        _.has(editUsersRoleDatastate, "data") &&
        editUsersRoleDatastate.success === true
      ) {
        props.setUpdateListAccessManagement(true)
        dispatch(props.listUsersRolesAll());
        roleListData(page);
        setIsAddModalVisible(false);
        props.setAdvanceSettingModalShow(true);
        message.success(editUsersRoleDatastate.message);
      }
      if (
        editUsersRoleDatastate &&
        _.has(editUsersRoleDatastate, "message") &&
        editUsersRoleDatastate.success === false
      ) {
        props.setLoader(false);
        message.error(editUsersRoleDatastate.message);
      }
    }
  }, [editUsersRoleDatastate, prevEditUsersRoleDatastate]);

  const deleteUsersRoleDatastate = useSelector(
    (state) => state.manageAccess.deleteUsersRoleData
  );
  const prevDeleteUsersRoleDatastate = usePrevious({ deleteUsersRoleDatastate });
  useEffect(() => {
    if (
      prevDeleteUsersRoleDatastate &&
      prevDeleteUsersRoleDatastate.deleteUsersRoleDatastate !== deleteUsersRoleDatastate
    ) {
      if (
        deleteUsersRoleDatastate &&
        _.has(deleteUsersRoleDatastate, "data") &&
        deleteUsersRoleDatastate.success === true
      ) {
        props.setUpdateListAccessManagement(true)
        dispatch(props.listUsersRolesAll());
        roleListData(1);
        message.success(deleteUsersRoleDatastate.message);
      }
      if (
        addUsersRoleDatastate &&
        _.has(deleteUsersRoleDatastate, "message") &&
        deleteUsersRoleDatastate.success === false
      ) {
        props.setLoader(false);
        message.error(deleteUsersRoleDatastate.message);
      }
    }
  }, [addUsersRoleDatastate, prevAddUsersRoleDatastate]);

  const keyPressDownEvent = (e) => {
    if (e.key === "Enter") {
      HandleAddRoleSubmit();
    }
    return false;
  };

  const HandleAddRoleSubmit=()=>{
    let id = editRoleData.id;
    let success = "";
    let error = addNewRoleState.wrongInput;
    let roleName = addNewRoleState.roleName.trim(),
      roleNameCls = success,
      roleNameErr = "",
      getError = false;

    if (validateInputs("required", roleName) === "empty") {
      roleNameErr = "Enter role name.";
      roleNameCls = error;
      getError = true;
    }
    if (roleName && roleName.trim().length < 3) {
      roleNameErr = "Enter atleast 3 characters.";
      roleNameCls = error;
      getError = true;
    }
    if (roleName && roleName.trim().length > 20) {
      roleNameErr = "Enter less than 20 characters.";
      roleNameCls = error;
      getError = true;
    }
    if (validateInputs("symbolWithOneAlphanumeric", roleNameCls) === false) {
      roleNameErr =
        "Enter alphabet or numeric character along with symbol.";
      roleNameCls = error;
      getError = true;
    }

    setAddNewRoleState({
      ...addNewRoleState,
      roleNameCls,
      roleNameErr,
    });
    if (
      getError === false &&
      roleNameErr === ""
    ) {
      if (id) {
        const editRole= {
          id: id,
          name: addNewRoleState.roleName
        };
        dispatch(editUsersRole(editRole));
        props.setLoader(true);
      } else {
        const newRole = {
          name: addNewRoleState.roleName
        };
         //console.log(getError,roleNameErr);
        dispatch(addUsersRole(newRole));
        props.setLoader(true);
      }
    }
  };

  const showNewRoleModal = ()=>{
    setIdState(false);
    setEditRoleData({});
    setIsAddModalVisible(true);
    props.setAdvanceSettingModalShow(false);
    setRoleName("");
    setAddNewRoleState({
      ...addNewRoleState,
       id: "",
      roleName: "",
      roleNameCls: "",
      roleNameErr: ""
    });
  };

const showEditRoleModal = (e, temp, id) => {
    props.setAdvanceSettingModalShow(false);
    setEditRoleData(temp);
    setIdState(true);
    setIsAddModalVisible(true);
    setRoleName('');
    setAddNewRoleState({
      ...addNewRoleState,
      id: temp.id,
      roleName:  temp.name ? temp.name : "",
      roleNameCls: "",
      roleNameErr: ""
    });

};

function showDeleteConfirm(e, temp) {
  Swal.fire({
    title: "Permission Before Delete",
    text: "Are you sure you want to delete this Role?",
    confirmButtonText: "OK",
    allowOutsideClick: false,
    allowEscapeKey: false,
    showCloseButton: true,
    showCancelButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      const deleteData = {
        id: temp.id
      };
      dispatch((deleteUsersRole(deleteData)))
      props.setLoader(true);
    }
});
}
if(localStorage.getItem("refreshRoleData")===true || localStorage.getItem("refreshRoleData")==='true' ){
  localStorage.setItem("refreshRoleData", false);
  roleListData(1);
}

  return (
    <> 
      <div className="card h-auto">
       <div className="card-header d-flex">
        <h4 className="sec_title d-flex">Roles Type</h4>
        <div className="filtersec d-flex ms-auto">
            <div className="input-field m-0">
              {/* eslint-disable-next-line */}
              <a
                className="waves-effect waves-light modal-trigger btn btn-default"
                onClick={showNewRoleModal}
              >
                <span className="d-inline-block">Add Role</span>
                <span className="visible_sm">Add</span>
                {/* <span className="ico ico-plus">Send Mail</span> */}
                {/* <span className="material-symbols-outlined fw-bold ml-10">add</span> */}
                <AddOutlinedIcon className="fw-bold" />
              </a>
            </div>
        </div>
      </div>
      <div className="card_body">
        <div className="table-responsive">
          <table>
            <thead>
              <tr>
                <td>
                  <div className="d-inline-flex align-items-center">
                    #
                  </div>
                </td>
                <td>
                  <div className="d-inline-flex align-items-center">
                    Roles
                    <button
                      className="sortBtb"
                      onClick={() =>
                        sortTheData(
                          "name", 1,'DESC'
                        )
                      }
                    >
                      {pageOrder=='DESC' ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )}
                    </button>
                  </div>
                </td>
                <td>Actions </td>
              </tr>
            </thead>
            <tbody>
              {listRolesData && listRolesData.length > 0 ? (
                _.map(listRolesData, (data, index) => {
                  return (
                    <tr key={data.id}>
                      <td className="manageemailtablestyle">{((page-1)*perPage)+index+1}</td>
                      <td className="manageemailtablestyle">{data.name}</td>
                      <td className="manageemailtablestyle">

                          <div className="action_grup d-inline-flex">
                                    <button
                                      className={`waves-effect waves-light btn btn-default  ${data.id !== 1 ? 'btn-edit-role': 'btn-edit-role-disabled'}`}
                                      onClick={(e) =>
                                        showEditRoleModal(e, data)
                                      }
                                    >
                                    
                                    {data.id === 1 ? ('View'): ('Edit') }
                                      {/* <span className='ico ico-edit'>Edit</span> */}
                                      {/* <span className="material-symbols-outlined  gray-light">edit</span> */}
                                      {data.id === 1 ? (
                                        <VisibilityOutlinedIcon className="gray-light" />
                                      ) : (
                                        <EditOutlinedIcon className="red-light" />
                                      )}
                                    </button>
                                    {data.id !== 1 ? (
                                      <button
                                        className="waves-effect waves-light btn btn-default"
                                        onClick={(e) =>
                                          showDeleteConfirm(e, data)
                                        }
                                        style={{marginRight:"-15px"}}
                                      >
                                        Delete
                                      <DeleteOutlineOutlinedIcon className="red-color" />
                                        
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="3">
                    <div className="d-flex w-100 justify-content-center p-5">
                      <Empty />
                    </div>
                  </td>

                </tr>
              )}
            </tbody>
          </table>
        </div>
        {totalRecord && totalPages > 1 ? (
          <PaginationFooter
            getPageData={(data) =>
              roleListData(data)
            }
            pageNo={page}
            totalRecords={totalRecord}
            limit={perPage}
          />
        ) : (
          ""
        )}
        </div>
      </div>
      <AddNewRole
        idState={idState}
        isAddModalVisible={isAddModalVisible}
        setIsAddModalVisible={() => setIsAddModalVisible(false)}
        setAdvanceSettingModalShow={props.setAdvanceSettingModalShow}
        addNewRoleState={addNewRoleState}
        setAddNewRoleState={setAddNewRoleState}
        roleName={roleName}
        disableField={addNewRoleState.id===1?true:false}
        HandleAddRoleSubmit={() => HandleAddRoleSubmit()}
        addRole={addRole}
        setAddRole={setAddRole}
        keyPressDownEvent={keyPressDownEvent}
        />
    </>
  );
};

export default ManageRoleListTable;
