const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const URL = (uri) => `${BASE_URL}${uri}`;
// const URL = (uri) => 'http://localhost:3000/auth/api/login';

// const URL = 'http://localhost:8000/api/login';
/***** Auth Routes*********/
export const REGISTRATION = URL("/register");
export const LOGIN = URL("/login");
// export const LOGIN = URL('/auth/login');

export const FORGOTPASSWORD = URL("/password/email");
export const RESETPASSWORD = URL("/reset-password");
export const MANAGESTAFFACCESS = URL("/staff/store");
export const VERIFYRMAIL = URL("/verify-email");
export const LOGOUT = URL("/logout");
export const EMAILVERIFICATION = URL("/resend-email-verification");
export const LINKEXPIREATION = URL("/link-expire");

//Manage staff Routes
export const Manage_Staff_New_User_Store = URL("/staff/store");
export const Manage_Staff_List = URL("/staff/list");
export const Manage_Staff_Update_Details = URL("/staff/update");
export const Manage_Staff_Restore = URL("/staff/restore");
export const RESET_PASSWORD_MANAGE_STAFF = URL("/staff/reset/password");
export const CHECK_USER_EMAIL = URL("/staff/check/email");
export const API_MANAGE_STAFF_UPDATE_RFP_ALERT = URL("/staff/rfp-alert-update");
export const Manage_Staff_Edit_User_Store = URL("/staff/profile/update");
//Manage Access management
export const API_USERS_ROLES_ALL_LIST = URL("/role-all-list");
export const API_USERS_ROLES_LIST = URL("/role-list");
export const API_USER_ROLE_ADD = URL("/role-add");
export const API_USER_ROLE_EDIT = URL("/role-edit");
export const API_USER_ROLE_DELETE = URL("/role-delete");
export const API_USER_ROLE_GET = URL("/role-get");
export const API_USER_ROLE_ACCESS_MANAGEMENT = URL("/access-management");
export const API_USER_ROLE_ACCESS_MANAGEMENT_SINGLE = URL("/access-management-single");
export const API_USER_ROLE_ACCESS_MANAGEMENT_LIST = URL("/access-management-list");
export const API_USER_ROLE_ACCESS_MANAGEMENT_VERIFY = URL("/access-management-verify");



// export const MANAGESTAFFACCESS = URL('/user/manage-staff-access');
export const SEND_USER_CREDENTIALS = URL("/staff/send/credentials");
export const RESET_PASSWORD_ACTION = URL("/staff/reset/password");
export const RESET_PASSWORD = URL("/reset-password");
export const DELETE_USER = URL("/staff/delete");
//Post set permission
export const STAFF_SET_PERMISSION = URL("/set-permission/update");
//Get set permission
export const STAFF_GET_PERMISSION = URL("/set-permission/index");
//all modules permission
export const STAFF_PERMISSION_MODULE = URL("/set-permission/all-modules");

//settings Routes
export const CHANGE_PASSWORD = URL("/change/password");
export const NOTIFICATION_LIST = URL("/notification/list");
export const CHANGED_NOTIFICATION_STATUS = URL("/change/status/notification");
export const DESTINATION_LIST = URL("/destinations");
export const ORIGINS_LIST = URL("/origins");
export const CREATE_NOTIFICATION_ALERT = URL("/create/notification");
export const DELETE_NOTIFICATION_ALERT = URL("/notification/delete");
export const EDIT_NOTIFICATION_ALERT = URL("/notification/edit");
export const ORIGIN_DESTINATION_DATA = URL("/get/origin/destinations");
export const SYNC_NOTIFICATION_DATA = URL("/sync-data");
export const SETTING_EQUIPMENT_TYPE = URL("/carrier-portal/get-equipment-list");
export const SETTING_RFP_ALERT_UPDATE = URL("/rfp-alert/update");
export const SETTING_RFP_ALERT_STATUS = URL("/rfp-alert/status");
// Change Profile Pic API
export const Change_Profile_Pic = URL("/change-profile");

// manage-emails
export const COPY_SHORTCODE = URL("/get/short-codes");
export const ADD_NEW_TEMPLATE = URL("/save-template");
export const TEMPLATE_LIST = URL("/get/templates");
export const EDIT_EMAIL_TEMPLATE = URL("/edit-template");
export const DELETE_EMAIL_TEMPLATE = URL("/delete-template");
export const SAVE_PREFERENCE = URL("/save/notification/preferences");
export const SEND_EMAIL = URL("/send-email");
export const CHECK_TEMPLATE_NAME = URL("/check/template-name");
export const MY_EMAILS_LIST = URL("/sent-email/list");
export const EMAIL_STATS = URL("/email/stats");
export const SEND_SELECTED_FILE = URL("/get/filtered-email"); // to send the file to backend
export const MY_DOWNLOAD_EMAILS_LIST = URL("/download/email-list");
// manage support email
export const MY_SUPPORT_EMAILS_LIST = URL("/soren-support-email/list");
// manage unsubscribed email
export const MY_UNSUBSCRIBE_EMAILS_LIST = URL("/unsubscribed/list");
export const DELETE_UNSUBSCRIBE_EMAILS=URL("/unsubscribed/delete");
// help-routes
export const ADD_NEW_FAQ = URL("/faqs/store");
export const NEW_FAQ_LIST = URL("/faqs/list");
export const DELETE_FAQ = URL("/faqs/delete");

//dashboard
export const LANES_AND_MAPS = URL("/fetch-lanes")
export const TEMPLATE_LIST_ = URL("/get/templates");
export const CARRIER_SOURCING_LIST = URL("/carrier-sourcing/list");
export const CARRIER_FILTERS_SAVE = URL("/carrier/filters/save");
export const CARRIER_FILTERS_UPDATE = URL("/carrier/filters/update");
export const CARRIER_FILTERS_GET = URL("/carrier/filters/get");
export const CARRIER_FILTERS_DELETE = URL("/carrier/filters/delete");
export const SAVE_CARRIER_RATING = URL("/rate/carriers");
export const GET_COLUMN_FILTERS = URL("/carriers/column/order/get");
export const SAVE_COLUMN_FILTER_ORDER = URL("/carriers/column/order/save");
export const CARRIER_SOURCING_dOWNLOAD_EXCEL = URL(
  "/carrier-sourcing/download/excel"
);
export const USERS_LIST_DASHBOARD = URL("/metrics/users-list");
export const CARRIER_USERS_LIST = URL("/metrics/users-list");
// advance search equipment type 
export const EQUIPMENT_TYPE_ADVANCE_SEARCH = URL("/carrier-portal/get-equipment-list");
export const EQUIPMENT_TYPE_ADVANCE_MARKET = URL("/carrier-portal/get-equipment-list");


export const CARRIER_UPDATE_DETAILS = URL("/groups/update");
// origin list
export const ORIGIN_SEARCH_LIST = URL("/get-origin-list");
// destination list
export const DESTINATION_SEARCH_LIST = URL("/get-destination-list");
// search carriers list
export const SEARCH_CARRIER_LIST = URL("/carrier-data");
// download carrier data in excel sheet
export const DOWNLOAD_CARRIER_DATA_EXCEL = URL("/download/excel/carrier-data");
// get origin and destination details
export const ORIGIN_DESTINATION_DETAIL = URL("/get-origin-destination-detail");
//get carrier detail
export const CARRIER_DETAIL = URL("/get/carrier-detail");
//post save notes
export const SAVE_NOTES = URL("/save/carrier/notes");
//post update basic detail
export const UPDATE_BASIC_DETAIL = URL("/carrier/edit/basic-detail");
//post update operations
export const UPDATE_OPERATIONS = URL("/carrier/edit/operation-detail");
//post update carrier info
export const UPDATE_CARRIER_CONTACT = URL("/carrier/edit/carrier-info");
//POST update dispatcher contact info
export const UPDATE_DISPATCHER_CONTACT = URL("/carrier/edit/dispatcher-detail");
//POST update authority info
export const UPDATE_AUTHORITY_DETAIL = URL("/carrier/edit/authority-info");
//POST update focus areas
export const UPDATE_FOCUS_AREA = URL("/carrier/edit/focus-areas");
//Carrier
export const MY_CARRIER_DELETE = URL("/groups/delete");
//Carrier Update
export const MY_CARRIER_UPDATE = URL("/groups/update");
//Carrier GET
export const MY_CARRIER_GET = URL("/groups/get");
//Carrier ADD
export const MY_CARRIER_ADD = URL("/groups/add");
export const GET_MY_CARRIER_BY_ID = URL("/groups/get-carrier");
// Market Data Graph API
export const MARKET_DATA_GRAPH = URL("/market-data");
export const ADDITIONAL_MARKET_DATA = URL("/additional-market-data");
//dashboard graphs
export const DASHBOARD_CARRIER_GRAPH = URL("/carrier/graph");
export const CARRIER_RATE_GRAPH = URL("/carrier-rate/graph");
export const EQUIPMENT_TYPE_GRAPH = URL("/equipment-types/graph");
export const CARRIER_COUNT_GRAPH = URL("/carriers/graph");
// Get Headquarters List
export const Get_Headquarters_List = URL("/get/headquarters");
export const NOTE_DELETE_DASHBOARD = URL("/delete/carrier/notes")
export const HEADQUARTERS_SEARCH_LIST_API = URL("/get-headquarter-list")


//Metrics
// User Graphs
export const METRICS_USER_GRAPH = URL("/metrics/get-metrics-details");
// download metrics data in excel sheet
export const DOWNLOAD_METRICS_DATA_EXCEL = URL("/download/excel");
// download search-filter data in excel sheet
export const DOWNLOAD_SEARCH_FILTER_DATA_EXCEL_API = URL("/download/excel/search-filter");

//matrices
//track-login
// export const TRACK_LOGIN_USER = URL("/metrics/track-login");
//Dashboard tracking filter
export const DASHBOARD_FILTER_TRACKING = URL("/metrics/dashboard-filters");
//Users
export const USERS_LIST = URL("/metrics/users-list");

// notification
export const CUSTOM_NOTIFICATION_LIST = URL("/custom-notification/list");
// export const LATEST_NOTIFICATION_LIST = URL('/custom-notification/latest');

// Reload carrier
// Reload carrier equiopment type
export const RELOAD_EQUIPMENT_LIST = URL("/carrier-portal/get-equipment-list")
// Reload carrier API
export const RELOAD_CARRIER = URL("/reload-carrier/get-arrival-list");
//insert new carrier data
export const INSERT_CARRIER_DATA = URL("/new-carrier/add");
// Reload Report Download
export const RELOAD_REPORT_DOWNLOAD = URL("/reload-carrier/get-arrival-list");
// Reload Carrier CSV import file
export const CSV_IMPORT_INSERT_CARRIER = URL("/new-carrier/import");
// multiple destination search API

// Insert New carrier data

// equipment type 
export const INSERT_EQUIPMENT_LIST = URL("/carrier-portal/get-equipment-list")
// Reload Carrier CSV import file
export const CSV_IMPORT_INVALID_EMAIL_CARRIER = URL("/new-carrier/import-csv");
// Reload Carrier CSV_IMPORT_EQUIPMENT_TYPE
export const CSV_IMPORT_EQUIPMENT_TYPE = URL("/new-carrier/equipment-type");
// Get Mc /Dot Number details
export const GET_MC_DOT_LIST = URL("/new-carrier/get");
// Import Invalid Carrier Origin and destination
export const IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION = URL(
  "/new-carrier/import-carriers"
);

// Import Valid Lanes
export const IMPORT_VALID_LANES = URL("/new-carrier/import-valid-lanes");
// Import Valid Lanes data
export const IMPORT_VALID_LANES_DATA = URL("/new-carrier/update");
export const DESTINATION_LIST_CARRIER_LANES = URL("/get-destination-list");
export const ORIGINS_LIST_CARRIER_LANES = URL("/get-origin-list");



//Import replacement emails
export const IMPORT_REPLACEMENT_EMAIL = URL("/new-carrier/import-emails");
// carrier-Portal
export const CARRIER_PORTAL_ORIGIN_LIST = URL("/get-carrier-origin-list")
export const CARRIER_PORTAL_DESTINATION_LIST = URL("/get-carrier-origin-list")

// equipment-type
export const EQUIPMENT_TYPE_LIST = URL("/carrier-portal/get-equipment-list")
// search API 
export const CARRIER_PORTAL_SEARCH = URL("/carrier-portal/search-carriers")

//post load--------------------------------------------------------------
// equipmnet type list for post load
export const EQUIPMENT_TYPE_LIST_FOR_POST_LOAD = URL("/carrier-portal/get-equipment-list");

// TO GET THE ORIGIN LIST
export const ADD_LOAD_ORIGINS_LIST = URL("/origins");
// to get the destination list 
export const ADD_LOAD_DESTINATION_LIST = URL("/destinations");
// search by ORDERid
export const SEARCH_BY_ORDER_ID = URL("/carrier-portal/get-order")
// stopList
export const ADD_POST_STOP_LIST = URL("/origins");
// save load or insert load 
export const SAVE_LOAD_DETAILS = URL("/posting-load/add")
// get all load for the dashboard
export const ALL_LOAD_LIST = URL("/posting-load/get-all-loads");

// get the single load details
export const SINGLE_LOAD_DETAILS = URL("/posting-load/get-all-loads");
// delete load
export const DELETE_LOAD = URL("/posting-load/delete")
// update load 
export const UPDATE_LOAD = URL("/posting-load/update")


// data source API
export const DATA_SOURCE_LOAD = URL("/data-sources")

// reload carrier data source 
export const RELOAD_DATASOURCE = URL("/data-sources")
// insert data source
export const INSERT_DATASOURCE = URL("/data-sources")

// Setting Db limit
export const SETTING_DB_LIMIT = URL("/update-db-limit")


// logout on the browser close 
export const TAB_CLOSE_LOGOUT = URL("/update-logout-time")


// RPF submit CSV file
export const IMPORT_CSV_RFP = URL("/rfp/import")

export const RFP_EQUIPMENT_TYPE_LIST = URL("/carrier-portal/get-equipment-list")
// manual CSV upload 
export const SAVE_MANUAL_RFP = URL("/rfp/manual-import")

export const ANALYZER_TABLE_RESULT = URL("/rfp/get-rfp-content")
export const ANALYZER_EXPORT_EXCEL = URL("/rfp/get-rfp-download-content")

export const PENDING_CALCULATION_STATUS = URL("/user/rfp-calculations")
// Save RFP 
export const SAVE_RFP_WITH_CONTROLS = URL("/rfp/save")

// get list of saved RFP
export const GET_SAVED_RFP_LIST = URL("/rfp/get-all-rfps")

// Delete Rfp 
export const DELETE_RFP = URL("/rfp/delete-selected-rfp")

// apply rfp 
export const APPLY_RFP = URL("/rfp/get-selected-rfp")

// Refresh RFP 
export const REFRESH_RFP_CALCULATIONS = URL("/rfp/refresh-calculations")

// get list of updated RFP
export const UPDATE_RFP_WITH_CONTROLS = URL("/rfp/update")
// RFP DOWNLOAD ALERT
export const RFP_DOWNLOAD_ALERT = URL("/rfp-report-download-alert")
// Save Range RFP 
export const SAVE_RANGE_RFP_WITH_CONTROLS=URL("/rfp/range-calculations")

//signature route list
export const ADD_SIGNATURE = URL("/signature/add");
export const GET_SIGNATURE = URL("/signature/get");
export const EDIT_SIGNATURE = URL("/signature/edit");
export const DELETE_SIGNATURE = URL("/signature/delete");
export const LIST_SIGNATURE = URL("/signature/list");
export const ALL_LIST_SIGNATURE = URL("/signature/list-all");
export const CHECK_SIGNATURE_NAME = URL("/signature/check-signature");
//otp login process
export const USERVERIFYRMAIL = URL("/login-mfa");
export const OTPVERIFICATION=URL("/verify-login-otp");
export const OTPRESEND=URL("/resend-mfa-otp");
// Reload carrier departure API
export const RELOAD_CARRIER_DEPARTURE = URL("/reload-carrier/get-departure-list");
export const RELOAD_REPORT_DOWNLOAD_DEPARTURE= URL("/reload-carrier/get-departure-list");
export const RELOAD_FILTERS_SAVE = URL("/reload-carrier/filters/save");
export const RELOAD_FILTERS_GET = URL("/reload-carrier/filters/all");
export const RELOAD_FILTERS_DELETE= URL("/reload-carrier/filters/delete");
export const RELOAD_FILTERS_UPDATE= URL("/reload-carrier/filters/update");
export const RELOAD_FILTERS_DEFAULT= URL("/reload-carrier/filters/default-filter");
export const RELOAD_CARRIER_PCLIST = URL("/reload-carrier/get-pc-list");

