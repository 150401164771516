import React, { useEffect,useState } from "react";
import Swal from "sweetalert2";
import { usersRoleAccessManagementList, usersRoleAccessManagementBatch, usersRoleAccessManagementVerify } from "../../../../redux/manageAccess/manageAccess.action";
import {
  Empty,
  message,
  Select,
  usePrevious,
  useSelector,
  useDispatch,
  AddOutlinedIcon,
  DeleteOutlineOutlinedIcon,
  listManageStaff,
  _,
} from "../index";

const ManageAccessTable = (props) => {
  const { Option } = Select;
  const [listAccessManagement, setListAccessManagement] = useState([]);
  const [selectedRoleId, setSelectedRoleId] = useState(-2);
  const [selectedCanAccessId, setSelectedCanAccessId] = useState(-2);
  const [selectedIndex, setSelectedIndex] = useState(-2);
  const dispatch = useDispatch();
 
  // On Load Get AccessManagement list
    useEffect(() => {
       props.setLoader(true);
       listData();
       localStorage.setItem("refreshManageAccessData", false);
    }, []); 
  

  const listAccessManagementDataSet = useSelector(
    (state) => state.manageAccess.usersRoleAccessManagementListData
  );
  const prevListAccessManagementDataSet = usePrevious({ listAccessManagementDataSet });
  useEffect(() => {
    if (
      prevListAccessManagementDataSet &&
      prevListAccessManagementDataSet.listAccessManagementDataSet !== listAccessManagementDataSet
    ) {
      if (
        listAccessManagementDataSet &&
        _.has(listAccessManagementDataSet, "data") &&
        listAccessManagementDataSet.success === true
      ) {
        setListAccessManagement(listAccessManagementDataSet.data);
        props.setUpdateListAccessManagement(false);
        props.setLoader(false);
      }
      if (
        listAccessManagementDataSet &&
        _.has(listAccessManagementDataSet, "message") &&
        listAccessManagementDataSet.success === false
      ) {
        props.setLoader(false);
        message.error(listAccessManagementDataSet.message);
      }
   } // eslint-disable-next-line
  }, [listAccessManagementDataSet, prevListAccessManagementDataSet]);

  const usersRoleAccessManagementVerifyData = useSelector(
    (state) => state.manageAccess.usersRoleAccessManagementVerifyData
  );
  const prevUsersRoleAccessManagementVerifyData = usePrevious({ usersRoleAccessManagementVerifyData });
  useEffect(() => {
    if (
      prevUsersRoleAccessManagementVerifyData &&
      prevUsersRoleAccessManagementVerifyData.usersRoleAccessManagementVerifyData !== usersRoleAccessManagementVerifyData
    ) {
      if (
        usersRoleAccessManagementVerifyData &&
        _.has(usersRoleAccessManagementVerifyData, "data") &&
        usersRoleAccessManagementVerifyData.success === true
      ) {
        props.setLoader(false);
        let newdata= [...listAccessManagement]
        newdata[selectedIndex]['can_access_id']=selectedCanAccessId
        newdata[selectedIndex]['role_id']=selectedRoleId
        setListAccessManagement(newdata)
        //message.success(usersRoleAccessManagementVerifyData.message);
      }
      if (
        usersRoleAccessManagementVerifyData &&
        _.has(usersRoleAccessManagementVerifyData, "message") &&
        usersRoleAccessManagementVerifyData.success === false
      ) {
        props.setLoader(false);
        message.error(usersRoleAccessManagementVerifyData.message);
      }
   } // eslint-disable-next-line
  }, [usersRoleAccessManagementVerifyData, prevUsersRoleAccessManagementVerifyData]);

  const usersRoleAccessManagementBatchData = useSelector(
    (state) => state.manageAccess.usersRoleAccessManagementBatchData
  );
  const prevUsersRoleAccessManagementBatchData = usePrevious({ usersRoleAccessManagementBatchData });
  useEffect(() => {
    if (
      prevUsersRoleAccessManagementBatchData &&
      prevUsersRoleAccessManagementBatchData.usersRoleAccessManagementBatchData !== usersRoleAccessManagementBatchData
    ) {
      if (
        usersRoleAccessManagementBatchData &&
        _.has(usersRoleAccessManagementBatchData, "data") &&
        usersRoleAccessManagementBatchData.success === true
      ) {
        //props.setLoader(false)
        listData()
        message.success(usersRoleAccessManagementBatchData.message)
      }
      if (
        usersRoleAccessManagementBatchData &&
        _.has(usersRoleAccessManagementBatchData, "message") &&
        usersRoleAccessManagementBatchData.success === false
      ) {
        props.setLoader(false);
        message.error(usersRoleAccessManagementBatchData.message);
      }
   } // eslint-disable-next-line
  }, [usersRoleAccessManagementBatchData, prevUsersRoleAccessManagementBatchData]);

  const listmanageStaffData = useSelector(
    (state) => state.managestaff.manageStaffData
  );

  const prevListManageStaffData = usePrevious({ listmanageStaffData });

  // List ManageStaff Data
  useEffect(() => {
    if (
      prevListManageStaffData &&
      prevListManageStaffData.listmanageStaffData !== listmanageStaffData
    ) {
      if (
        listmanageStaffData &&
        _.has(listmanageStaffData, "data") &&
        listmanageStaffData.success === true
      ) {
        props.setLoader(false);
        props.setManageStaffList(listmanageStaffData.data.records);
        props.setTotalRecords(listmanageStaffData.data.totalRecord);
        props.setTotalPage(listmanageStaffData.data.totalPages);
        props.setPage(listmanageStaffData.data.page);
        props.setlimit(listmanageStaffData.data.limit);
        //props.setSearch('')
      }
      if (
        listmanageStaffData &&
        _.has(listmanageStaffData, "message") &&
        listmanageStaffData.success === false
      ) {
        props.setLoader(false);
        message.error(listmanageStaffData.message);
      }
   } // eslint-disable-next-line
  }, [listmanageStaffData, prevListManageStaffData]);

  
  
  const listData = (pageNo)=> {
    props.setLoader(true);
    dispatch(usersRoleAccessManagementList());
  }

  const selectHandleChange=(e, option)=>{
    let optionData=e.split('__');
    let index = parseInt(optionData[0])
    let can_access_id = parseInt(optionData[1])
    let role_id = parseInt(optionData[2])
    setSelectedIndex(index)
    setSelectedCanAccessId(can_access_id)
    setSelectedRoleId(role_id)
    if(!validRoleEmployeeData(index,can_access_id,role_id)){
      return false;
    }
    // let res = checkHierarchy(role_id, can_access_id, [getRoleNameFromId(role_id)])
    // if( res.status===false){
    //   let hierarchyMsg='';
    //   _.each( _.reverse( res.hierarchy ), function(role, mIndex){
    //     hierarchyMsg += hierarchyMsg===''? role : ' << '+role
    //   })
    //   message.error('Row '+(index+1)+' The selected  Employee Role Type role is already assigned as the manager of the current role hierarchy : '+hierarchyMsg)
    //  return false
    // }
    let newdata= [...listAccessManagement]; 
    newdata[index]['can_access_id']=can_access_id
    setListAccessManagement(newdata)
  }

  const validRoleEmployeeData=(index, can_access_id, role_id)=>{
    let check=true
    _.each( _.filter(listAccessManagement, function(roleData, asIndex) {
      return asIndex !==index
     }), function(role, mIndex){
         if(parseInt(role.role_id)===can_access_id && parseInt(role.can_access_id)===role_id){
            let eIndex = mIndex < index ? mIndex : mIndex+1
            let roleName= getRoleNameFromId(can_access_id)
            let managerRoleName= getRoleNameFromId(role_id)
            message.error('Row '+(index+1)+' The Employee Role Type ('+roleName+') is already used as manager for Selected Manager Role Type ('+managerRoleName+') Row '+(eIndex+1))
            check=false
            return false
         }
    })
    return check
  }
  if(props.updateListAccessManagement===true){
    listData();
    // console.log('here');
    props.setUpdateListAccessManagement(false)
  }

  if(localStorage.getItem("refreshManageAccessData")===true || localStorage.getItem("refreshManageAccessData")==='true' ){
    localStorage.setItem("refreshManageAccessData", false);
    listData();
  }

  // if(props.refreshManageAccessData===true){
  //   listData();
  //   props.setRefreshManageAccessData(false);
  // }



  const selectHandleChangeManager=(e, option)=>{
    let optionData=e.split('__')
    let index = parseInt(optionData[0])
    let role_id = parseInt(optionData[1])
    let can_access_id = parseInt(optionData[2])
    setSelectedIndex(index)
    setSelectedCanAccessId(can_access_id)
    setSelectedRoleId(role_id)
    // if(!validRoleManagerData(index,role_id,can_access_id)){
    //   return false;
    // }
    let res = checkHierarchy(role_id, can_access_id, [getRoleNameFromId(role_id)])
    if( res.status===false){
      let hierarchyMsg='';
      _.each( _.reverse( res.hierarchy ), function(role, mIndex){
        hierarchyMsg += hierarchyMsg===''? role : ' << '+role
      })
      message.error('Row '+(index+1)+' The selected  Employee Role Type role is already assigned as the manager of the current role hierarchy : '+hierarchyMsg)
     return false
    }
    let newdata= [...listAccessManagement]; 
    newdata[index]['role_id']=role_id
    setListAccessManagement(newdata)
  }

  const validRoleManagerData=(index, role_id,can_access_id)=>{
    let check=true;
    _.each( _.filter(listAccessManagement, function(roleData, asIndex) {
      return asIndex !==index
     }), function(role, mIndex){
         if(parseInt(role.role_id)===role_id){
            let eIndex = mIndex < index ? mIndex : mIndex+1
            let roleName= getRoleNameFromId(role_id)
            message.error('Row '+(index+1)+' The Manager Role Type ('+roleName+') is already used in Row '+(eIndex+1))
            check=false;
            return false;
         }
         if(parseInt(role.role_id)===can_access_id && parseInt(role.can_access_id)===role_id){
          let eIndex = mIndex < index ? mIndex : mIndex+1
          let roleName= getRoleNameFromId(can_access_id)
          let managerRoleName= getRoleNameFromId(role_id)
          message.error('Row '+(index+1)+' The Employee Role Type ('+roleName+') is already used as manager for Selected Manager Role Type ('+managerRoleName+') Row '+(eIndex+1))
          check=false
          return false
       }
    })
    return check
  }

  const getRoleNameFromId=(role_id)=>{
      let role_name=''
    _.each(props.listUsersRolesAllList, function(role, mIndex){
         if(parseInt(role.id)===role_id){
           return role_name=role.name;
         }
    })
    return role_name
  }

  const getRoleMangerId=(role_id)=>{
    let managers=[];
    _.each(  _.filter(listAccessManagement, function(roledata) {
                    return roledata.role_id !==2 && roledata.can_access_id !==2 && roledata.can_access_id !== roledata.role_id
        }), function(role, mIndex){
        //console.log(role)
        if(parseInt(role['can_access_id'])===role_id){
          managers.push(parseInt(role.role_id))
        }
    })
    return managers
  }

  const validRoleManagerSubmitData=()=>{
    let check=true;
    _.each(listAccessManagement, function(role, mIndex){
         if(parseInt(role.role_id)=== -2|| parseInt(role.can_access_id)=== -2){
            message.error('Row '+(mIndex+1)+' The Manager Role Type or Employee Role Type has not been selected.')
            check=false;
            return false;
         }
         if(parseInt(role.role_id)=== parseInt(role.can_access_id)){
          message.error('Row '+(mIndex+1)+' The Employee Role Type has not been selected.')
          check=false
          return false
       }
    })
    return check
  }

  const checkHierarchy=(role_id, can_access_id, hierarchy=[], statusArray=[])=>{
    //console.log(role_id, can_access_id)
    if((role_id===-2)||can_access_id ===-2||can_access_id===role_id){
      return {
        status : true,
        hierarchy: hierarchy
      } 
    }
    let managerIds=getRoleMangerId(role_id)
    //console.log(managerIds);
    for (let i = 0; i < managerIds.length; i++) {
        hierarchy.push(getRoleNameFromId(managerIds[i]))
      
        if(parseInt(managerIds[i]) === can_access_id){
        //hierarchy.push(getRoleNameFromId(can_access_id))
          return {
            status : false,
            hierarchy: hierarchy
          }
        }
        return checkHierarchy(managerIds[i], can_access_id, hierarchy)    
    }
    return {
      status : true,
      hierarchy: hierarchy
    } 
  }


  const accessManagementDataSubmit=()=>{
    if(!validRoleManagerSubmitData()){
      return false;
    }
    props.setLoader(true);
    dispatch(usersRoleAccessManagementBatch(listAccessManagement))
    listManageStaff({ page: 1 });
    dispatch(listManageStaff({ page: 1 }));
    props.setSearch("");
  }

  const hideMainModel=()=>{
    props.setAdvanceSettingModalShow(false)
  }
  
  const addNewManagerAccess=()=>{
    let newdata= [...listAccessManagement] 
    newdata.push({role_id:-2, can_access_id:-2})
    setListAccessManagement(newdata)
  }

  const removeManagerAccess=(index)=>{
    Swal.fire({
      title: "Permission Before Delete",
      text: "Are you sure you want to delete this Manager Access?",
      confirmButtonText: "OK",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let newdata= [...listAccessManagement] 
        newdata.splice(index, 1)
        setListAccessManagement(newdata)
      }
    });
    
  }


  return (
    <> 
      <div className="card h-auto">
       <div className="card-header d-flex">
        <h4 className="sec_title d-flex">Manager Access</h4>
        <div className="input-field ms-auto m-0">
              {/* eslint-disable-next-line */}
              <a
                className="waves-effect waves-light modal-trigger btn btn-default"
                onClick={addNewManagerAccess}
              > 
                <span className="d-inline-block">Add Manager Access </span>
                <AddOutlinedIcon className="fw-bold" />
              </a>
        </div>
      </div>
      <div className="card_body">
        <div className="table-responsive">
          <table>
            <thead>
              <tr key={'head'+1}>
                <td>
                  <div className="d-inline-flex align-items-center">
                    #
                  </div>
                </td>
                <td>
                  <div className="d-inline-flex align-items-center">
                    Manager Role Type
                  </div>
                </td>
                <td>Employee Role Type</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {listAccessManagement && listAccessManagement.length > 0 ? (
                _.map(
                    _.filter(listAccessManagement, function(roledata) {
                    return roledata.role_id !==1
                   })
                  , (data, index) => {
                  return (
                    <tr key={'tr'+index}>
                      <td className="manageemailtablestyle">{index+1}</td>
                      <td className="manageemailtablestyle">
                      <div className="action_grup d-inline-flex">
                                  <div className="input-group app_Select">
                                    <Select
                                      name={"chooseRoleType"}
                                      data-role-id={data.id}
                                      
                                      style={{ width: 250 }}
                                      disabled={data.id===1?true:false}
                                      value={
                                        data.role_id 
                                          ? index+'__'+data.role_id+'__'+data.can_access_id
                                          : undefined
                                      }
                                      placeholder="Click To Choose Manager Role Type"
                                      onChange={(e, options) => selectHandleChangeManager(e, options)}
                                    >
                                      <Option key={'mgd'+1+index} value={index+'__'+'-2'+'__'+data.can_access_id} disabled={true}>
                                        Click To Choose Role Type
                                      </Option>
                                      {props.listUsersRolesAllList && props.listUsersRolesAllList.length > 0 ? (
                                        _.map(
                                          _.filter(props.listUsersRolesAllList, function(roledata) {
                                          return roledata.id !==1 
                                         }), 
                                         (role, i) => {
                                          return (
                                             
                                            <Option
                                              key={'mg'+i}
                                              value={role.id ? index+'__'+role.id+'__'+data.can_access_id : 0}
                                              allvalue={role}
                                            >
                                              {role.name}
                                            </Option>
                                          );
                                        })
                                      ) : (
                                        <Option className="py-5 my-3" value="disabled" disabled>
                                          <Empty />
                                        </Option>
                                      )}
                                    </Select>
                                  </div> 
                          </div>
                      </td>
                      <td className="manageemailtablestyle">
                          <div className="action_grup d-inline-flex">
                                  <div className="input-group app_Select">
                                    <Select
                                      name={"chooseEmployeeType"}
                                      style={{ width: 250 }}
                                      disabled={data.can_access_id===1?true:false}
                                      value={ data.can_access_id
                                          ? index+'__'+data.can_access_id+'__'+data.role_id
                                          : undefined
                                      }
                                      placeholder="Click To Choose Employee Role Type"
                                      onChange={(data, options) => selectHandleChange(data, options)}
                                    > 
                                      { data.role_id!==data.can_access_id?
                                     <Option key={'emb'+2} value={index+'__'+'-2'+'__'+data.role_id} disabled={true}>Click To Choose Employee Role Type</Option>
                                      :
                                      <Option key={'emb'+1} value={index+'__'+data.can_access_id+'__'+data.role_id} disabled={true}>Click To Choose Employee Role Type</Option>
                                      }
                                      {props.listUsersRolesAllList && props.listUsersRolesAllList.length > 0 ? (
                                        _.map(
                                          _.filter(props.listUsersRolesAllList, function(roledata) {
                                          return roledata.id !=1 && data.role_id!==roledata.id;
                                         }), 
                                         (role, i) => {
                                          return (
                                            <Option
                                              key={'em'+i}
                                              value={role.id ? index+'__'+role.id+'__'+data.role_id : 0}
                                              allvalue={role}
                                            >
                                              {role.name}
                                            </Option>
                                          );
                                        })
                                      ) : (
                                        <Option key={'empty'+1} className="py-5 my-3" value="disabled" disabled>
                                          <Empty />
                                        </Option>
                                      )}
                                    </Select>
                                  </div> 
                          </div>
                      </td>
                      <td className="manageemailtablestyle">
                             <div className=" action_grup d-inline-flex">
                                {listAccessManagement.length === index+1?(
                                    <div className="input-field mx-3">
                                      
                                    <a
                                      className="waves-effect waves-light modal-trigger btn btn-default"
                                      onClick={(index)=>addNewManagerAccess(index)}
                                    >
                                      Add
                                      <AddOutlinedIcon className="fw-bold" />
                                    </a>
                                    </div>
                                ):("")}
                                <div className="input-field">
                                      
                                      <a
                                        className="waves-effect waves-light modal-trigger btn btn-default"
                                        data-index={index}
                                        onClick={()=>removeManagerAccess(index)}
                                      >
                                         Delete
                                         <DeleteOutlineOutlinedIcon className="red-color" />
                                      </a>
                                </div>
                              </div>
                        </td>
                    </tr>
                  );
                })
              ) : (
                <tr key={'empty1'}>
                  <td colSpan="4">
                    <div className="d-flex w-100 justify-content-center p-5">
                      <Empty />
                    </div>
                  </td>

                </tr>
              )}
            </tbody>
          </table>
        </div>
        </div>
      </div> 
     
      <div className="row app_form">
          <div className="form-group">
            <div className="btn-group d-flex w-100 position-relative">
              <button
                type="button"
                onClick={hideMainModel}
                className="btn-default modal-action modal-close btn-default btn-open"
              >
               Close
              </button>
              
                <button
                  type="button"
                  className="btn-default btn-primary"
                  onClick={accessManagementDataSubmit}
                >
                  <span>Save</span>
                </button>
             
            </div>
          </div>
      </div>
    </>
  );
};

export default ManageAccessTable;
