import { request } from "../request/axios.request";
import {
  CARRIER_FILTERS_DELETE,
  CARRIER_FILTERS_GET,
  CARRIER_FILTERS_SAVE,
  CARRIER_SOURCING_dOWNLOAD_EXCEL,
  CARRIER_SOURCING_LIST,
  CARRIER_UPDATE_DETAILS,
  GET_COLUMN_FILTERS,
  SAVE_CARRIER_RATING,
  SAVE_COLUMN_FILTER_ORDER,
  TEMPLATE_LIST_,
  ORIGIN_SEARCH_LIST,
  DESTINATION_SEARCH_LIST,
  SEARCH_CARRIER_LIST,
  DOWNLOAD_CARRIER_DATA_EXCEL,
  ORIGIN_DESTINATION_DETAIL,
  CARRIER_DETAIL,
  CARRIER_FILTERS_UPDATE,
  SAVE_NOTES,
  UPDATE_BASIC_DETAIL,
  UPDATE_OPERATIONS,
  UPDATE_CARRIER_CONTACT,
  UPDATE_DISPATCHER_CONTACT,
  UPDATE_AUTHORITY_DETAIL,
  UPDATE_FOCUS_AREA,
  MY_CARRIER_DELETE,
  MY_CARRIER_UPDATE,
  MY_CARRIER_GET,
  MY_CARRIER_ADD,
  GET_MY_CARRIER_BY_ID,
  MARKET_DATA_GRAPH,
  ADDITIONAL_MARKET_DATA,
  Get_Headquarters_List,
  EQUIPMENT_TYPE_ADVANCE_SEARCH,
  EQUIPMENT_TYPE_ADVANCE_MARKET,
  DATA_SOURCE_LOAD,
  USERS_LIST_DASHBOARD,
  CARRIER_USERS_LIST,
  LANES_AND_MAPS,
  NOTE_DELETE_DASHBOARD,
  HEADQUARTERS_SEARCH_LIST_API,
} from "../routing/route";

// CARRIER_SOURCING_LIST
export async function carrierSourcingListApi(data) {
  let carrierSourcingList = data.data;
  return request({
    url: CARRIER_SOURCING_LIST,
    method: "get",
    params: carrierSourcingList,
  });
}

//CARRIER_FILTERS_SAVE
export async function carrierFiltersSaveApi(data) {
  let carrierFiltersSaveData = data.data;
  return request({
    url: CARRIER_FILTERS_SAVE,
    method: "post",
    data: carrierFiltersSaveData,
  });
}

//CARRIER_FILTERS_GET
export async function carrierFiltersGetApi(data) {
  let carrierfiltersGetData = data.data;
  return request({
    url: CARRIER_FILTERS_GET,
    method: "get",
    params: carrierfiltersGetData,
  });
}

//CARRIER_FILTERS_DELETE
export async function carrierFiltersDeleteApi(data) {
  let carrierFiltersDeleteData = data.data;
  return request({
    url: CARRIER_FILTERS_DELETE,
    method: "get",
    params: carrierFiltersDeleteData,
  });
}
//SAVE_CARRIER_RATING
export async function saveCarrierRatingApi(data) {
  let saveCarrierFilterRatingData = data.data;
  return request({
    url: SAVE_CARRIER_RATING,
    method: "post",
    data: saveCarrierFilterRatingData,
  });
}

//CARRIER_SOURCING_dOWNLOAD_PDF
export async function carrierSourcingDownloadExcelApi(data) {
  let carrierSourcingDownloadExcelData = data.data;
  return request({
    url: CARRIER_SOURCING_dOWNLOAD_EXCEL,
    method: "get",
    params: carrierSourcingDownloadExcelData,
  });
}

//CARRIER_UPDATE_DETAILS
export async function carrierUpdateDetailsApi(data) {
  let carrierUpdateData = data.data;
  return request({
    url: CARRIER_UPDATE_DETAILS,
    method: "post",
    data: carrierUpdateData,
  });
}

export async function templateListApi(data) {
  return request({ url: TEMPLATE_LIST_, method: "get" });
}

//GetColumnFiltersDataApi
export async function GetColumnFiltersDataApi(data) {
  //let carrierfiltersGetData = data.data;
  return request({
    url: GET_COLUMN_FILTERS,
    method: "get",
  });
}

//CARRIER_UPDATE_DETAILS
export async function saveColumnFilterOrderApi(data) {
  let saveFilterOrderData = data.data;
  return request({
    url: SAVE_COLUMN_FILTER_ORDER,
    method: "post",
    data: saveFilterOrderData,
  });
}

//ORIGIN_SEARCH_LIST
export async function getOriginSearchListApi(data) {
  let getOriginSearchListData = data.data;
  return request({
    url: ORIGIN_SEARCH_LIST,
    method: "get",
    params: getOriginSearchListData,
  });
}

//DESTINATION_SEARCH_LIST
export async function getDestinationSearchListApi(data) {
  let getDestinationSearchListData = data.data;
  return request({
    url: DESTINATION_SEARCH_LIST,
    method: "get",
    params: getDestinationSearchListData,
  });
}

//SEARCH_CARRIER_LIST
export async function getSearchCarrierListApi(data) {
  let getSearchCarrierListData = data.data;
  return request({
    url: SEARCH_CARRIER_LIST,
    method: "post",
    data: getSearchCarrierListData,
  });
}

//DOWNLOAD_CARRIER_DATA_EXCEL
export async function downloadCarrierDataExcelApi(data) {
  let downloadCarrierDataExcelData = data.data;
  return request({
    url: DOWNLOAD_CARRIER_DATA_EXCEL,
    method: "get",
    params: downloadCarrierDataExcelData,
  });
}

//ORIGIN_DESTINATION_DETAIL
export async function originDestinationDetailApi(data) {
  let originDestinationDetailData = data.data;
  return request({
    url: ORIGIN_DESTINATION_DETAIL,
    method: "get",
    params: originDestinationDetailData,
  });
}

//CARRIER_DETAIL
export async function carrierDetailApi(data) {
  let carrierDetailData = data.data;
  return request({
    url: CARRIER_DETAIL,
    method: "get",
    params: carrierDetailData,
  });
}

//CARRIER_FILTERS_UPDATE
export async function carrierFiltersUpdateApi(data) {
  let carrierFiltersUpdateData = data.data;
  return request({
    url: CARRIER_FILTERS_UPDATE,
    method: "post",
    data: carrierFiltersUpdateData,
  });
}

//SAVE_NOTES
export async function saveNotesApi(data) {
  let saveNotesData = data.data;
  return request({
    url: SAVE_NOTES,
    method: "post",
    data: saveNotesData,
  });
}

//UPDATE_BASIC_DETAIL
export async function updateBasicDetailApi(data) {
  let updateBasicDetailData = data.data;
  return request({
    url: UPDATE_BASIC_DETAIL,
    method: "post",
    data: updateBasicDetailData,
  });
}

//UPDATE_OPERATIONS
export async function updateOperationsApi(data) {
  let updateOperationsData = data.data;
  return request({
    url: UPDATE_OPERATIONS,
    method: "post",
    data: updateOperationsData,
  });
}

//UPDATE_CARRIER_CONTACT
export async function updateCarrierContactApi(data) {
  let updateCarrierContactData = data.data;
  return request({
    url: UPDATE_CARRIER_CONTACT,
    method: "post",
    data: updateCarrierContactData,
  });
}

//UPDATE_DISPATCHER_CONTACT
export async function updateDispatcherContactApi(data) {
  let updateDispatcherContactData = data.data;
  return request({
    url: UPDATE_DISPATCHER_CONTACT,
    method: "post",
    data: updateDispatcherContactData,
  });
}

//UPDATE_AUTHORITY_DETAIL
export async function updateAuthorityDetailApi(data) {
  let updateAuthorityDetailData = data.data;
  return request({
    url: UPDATE_AUTHORITY_DETAIL,
    method: "post",
    data: updateAuthorityDetailData,
  });
}

//UPDATE_FOCUS_AREA
export async function updateFocusAreaApi(data) {
  let updateFocusArea = data.data;
  return request({
    url: UPDATE_FOCUS_AREA,
    method: "post",
    data: updateFocusArea,
  });
}

//MY_CARRIER_DELETE
export async function mycarrierDeleteApi(data) {
  let mycarrierDeleteData = data.data;
  return request({
    url: MY_CARRIER_DELETE,
    method: "delete",
    params: mycarrierDeleteData,
  });
}

//MY_CARRIER_UPDATE
export async function mycarrierUpdateApi(data) {
  let mycarrierUpdateData = data.data;
  return request({
    url: MY_CARRIER_UPDATE,
    method: "post",
    data: mycarrierUpdateData,
  });
}

//MY_CARRIER_GET
export async function mycarriergetApi(data) {
  let mycarrierGetData = data.data;
  return request({
    url: MY_CARRIER_GET,
    method: "get",
    params: mycarrierGetData,
  });
}

// MY_CARRIER_ADD
export async function mycarrierAddApi(data) {
  let mycarrierAddData = data.data;
  return request({
    url: MY_CARRIER_ADD,
    method: "post",
    data: mycarrierAddData,
  });
}

// MY_GET_CARRIER_LIST
export async function getMyCarrierByIdApi(data) {
  let getmyCarrierbyid = data.data;
  return request({
    url: GET_MY_CARRIER_BY_ID,
    method: "get",
    params: getmyCarrierbyid,
  });
}

// MARKET_DATA_GRAPH
export async function getMarketDataGraphApi(data) {
  let getMarketDataGraph = data.data;
  return request({
    url: MARKET_DATA_GRAPH,
    method: "get",
    params: getMarketDataGraph,
  });
}

// ADDITIONAL_MARKET_DATA
export async function getAdditionalMarketDataApi(data) {
  let getAdditionalMarketData = data.data;
  return request({
    url: ADDITIONAL_MARKET_DATA,
    method: "get",
    params: getAdditionalMarketData,
  });
}

// Get Headquarters List
export async function getHeadquatersListApi(data) {
  let getHeadquatersListData = data.data;
  return request({
    url: Get_Headquarters_List,
    method: "get",
    params: getHeadquatersListData,
  });
}

// Advance  search equipment type
export async function advanceSearchEquipmentApi(data) {
  let advanceSearchEquipmentApiData = data.data;
  return request({
    url: EQUIPMENT_TYPE_ADVANCE_SEARCH,
    method: "get",
    params: advanceSearchEquipmentApiData
  })
}

// Advance market search equipment type
export async function advanceMarketEquipmentApi(data) {
  let marketSearchEquipmentApi = data.data
  return request({
    url: EQUIPMENT_TYPE_ADVANCE_MARKET,
    method: "get",
    params: marketSearchEquipmentApi
  })
}

// data source load
export async function dataSourceApi(data) {
  let dataSourceApiData = data.data
  return request({
    url: DATA_SOURCE_LOAD,
    method: "get",
    params: dataSourceApiData
  })
}

// USER LIST 
export async function dashboardUserListApi(data) {
  let searchData = data.data
  return request({
    url: USERS_LIST_DASHBOARD,
    method: "get",
    params: searchData
  })
}

//  USER LIST FOR SELECT CARRIER 
export async function carrierUserListApi(data) {
  let searchDataList = data.data
  return request({
    url: CARRIER_USERS_LIST,
    method: "get",
    params: searchDataList
  })
}
// historical lanes
export async function getLanesAndMapApi(data) {
  let getLanesAndMapData = data.data
  return request({
    url: LANES_AND_MAPS,
    method: "get",
    params: getLanesAndMapData
  })
}
// data source load
export async function noteDeleteApi(data) {
  let noteDeleteApiData = data.data
  return request({
    url: NOTE_DELETE_DASHBOARD,
    method: "post",
    data: noteDeleteApiData
  })
}

// headquarters search list api
export async function headquartersSearchListApi(data) {
  let headquartersSearchListData = data.data
  return request({
    url: HEADQUARTERS_SEARCH_LIST_API,
    method: "get",
    params: headquartersSearchListData
  })
}