import {
  ERROR_DASHBOARD_FILTER_TRACKING,
  ERROR_DOWNLOAD_METRICS_DATA_EXCEL,
  ERROR_METRICS_USER_GRAPH,
  ERROR_METRICS_USER_SEARCH_LIST,
  // ERROR_TRACK_LOGIN_USER,
  ERROR_USER_SEARCH_LIST,
  SUCCESS_DASHBOARD_FILTER_TRACKING,
  SUCCESS_DOWNLOAD_METRICS_DATA_EXCEL,
  SUCCESS_METRICS_USER_GRAPH,
  SUCCESS_METRICS_USER_SEARCH_LIST,
  // SUCCESS_TRACK_LOGIN_USER,
  SUCCESS_USER_SEARCH_LIST,
  SUCCESS_DOWNLOAD_SEARCH_FILTER_DATA_EXCEL, 
  ERROR_DOWNLOAD_SEARCH_FILTER_DATA_EXCEL
} from "./metrics.action";
import { DEFAULT_STATE } from "./metrics.state";
export const metricsReducer = (
  state = DEFAULT_STATE,
  action = {
    type: {},
    data: {},
  }
) => {
  switch (action.type) {
    // case SUCCESS_TRACK_LOGIN_USER:
    //   const trackloginuserData = action.data;
    //   return { ...state, trackloginuserData };
    // case ERROR_TRACK_LOGIN_USER:
    //   const errortrackloginuserData = action.data;
    //   return { ...state, trackloginuserData: errortrackloginuserData };

    case SUCCESS_DASHBOARD_FILTER_TRACKING:
      const dashboardfiltertrackingData = action.data;
      return { ...state, dashboardfiltertrackingData };
    case ERROR_DASHBOARD_FILTER_TRACKING:
      const errordashboardfiltertrackingData = action.data;
      return {
        ...state,
        dashboardfiltertrackingData: errordashboardfiltertrackingData,
      };

    case ERROR_USER_SEARCH_LIST:
      const error = action.data;
      return { ...state, userListData: error };

    case SUCCESS_USER_SEARCH_LIST:
      const success = action.data;
      return { ...state, userListData: success };

    case SUCCESS_DOWNLOAD_METRICS_DATA_EXCEL:
      const metricsDownloadExcelData = action.data;
      return { ...state, metricsDownloadExcelData };
    case ERROR_DOWNLOAD_METRICS_DATA_EXCEL:
      const errorMetricsDownloadExcelData = action.data;
      return {
        ...state,
        metricsDownloadExcelData: errorMetricsDownloadExcelData,
      };

    case SUCCESS_METRICS_USER_GRAPH:
      const metricsTypeGraphData = action.data;
      return { ...state, metricsTypeGraphData };
    case ERROR_METRICS_USER_GRAPH:
      const errorMetricsTypeGraphData = action.data;
      return { ...state, metricsTypeGraphData: errorMetricsTypeGraphData };

    case SUCCESS_METRICS_USER_SEARCH_LIST:
      const metricsUserListData = action.data;
      return { ...state, metricsUserListData };
    case ERROR_METRICS_USER_SEARCH_LIST:
      const errorMetricsUserListData = action.data;
      return { ...state, metricsTypeGraphData: errorMetricsUserListData };

    case SUCCESS_DOWNLOAD_SEARCH_FILTER_DATA_EXCEL:
      const downloadSearchFilterExcelData = action.data;
      return { ...state, downloadSearchFilterExcelData };
    case ERROR_DOWNLOAD_SEARCH_FILTER_DATA_EXCEL:
      const downloadSearchFilterExcelDataError = action.data;
      return { ...state, downloadSearchFilterExcelData: downloadSearchFilterExcelDataError };


    default:
      return state;
  }
};
