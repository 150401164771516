import {
  ERROR_RELOAD_CARRIER,
  ERROR_RELOAD_DATASOURCE_LIST,
  ERROR_RELOAD_EQUIPMENT_TYPE_LIST,
  ERROR_RELOAD_REPORT_DOWNLOAD,
  ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD,
  SUCCESS_RELOAD_CARRIER,
  SUCCESS_RELOAD_DATASOURCE_LIST,
  SUCCESS_RELOAD_EQUIPMENT_TYPE_LIST,
  SUCCESS_RELOAD_REPORT_DOWNLOAD,
  SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD,
  ERROR_RELOAD_CARRIER_DEPARTURE,
  SUCCESS_RELOAD_CARRIER_DEPARTURE,
  SUCCESS_RELOAD_REPORT_DOWNLOAD_DEPARTURE,
  ERROR_RELOAD_REPORT_DOWNLOAD_DEPARTURE,
  SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_DEPARTURE,
  ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_DEPARTURE,
  SUCCESS_SAVE_RELOAD_FILTERS,
  SUCCESS_GET_RELOAD_FILTERS,
  ERROR_SAVE_RELOAD_FILTERS,
  ERROR_GET_RELOAD_FILTERS,
  SUCCESS_UPDATE_RELOAD_FILTERS,
  SUCCESS_DELETE_RELOAD_FILTERS,
  ERROR_DELETE_RELOAD_FILTERS,
  ERROR_UPDATE_RELOAD_FILTERS,
  SUCCESS_DEFAULT_RELOAD_FILTERS,
  ERROR_DEFAULT_RELOAD_FILTERS,
  SUCCESS_RELOAD_CARRIER_PCLIST,
  ERROR_RELOAD_CARRIER_PCLIST,
  SUCCESS_RELOAD_REPORT_DOWNLOAD_PCLIST,
  ERROR_RELOAD_REPORT_DOWNLOAD_PCLIST,
  SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_PCLIST,
  ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_PCLIST
} from "./reloadcarrier.action";
import { DEFAULT_STATE } from "./reloadcarrier.state";
export const reloadcarrierReducer = (
  state = DEFAULT_STATE,
  action = {
    type: {},
    data: {},
  }
) => {
  switch (action.type) {
    case SUCCESS_RELOAD_CARRIER:
      const reloadcarrierData = action.data;
      return { ...state, reloadcarrierData };
    case ERROR_RELOAD_CARRIER:
      const errorreloadcarrierData = action.data;
      return { ...state, reloadcarrierData: errorreloadcarrierData };

    case SUCCESS_RELOAD_REPORT_DOWNLOAD:
      const reloadreportdownloadData = action.data;
      return { ...state, reloadreportdownloadData };
    case ERROR_RELOAD_REPORT_DOWNLOAD:
      const errorreloadreportdownloadData = action.data;
      return {
        ...state,
        reloadreportdownloadData: errorreloadreportdownloadData,
      };

    case SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD:
      const selectAllEmailReloadreportdownloadData = action.data;
      return { ...state, selectAllEmailReloadreportdownloadData };
    case ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD:
      const errorselectAllEmailReloadreportdownloadData = action.data;
      return {
        ...state,
        selectAllEmailReloadreportdownloadData: errorselectAllEmailReloadreportdownloadData,
      };

    case SUCCESS_RELOAD_EQUIPMENT_TYPE_LIST:
      let reloadEquipmentData = action.data
      return { ...state, reloadEquipmentData }
    case ERROR_RELOAD_EQUIPMENT_TYPE_LIST:
      let errorReloadEquipmentData = action.data
      return { ...state, reloadEquipmentData: errorReloadEquipmentData }

    case SUCCESS_RELOAD_DATASOURCE_LIST:
      let reloadDataSourceData = action.data
      return { ...state, reloadDataSourceData }
    case ERROR_RELOAD_DATASOURCE_LIST:
      let errorReloadDataSourceData = action.data
      return { ...state, reloadDataSourceData: errorReloadDataSourceData }

      case SUCCESS_RELOAD_CARRIER_DEPARTURE:
        const reloadcarrierdepartureData = action.data;
        return { ...state, reloadcarrierdepartureData };
      case ERROR_RELOAD_CARRIER_DEPARTURE:
        const errorreloadcarrierdepartureData = action.data;
        return { ...state, reloadcarrierdepartureData: errorreloadcarrierdepartureData };

        case SUCCESS_RELOAD_REPORT_DOWNLOAD_DEPARTURE:
          const reloadreportdownloaddepartureData = action.data;
          return { ...state, reloadreportdownloaddepartureData };
        case ERROR_RELOAD_REPORT_DOWNLOAD_DEPARTURE:
          const errorreloadreportdownloaddepartureData = action.data;
          return {
            ...state,
            reloadreportdownloaddepartureData: errorreloadreportdownloaddepartureData,
          }; 

          case SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_DEPARTURE:
            const selectAllEmailReloadreportdownloaddepartureData = action.data;
            return { ...state, selectAllEmailReloadreportdownloaddepartureData };
          case ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_DEPARTURE:
            const errorselectAllEmailReloadreportdownloaddepartureData = action.data;
            return {
              ...state,
              selectAllEmailReloadreportdownloaddepartureData: errorselectAllEmailReloadreportdownloaddepartureData,
            };  
            
            case SUCCESS_SAVE_RELOAD_FILTERS:
      const successSaveReloadFiltersData = action.data;
      return { ...state, successSaveReloadFiltersData };
    case ERROR_SAVE_RELOAD_FILTERS:
      const errorSuccesssaveReloadFiltersData = action.data;
      return { ...state, successSaveReloadFiltersData: errorSuccesssaveReloadFiltersData };

    case SUCCESS_GET_RELOAD_FILTERS:
      const getReloadFiltersData = action.data;
      return { ...state, getReloadFiltersData };
    case ERROR_GET_RELOAD_FILTERS:
      const errorGetReloadFiltersData = action.data;
      return { ...state, getReloadFiltersData: errorGetReloadFiltersData };

      case SUCCESS_UPDATE_RELOAD_FILTERS:
      const successUpdateReloadFiltersData = action.data;
      return { ...state, successUpdateReloadFiltersData };
    case ERROR_UPDATE_RELOAD_FILTERS:
      const errorSuccessUpdateFiltersData = action.data;
      return {
        ...state,
        successUpdateReloadFiltersData: errorSuccessUpdateFiltersData,
      };

      case SUCCESS_DELETE_RELOAD_FILTERS:
      const deleteReloadFiltersData = action.data;
      return { ...state, deleteReloadFiltersData };
    case ERROR_DELETE_RELOAD_FILTERS:
      const errorDeleteReloadFiltersData = action.data;
      return { ...state, deleteReloadFiltersData: errorDeleteReloadFiltersData };

      case SUCCESS_DEFAULT_RELOAD_FILTERS:
      const defaultReloadFiltersData = action.data;
      return { ...state, defaultReloadFiltersData };
    case ERROR_DEFAULT_RELOAD_FILTERS:
      const errorDefaultReloadFiltersData = action.data;
      return { ...state, defaultReloadFiltersData: errorDefaultReloadFiltersData };

      case SUCCESS_RELOAD_CARRIER_PCLIST:
        const reloadcarrierpcData = action.data;
        return { ...state, reloadcarrierpcData };
      case ERROR_RELOAD_CARRIER_PCLIST:
        const errorreloadcarrierpcData = action.data;
        return { ...state, reloadcarrierpcData: errorreloadcarrierpcData };

        case SUCCESS_RELOAD_REPORT_DOWNLOAD_PCLIST:
          const reloadreportdownloadPCListData = action.data;
          return { ...state, reloadreportdownloadPCListData };
        case ERROR_RELOAD_REPORT_DOWNLOAD_PCLIST:
          const errorreloadreportdownloadPCListData = action.data;
          return {
            ...state,
            reloadreportdownloadPCListData: errorreloadreportdownloadPCListData,
          }; 

          case SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_PCLIST:
            const selectAllEmailReloadreportdownloadPCListData = action.data;
            return { ...state, selectAllEmailReloadreportdownloadPCListData };
          case ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_PCLIST:
            const errorselectAllEmailReloadreportdownloadPCListData = action.data;
            return {
              ...state,
              selectAllEmailReloadreportdownloadPCListData: errorselectAllEmailReloadreportdownloadPCListData,
            }; 

    default:
      return state;
  }
};
