import { Modal, message, Empty, Menu, Dropdown, Select } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Spin } from "antd"
import Footer from "../../component/dashboard/footer";
import {
  listManageStaff,
  StaffUpdateDetails,
  staffgetpermission,
  resetPasswordManageStaff,
  sendUserCredentials,
  deleteUser,
  newUserManageStaff,
  checkUserEmail,
  staffRestore,
  staffUpdateRfpAlert,
  editUserManageStaff
} from "../../../redux/managestaff/manage.action";
import { fieldValidator, usePrevious } from "../../../common/custom";
import { constants } from "../../../common/constants";
import { validateInputs } from "../../../common/Validation";
import { PaginationFooter } from "../../../common/pagination";
import loader1 from "../../../assets/images/loader1.svg";
import {
  staffpermissionmodule,
  staffsetpermission,
} from "../../../redux/managestaff/manage.action";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { STAFFPERMISSION } from "../../../routing/routeContants";
import { AddNewUser } from "./addNewUser";
import { AdvanceSetting } from "./advanceSetting";
import { ResetPassword } from "./resetPassword";
import { EditUserProfile } from "./editUserProfile";
import {
  MoreVertOutlinedIcon,
  AddOutlinedIcon,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  SettingsOutlinedIcon,
  EditOutlinedIcon,
  DeleteOutlineOutlinedIcon,
  VisibilityOutlinedIcon,
  PersonAddIcon,
  PersonRemoveIcon,
  RemoveIcon
} from "../../../common/commonMaterialIcons";
import { commonEmailPermission } from "../../../common/commonEmailPermission";
import { User } from "../auth";
//To set the message configration like how many times message should display
message.config({
  maxCount: 1,
});

export {
  _,
  useHistory,
  ResetPassword,
  MoreVertOutlinedIcon,
  AddOutlinedIcon,
  KeyboardArrowDownIcon,
  commonEmailPermission,
  User,
  KeyboardArrowUpIcon,
  STAFFPERMISSION,
  AddNewUser,
  AdvanceSetting,
  constants,
  checkUserEmail,
  Dropdown,
  deleteUser,
  Empty,
  ExclamationCircleOutlined,
  Footer,
  fieldValidator,
  loader1,
  listManageStaff,
  Menu,
  Modal,
  message,
  newUserManageStaff,
  PhoneInput,
  PaginationFooter,
  resetPasswordManageStaff,
  Select,
  Swal,
  sendUserCredentials,
  StaffUpdateDetails,
  useDispatch,
  useSelector,
  usePrevious,
  validateInputs,
  staffgetpermission,
  staffpermissionmodule,
  staffsetpermission,
  LoadingOutlined,
  SettingsOutlinedIcon,
  Spin,
  EditOutlinedIcon,
  DeleteOutlineOutlinedIcon,
  VisibilityOutlinedIcon,
  PersonAddIcon,
  PersonRemoveIcon,
  RemoveIcon,
  staffRestore,
  EditUserProfile,
  editUserManageStaff,
  staffUpdateRfpAlert
};
