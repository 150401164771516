import {
    IMPORT_CSV_RFP,
    RFP_EQUIPMENT_TYPE_LIST,
    SAVE_MANUAL_RFP,
    ANALYZER_TABLE_RESULT,
    ANALYZER_EXPORT_EXCEL,
    PENDING_CALCULATION_STATUS,
    SAVE_RFP_WITH_CONTROLS,
    GET_SAVED_RFP_LIST,
    DELETE_RFP,
    APPLY_RFP,
    REFRESH_RFP_CALCULATIONS,
    UPDATE_RFP_WITH_CONTROLS,
    RFP_DOWNLOAD_ALERT,
    SAVE_RANGE_RFP_WITH_CONTROLS
} from "../routing/route"

import { request } from "../request/axios.request"
import { Tokens } from "../../app/storage/index"

export async function rfpImportCsvApi(data) {
    let rfpImportCsvData = data.data;
    let formData = new FormData()
    formData.append("file", rfpImportCsvData.file)

    return request({
        url: IMPORT_CSV_RFP,
        method: "post",
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Tokens.getToken() ? Tokens.getToken() : Tokens.getVerifyToken()
                }`,
        }
    })
}

// equipment type
export async function equipmentTypeRfpApi(data) {
    let equipmentTypeRfpData = data.data
    return request({
        url: RFP_EQUIPMENT_TYPE_LIST,
        method: "get",
        params: equipmentTypeRfpData
    })
}

//MANUAL rfp
export async function saveManualRfpApi(data) {
    let saveManualRfp = data.data
    return request({
        url: SAVE_MANUAL_RFP,
        method: "post",
        data: saveManualRfp
    })
}


// Analyzer result data
export async function analyzerResultDataApi(data) {
    let analyzerResultData = data.data
    return request({
        url: ANALYZER_TABLE_RESULT,
        method: "get",
        params: analyzerResultData
    })
}

// Analyzer export data 
export async function analyzerExportDataApi(data) {
    let analyzerExportData = data.data
    return request({
        url: ANALYZER_EXPORT_EXCEL,
        method: "get",
        params: analyzerExportData
    })
}

//  pending calculation count 
export async function pendingCalculationApi(data) {
    let pendingCalculationData = data.data
    return request({
        url: PENDING_CALCULATION_STATUS,
        method: "get",
        params: pendingCalculationData
    })
}
// SAVE rfp details with analyzer 
export async function saveRfpWithControlsApi(data) {
    let saveRfpWithControlsData = data.data
    return request({
        url: SAVE_RFP_WITH_CONTROLS,
        method: "post",
        data: saveRfpWithControlsData
    })
}

// insert rfp refresh calculations
export async function refreshRfpCalculationApi(data) {
    let refreshRfpCalculationData = data.data
    return request({
        url: REFRESH_RFP_CALCULATIONS,
        method: "get",
        data: refreshRfpCalculationData
    })
} 
// get list of all saved RFP 
export async function getSavedRfpListApi(data) {
    let getSavedRfpListData = data.data
    return request({
        url: GET_SAVED_RFP_LIST,
        method: "get",
        params: getSavedRfpListData
    })
}

// Delete rfp
export async function deleteRfpApi(data) {
    let deleteRfpData = data.data
    return request({
        url: DELETE_RFP,
        method: "delete",
        params: deleteRfpData
    })
}

// apply rfp 
export async function applyRfpApi(data) {
    let applyRfpData = data.data
    return request({
        url: APPLY_RFP,
        method: "get",
        params: applyRfpData
    })
}

// get list of all Updated RFP 
export async function getUpdatedRfpListApi(data) {
    let getUpdatedRfpListData = data.data
    return request({
        url: UPDATE_RFP_WITH_CONTROLS,
        method: "post",
        data: getUpdatedRfpListData
    })
}
//RFP_DOWNLOAD_ALERT
export async function rfpDownloadALertApi(data) {
    let rfp = data.data
    return request({
        url: RFP_DOWNLOAD_ALERT,
        method: "post",
        data: rfp
    })
}
// SAVE Range rfp details with analyzer 
export async function saveRangeRfpWithControlsApi(data) {
    let saveRangeRfpWithControlsData = data.data
    return request({
        url: SAVE_RANGE_RFP_WITH_CONTROLS,
        method: "post",
        data: saveRangeRfpWithControlsData
    })
}
