import {
    ERROR_EQUIPMENT_TYPE_RFP,
    ERROR_SAVE_MANUAL_RFP_DATA,
    ERROR_SUBMIT_CSV_RFP,
    SUCCESS_EQUIPMENT_TYPE_RFP,
    SUCCESS_SAVE_MANUAL_RFP_DATA,
    SUCCESS_SUBMIT_CSV_RFP,
    SUCCESS_ANALYZER_RESULT_TABLE_DATA,
    ERROR_ANALYZER_RESULT_TABLE_DATA,
    SUCCESS_ANALYZER_EXPORT_DATA,
    ERROR_ANALYZER_EXPORT_DATA,
    SUCCESS_PENDING_CALCULATION_COUNT,
    ERROR_PENDING_CALCULATION_COUNT,
    SUCCESS_SAVE_RFP_CONTROLS_DATA,
    ERROR_SAVE_RFP_CONTROLS_DATA,
    SUCCESS_GET_SAVED_RFP_LIST,
    ERROR_GET_SAVED_RFP_LIST,
    SUCCESS_DELETE_RFP,
    ERROR_DELETE_RFP,
    SUCCESS_APPLY_SELECTED_RFP,
    ERROR_APPLY_SELECTED_RFP,
    SUCCESS_REFRESH_RFP_CALCULATIONS,
    ERROR_REFRESH_RFP_CALCULATIONS,
    SUCCESS_UPDATE_RFP_CONTROLS_DATA,
    ERROR_UPDATE_RFP_CONTROLS_DATA,
    SUCCESS_RFP_DOWNLOAD_ALERT,
    ERROR_RFP_DOWNLOAD_ALERT,
    SUCCESS_SAVE_RANGE_RFP_CONTROLS_DATA,
    ERROR_SAVE_RANGE_RFP_CONTROLS_DATA,
} from "./rfp.action";
import { DEFAULT_STATE } from "./rfp.state";
export const rfpReducer = (
    state = DEFAULT_STATE,
    action = {
        type: {},
        data: {}
    }
) => {
    switch (action.type) {
        case SUCCESS_SUBMIT_CSV_RFP:
            let submitCsvRfpData = action.data;
            return { ...state, submitCsvRfpData }
        case ERROR_SUBMIT_CSV_RFP:
            let errorSubmitCsvRfpData = action.data
            return { ...state, submitCsvRfpData: errorSubmitCsvRfpData }

        case SUCCESS_EQUIPMENT_TYPE_RFP:
            let equipmentTypeData = action.data
            return { ...state, equipmentTypeData }
        case ERROR_EQUIPMENT_TYPE_RFP:
            let errorEquipmentTypeData = action.data
            return { ...state, equipmentTypeData: errorEquipmentTypeData }

        case SUCCESS_SAVE_MANUAL_RFP_DATA:
            let saveManualRfpData = action.data
            return { ...state, saveManualRfpData }
        case ERROR_SAVE_MANUAL_RFP_DATA:
            let errorSaveManualRfpData = action.data
            return { ...state, saveManualRfpData: errorSaveManualRfpData }



        case SUCCESS_ANALYZER_RESULT_TABLE_DATA:
            let analyzerResultTableData = action.data
            return { ...state, analyzerResultTableData }

        case ERROR_ANALYZER_RESULT_TABLE_DATA:
            let errorAnalyzerResultTableData = action.data
            return { ...state, analyzerResultTableData: errorAnalyzerResultTableData }

        
        case SUCCESS_REFRESH_RFP_CALCULATIONS:
                let refreshRfpCalculationData = action.data
                return { ...state, refreshRfpCalculationData }
                
        case ERROR_REFRESH_RFP_CALCULATIONS:
                let errorRefreshRfpCalculationData = action.data
                return { ...state, refreshRfpCalculationData: errorRefreshRfpCalculationData }
        
        case SUCCESS_ANALYZER_EXPORT_DATA:
            let analyzerExportData = action.data
            return { ...state, analyzerExportData }
        case ERROR_ANALYZER_EXPORT_DATA:
            let errorAnalyzerExportData = action.data
            return { ...state, analyzerExportData: errorAnalyzerExportData }

        case SUCCESS_PENDING_CALCULATION_COUNT:
            let pendingCalculationData = action.data
            return { ...state, pendingCalculationData }
        case ERROR_PENDING_CALCULATION_COUNT:
            let errorPendingCalculationData = action.data
            return { ...state, pendingCalculationData: errorPendingCalculationData }

        case SUCCESS_SAVE_RFP_CONTROLS_DATA:
            let saveRfpControlData = action.data
            return { ...state, saveRfpControlData }
        case ERROR_SAVE_RFP_CONTROLS_DATA:
            let errorSaveRfpControlData = action.data
            return { ...state, saveRfpControlData: errorSaveRfpControlData }

        case SUCCESS_GET_SAVED_RFP_LIST:
            let savedRfpListData = action.data
            return { ...state, savedRfpListData }
        case ERROR_GET_SAVED_RFP_LIST:
            let errorSavedRfpListData = action.data
            return { ...state, savedRfpListData: errorSavedRfpListData }
        case SUCCESS_DELETE_RFP:
            let deleteRfpData = action.data
            return { ...state, deleteRfpData }
        case ERROR_DELETE_RFP:
            let errorDeleteRfpData = action.data
            return { ...state, deleteRfpData: errorDeleteRfpData }

        case SUCCESS_APPLY_SELECTED_RFP:
            let applyRfpData = action.data
            return { ...state, applyRfpData }
        case ERROR_APPLY_SELECTED_RFP:
            let errorApplyRfpData = action.data
            return { ...state, applyRfpData: errorApplyRfpData }
            case SUCCESS_UPDATE_RFP_CONTROLS_DATA:
                let updatedRfpListData = action.data
                return { ...state, updatedRfpListData }
            case ERROR_UPDATE_RFP_CONTROLS_DATA:
                let errorUpdatedRfpListData = action.data
                return { ...state, updatedRfpListData: errorUpdatedRfpListData }
        
        case SUCCESS_RFP_DOWNLOAD_ALERT:
                let rfpDownLoadAlertData = action.data
                return { ...state, rfpDownLoadAlertData }
        case ERROR_RFP_DOWNLOAD_ALERT:
                let errorRfpDownLoadAlertDataa = action.data
                return { ...state, rfpDownLoadAlertData: errorRfpDownLoadAlertDataa }

            case SUCCESS_SAVE_RANGE_RFP_CONTROLS_DATA:
                let saveRangeRfpControlData = action.data
                return { ...state, saveRangeRfpControlData }
            case ERROR_SAVE_RANGE_RFP_CONTROLS_DATA:
                let errorSaveRangeRfpControlData = action.data
                return { ...state, saveRangeRfpControlData: errorSaveRangeRfpControlData }
        default:
            return state;
    }
}
