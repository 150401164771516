// GET RELOAD_CARRIER
export const RELOAD_CARRIER = "RELOAD_CARRIER";
export const reloadCarrierList = (data) => ({ type: RELOAD_CARRIER, data });
export const SUCCESS_RELOAD_CARRIER = "SUCCESS_RELOAD_CARRIER";
export const ERROR_RELOAD_CARRIER = "ERROR_RELOAD_CARRIER";
export const reloadCarrierListResponse = (type, data) => ({ type, data });

// GET RELOAD_REPORT_DOWNLOAD
export const RELOAD_REPORT_DOWNLOAD = "RELOAD_REPORT_DOWNLOAD";
export const reloadreportdownloadList = (data) => ({
  type: RELOAD_REPORT_DOWNLOAD,
  data,
});
export const SUCCESS_RELOAD_REPORT_DOWNLOAD = "SUCCESS_RELOAD_REPORT_DOWNLOAD";
export const ERROR_RELOAD_REPORT_DOWNLOAD = "ERROR_RELOAD_REPORT_DOWNLOAD";
export const reloadreportdownloadListResponse = (type, data) => ({
  type,
  data,
});

// GET RELOAD_REPORT_DOWNLOAD
export const SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD = "SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD";
export const selectAllEmailReloadreportdownloadList = (data) => ({
  type: SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD,
  data,
});
export const SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD = "SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD";
export const ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD = "ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD";
export const selectAllEmailReloadreportdownloadListResponse = (type, data) => ({
  type,
  data,
});

// equipment type
export const RELOAD_EQUIPMENT_TYPE_LIST = "RELOAD_EQUIPMENT_TYPE_LIST"
export const reloadEquipmentTypeList = (data) => ({ type: RELOAD_EQUIPMENT_TYPE_LIST, data })
export const SUCCESS_RELOAD_EQUIPMENT_TYPE_LIST = "SUCCESS_RELOAD_EQUIPMENT_TYPE_LIST"
export const ERROR_RELOAD_EQUIPMENT_TYPE_LIST = "ERROR_RELOAD_EQUIPMENT_TYPE_LIST"
export const reloadEquipmentTypeListResponse = (type, data) => ({ type, data })

// data source
export const RELOAD_DATASOURCE_LIST = "RELOAD_DATASOURCE_LIST"
export const reloadDataSourceList = (data) => ({ type: RELOAD_DATASOURCE_LIST, data })
export const SUCCESS_RELOAD_DATASOURCE_LIST = "SUCCESS_RELOAD_DATASOURCE_LIST"
export const ERROR_RELOAD_DATASOURCE_LIST = "ERROR_RELOAD_DATASOURCE_LIST"
export const reloadDataSourceListResponse = (type, data) => ({ type, data })


// GET RELOAD_CARRIER Departure
export const RELOAD_CARRIER_DEPARTURE = "RELOAD_CARRIER_DEPARTURE";
export const reloadCarrierDepartureList = (data) => ({ type: RELOAD_CARRIER_DEPARTURE, data });
export const SUCCESS_RELOAD_CARRIER_DEPARTURE = "SUCCESS_RELOAD_CARRIER_DEPARTURE";
export const ERROR_RELOAD_CARRIER_DEPARTURE = "ERROR_RELOAD_CARRIER_DEPARTURE";
export const reloadCarrierListDepartureResponse = (type, data) => ({ type, data });

// GET RELOAD_REPORT_DOWNLOAD Departure
export const RELOAD_REPORT_DOWNLOAD_DEPARTURE = "RELOAD_REPORT_DOWNLOAD_DEPARTURE";
export const reloadreportdownloaddepartureList = (data) => ({
  type: RELOAD_REPORT_DOWNLOAD_DEPARTURE,
  data,
});
export const SUCCESS_RELOAD_REPORT_DOWNLOAD_DEPARTURE = "SUCCESS_RELOAD_REPORT_DOWNLOAD_DEPARTURE";
export const ERROR_RELOAD_REPORT_DOWNLOAD_DEPARTURE = "ERROR_RELOAD_REPORT_DOWNLOAD_DEPARTURE";
export const reloadreportdownloaddepartureListResponse = (type, data) => ({
  type,
  data,
});

// GET RELOAD_REPORT_DOWNLOAD Departure
export const SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_DEPARTURE = "SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_DEPARTURE";
export const selectAllEmailReloadreportdownloaddepartureList = (data) => ({
  type: SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_DEPARTURE,
  data,
});
export const SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_DEPARTURE = "SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_DEPARTURE";
export const ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_DEPARTURE = "ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_DEPARTURE";
export const selectAllEmailReloadreportdownloaddepartureListResponse = (type, data) => ({
  type,
  data,
});

//SAVE_RELOAD_FILTERS
export const SAVE_RELOAD_FILTERS = "SAVE_RELOAD_FILTERS";
export const saveReloadFilters = (data) => ({ type: SAVE_RELOAD_FILTERS, data });
export const SUCCESS_SAVE_RELOAD_FILTERS = "SUCCESS_SAVE_RELOAD_FILTERS";
export const ERROR_SAVE_RELOAD_FILTERS = "ERROR_SAVE_RELOAD_FILTERS";
export const saveReloadFiltersResponse = (type, data) => ({ type, data });

//GET_RELOAD_FILTERS
export const GET_RELOAD_FILTERS = "GET_RELOAD_FILTERS";
export const getReloadFilters = (data) => ({ type: GET_RELOAD_FILTERS, data });
export const SUCCESS_GET_RELOAD_FILTERS = "SUCCESS_GET_RELOAD_FILTERS";
export const ERROR_GET_RELOAD_FILTERS = "ERROR_GET_RELOAD_FILTERS";
export const getReloadFiltersResponse = (type, data) => ({ type, data });

//carrierFiltersDeleteApi
export const DELETE_RELOAD_FILTERS = "DELETE_RELOAD_FILTERS";
export const deleteReloadFilters = (data) => ({ type: DELETE_RELOAD_FILTERS, data });
export const SUCCESS_DELETE_RELOAD_FILTERS = "SUCCESS_DELETE_RELOAD_FILTERS";
export const ERROR_DELETE_RELOAD_FILTERS = "ERROR_DELETE_RELOAD_FILTERS";
export const deleteReloadFiltersResponse = (type, data) => ({ type, data });

//UPDATE_RELOAD_FILTERS
export const UPDATE_RELOAD_FILTERS = "UPDATE_RELOAD_FILTERS";
export const updateReloadFilters = (data) => ({ type: UPDATE_RELOAD_FILTERS, data });
export const SUCCESS_UPDATE_RELOAD_FILTERS = "SUCCESS_UPDATE_RELOAD_FILTERS";
export const ERROR_UPDATE_RELOAD_FILTERS = "ERROR_UPDATE_RELOAD_FILTERS";
export const updateReloadFiltersResponse = (type, data) => ({ type, data });

//carrierFiltersDEFAULTApi
export const DEFAULT_RELOAD_FILTERS = "DEFAULT_RELOAD_FILTERS";
export const defaultReloadFilters = (data) => ({ type: DEFAULT_RELOAD_FILTERS, data });
export const SUCCESS_DEFAULT_RELOAD_FILTERS = "SUCCESS_DEFAULT_RELOAD_FILTERS";
export const ERROR_DEFAULT_RELOAD_FILTERS = "ERROR_DEFAULT_RELOAD_FILTERS";
export const defaultReloadFiltersResponse = (type, data) => ({ type, data });


// GET RELOAD_CARRIER PCLIST
export const RELOAD_CARRIER_PCLIST = "RELOAD_CARRIER_PCLIST";
export const reloadCarrierPCList = (data) => ({ type: RELOAD_CARRIER_PCLIST, data });
export const SUCCESS_RELOAD_CARRIER_PCLIST = "SUCCESS_RELOAD_CARRIER_PCLIST";
export const ERROR_RELOAD_CARRIER_PCLIST = "ERROR_RELOAD_CARRIER_PCLIST";
export const reloadCarrierListPCResponse = (type, data) => ({ type, data });


// GET RELOAD_REPORT_DOWNLOAD PCLIST
export const RELOAD_REPORT_DOWNLOAD_PCLIST = "RELOAD_REPORT_DOWNLOAD_PCLIST";
export const reloadreportdownloadPCList = (data) => ({
  type: RELOAD_REPORT_DOWNLOAD_PCLIST,
  data,
});
export const SUCCESS_RELOAD_REPORT_DOWNLOAD_PCLIST = "SUCCESS_RELOAD_REPORT_DOWNLOAD_PCLIST";
export const ERROR_RELOAD_REPORT_DOWNLOAD_PCLIST = "ERROR_RELOAD_REPORT_DOWNLOAD_PCLIST";
export const reloadreportdownloadPCListResponse = (type, data) => ({
  type,
  data,
});

// GET RELOAD_REPORT_DOWNLOAD PCLIST
export const SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_PCLIST = "SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_PCLIST";
export const selectAllEmailReloadreportdownloadPCList = (data) => ({
  type: SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_PCLIST,
  data,
});
export const SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_PCLIST = "SUCCESS_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_PCLIST";
export const ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_PCLIST = "ERROR_SELECT_ALL_EMAIL_RELOAD_REPORT_DOWNLOAD_PCLIST";
export const selectAllEmailReloadreportdownloadPCListResponse = (type, data) => ({
  type,
  data,
});