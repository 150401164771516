import { request } from "../request/axios.request";
import { RELOAD_CARRIER, RELOAD_DATASOURCE, RELOAD_EQUIPMENT_LIST, RELOAD_REPORT_DOWNLOAD ,RELOAD_CARRIER_DEPARTURE,RELOAD_REPORT_DOWNLOAD_DEPARTURE,RELOAD_FILTERS_SAVE,RELOAD_FILTERS_GET
  ,RELOAD_FILTERS_UPDATE, RELOAD_FILTERS_DELETE,RELOAD_FILTERS_DEFAULT,RELOAD_CARRIER_PCLIST} from "../routing/route";

export async function reloadcarrierApi(data) {
  let reloadcarrierdata = data.data;
  return request({
    url: RELOAD_CARRIER,
    method: "get",
    params: reloadcarrierdata,
  });
}

export async function reloadreportdownloadApi(data) {
  let reloadreportdownloaddata = data.data;
  return request({
    url: RELOAD_REPORT_DOWNLOAD,
    method: "get",
    params: reloadreportdownloaddata,
  });
}

export async function reloadEquipmentTypeListApi(data) {
  let reloadEquipmentTypeData = data.data
  return request({
    url: RELOAD_EQUIPMENT_LIST,
    method: "get",
    params: reloadEquipmentTypeData
  })
}
// DATA SOURCE 
export async function reloadDataSourceApi(data) {
  let reloadDataSourceData = data.data
  return request({
    url: RELOAD_DATASOURCE,
    method: "get",
    params: reloadDataSourceData
  })
}
export async function reloadcarrierdepartureApi(data) {
  let reloadcarrierdeparturedata = data.data;
  return request({
    url: RELOAD_CARRIER_DEPARTURE,
    method: "get",
    params: reloadcarrierdeparturedata,
  });
}

export async function reloadreportdownloaddepartureApi(data) {
  let reloadreportdownloaddeparturedata = data.data;
  return request({
    url: RELOAD_REPORT_DOWNLOAD_DEPARTURE,
    method: "get",
    params: reloadreportdownloaddeparturedata,
  });
}
//RELOAD_FILTERS_SAVE
export async function reloadFiltersSaveApi(data) {
  let reloadFiltersSaveData = data.data;
  return request({
    url: RELOAD_FILTERS_SAVE,
    method: "post",
    data: reloadFiltersSaveData,
  });
}

//RELOAD_FILTERS_GET
export async function reloadFiltersGetApi(data) {
  let reloadfiltersGetData = data.data;
  return request({
    url: RELOAD_FILTERS_GET,
    method: "get",
    params: reloadfiltersGetData,
  });
}

//CARRIER_FILTERS_UPDATE
export async function reloadFiltersUpdateApi(data) {
  let reloadFiltersUpdateData = data.data;
  return request({
    url: RELOAD_FILTERS_UPDATE,
    method: "post",
    data: reloadFiltersUpdateData,
  });
}

//CARRIER_FILTERS_DELETE
export async function reloadFiltersDeleteApi(data) {
  let reloadFiltersDeleteData = data.data;
  return request({
    url: RELOAD_FILTERS_DELETE,
    method: "post",
    data: reloadFiltersDeleteData,
  });
}

//CARRIER_FILTERS_DEFAULT
export async function reloadFiltersDefaultApi(data) {
  let reloadFiltersDefaultData = data.data;
  return request({
    url: RELOAD_FILTERS_DEFAULT,
    method: "post",
    data: reloadFiltersDefaultData,
  });
}

//RELOAD_FILTERS_PCLIST
export async function reloadcarrierpcApi(data) {
  let reloadcarrierpcdata = data.data;
  return request({
    url: RELOAD_CARRIER_PCLIST,
    method: "get",
    params: reloadcarrierpcdata,
  });
}