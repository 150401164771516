//TRACK_LOGIN_USER
// export const TRACK_LOGIN_USER = 'TRACK_LOGIN_USER';
// export const trackloginuser = (data) => ({ type: TRACK_LOGIN_USER, data });
// export const SUCCESS_TRACK_LOGIN_USER = 'SUCCESS_TRACK_LOGIN_USER';
// export const ERROR_TRACK_LOGIN_USER = 'ERROR_TRACK_LOGIN_USER';
// export const trackloginuserResponse = (type, data) => ({ type, data });

export const DASHBOARD_FILTER_TRACKING = "DASHBOARD_FILTER_TRACKING";
export const dashboardfiltertracking = (data) => ({
  type: DASHBOARD_FILTER_TRACKING,
  data,
});
export const SUCCESS_DASHBOARD_FILTER_TRACKING =
  "SUCCESS_DASHBOARD_FILTER_TRACKING";
export const ERROR_DASHBOARD_FILTER_TRACKING =
  "ERROR_DASHBOARD_FILTER_TRACKING";
export const dashboardfiltertrackingResponse = (type, data) => ({ type, data });

// GET_USER_LIST
export const USER_SEARCH_LIST = "USER_SEARCH_LIST";
export const userSearchList = (data) => ({ type: USER_SEARCH_LIST, data });
export const SUCCESS_USER_SEARCH_LIST = "SUCCESS_USER_SEARCH_LIST";
export const ERROR_USER_SEARCH_LIST = "ERROR_USER_SEARCH_LIST";
export const userSearchListResponse = (type, data) => ({ type, data });

// DOWNLOAD_METRICS_DATA_EXCEL
export const DOWNLOAD_METRICS_DATA_EXCEL = "DOWNLOAD_METRICS_DATA_EXCEL";
export const downloadMetricsDataExcel = (data) => ({
  type: DOWNLOAD_METRICS_DATA_EXCEL,
  data,
});
export const SUCCESS_DOWNLOAD_METRICS_DATA_EXCEL =
  "SUCCESS_DOWNLOAD_METRICS_DATA_EXCEL";
export const ERROR_DOWNLOAD_METRICS_DATA_EXCEL =
  "ERROR_DOWNLOAD_METRICS_DATA_EXCEL";
export const downloadMetricsDataExcelResponse = (type, data) => ({
  type,
  data,
});

//Metrics User Graph
export const METRICS_USER_GRAPH = "METRICS_USER_GRAPH";
export const metricsTypeGraph = (data) => ({
  type: METRICS_USER_GRAPH,
  data,
});
export const SUCCESS_METRICS_USER_GRAPH = "SUCCESS_METRICS_USER_GRAPH";
export const ERROR_METRICS_USER_GRAPH = "ERROR_METRICS_USER_GRAPH";
export const metricsTypeGraphResponse = (type, data) => ({ type, data });

// GET_USER_LIST
export const METRICS_USER_SEARCH_LIST = "METRICS_USER_SEARCH_LIST";
export const metricesUserSearchList = (data) => ({ type: METRICS_USER_SEARCH_LIST, data });
export const SUCCESS_METRICS_USER_SEARCH_LIST = "SUCCESS_METRICS_USER_SEARCH_LIST";
export const ERROR_METRICS_USER_SEARCH_LIST = "ERROR_METRICS_USER_SEARCH_LIST";
export const metricesUserSearchListResponse = (type, data) => ({ type, data });

// DOWNLOAD_SERACH_FILTER_DATA_EXCEL
export const DOWNLOAD_SEARCH_FILTER_DATA_EXCEL = "DOWNLOAD_SEARCH_FILTER_DATA_EXCEL";
export const downloadSearchFilterDataExcel = (data) => ({
  type: DOWNLOAD_SEARCH_FILTER_DATA_EXCEL,
  data,
});
export const SUCCESS_DOWNLOAD_SEARCH_FILTER_DATA_EXCEL =
  "SUCCESS_DOWNLOAD_SEARCH_FILTER_DATA_EXCEL";
export const ERROR_DOWNLOAD_SEARCH_FILTER_DATA_EXCEL  =
  "ERROR_DOWNLOAD_SEARCH_FILTER_DATA_EXCEL";
export const downloadSearchFilterDataExcelResponse = (type, data) => ({
  type,
  data,
});

