import { put, takeLatest } from "redux-saga/effects";
import _ from "lodash";
import {
  CARRIER_SOURCING_LIST,
  carrierSourcingListResponse,
  ERROR_CARRIER_SOURCING_LIST,
  SUCCESS_CARRIER_SOURCING_LIST,
  SAVE_FILTERS,
  SUCCESS_SAVE_FILTERS,
  ERROR_SAVE_FILTERS,
  saveFiltersResponse,
  SUCCESS_GET_FILTERS,
  ERROR_GET_FILTERS,
  getFiltersResponse,
  GET_FILTERS,
  DELETE_FILTERS,
  deleteFiltersResponse,
  SUCCESS_DELETE_FILTERS,
  ERROR_DELETE_FILTERS,
  saveCarrierFiltersResponse,
  SUCCESS_SAVE_CARRIER_RATING,
  ERROR_SAVE_CARRIER_RATING,
  SAVE_CARRIER_RATING,
  DOWNLOAD_CARRIER_SOURCING_EXCEL,
  SUCCESS_DOWNLOAD_CARRIER_SOURCING_EXCEL,
  ERROR_DOWNLOAD_CARRIER_SOURCING_EXCEL,
  downloadCarrierSourcingExcelResponse,
  UPDATE_CARRIER_DETAILS,
  SUCCESS_UPDATE_CARRIER_DETAILS,
  ERROR_UPDATE_CARRIER_DETAILS,
  updateCarrierDetialsResponse,
  SUCCESS_TEMPLATE_LIST_,
  ERROR_TEMPLATE_LIST_,
  templateListResponseData,
  TEMPLATE_LIST_,
  SUCCESS_GET_COLUMN_ORDER_FILTER,
  ERROR_GET_COLUMN_ORDER_FILTER,
  GET_COLUMN_ORDER_FILTER,
  getColumnOrderFilterResponse,
  SUCCESS_SAVE_COLUMN_ORDER,
  saveColumnOrderResponse,
  ERROR_SAVE_COLUMN_ORDER,
  SAVE_COLUMN_ORDER,
  originSearchListResponse,
  SUCCESS_ORIGIN_SEARCH_LIST,
  ERROR_ORIGIN_SEARCH_LIST,
  ORIGIN_SEARCH_LIST,
  destinationSearchListResponse,
  SUCCESS_DESTINATION_SEARCH_LIST,
  ERROR_DESTINATION_SEARCH_LIST,
  DESTINATION_SEARCH_LIST,
  searchCarrierListResponse,
  SUCCESS_SEARCH_CARRIER_LIST,
  ERROR_SEARCH_CARRIER_LIST,
  SEARCH_CARRIER_LIST,
  DOWNLOAD_CARRIER_DATA_EXCEL,
  originDestinationDetailResponse,
  SUCCESS_ORIGIN_DESTINATION_DETAIL,
  ERROR_ORIGIN_DESTINATION_DETAIL,
  ORIGIN_DESTINATION_DETAIL,
  carrierDetailResponse,
  SUCCESS_CARRIER_DETAIL,
  ERROR_CARRIER_DETAIL,
  CARRIER_DETAIL,
  ERROR_UPDATE_FILTERS,
  SUCCESS_UPDATE_FILTERS,
  UPDATE_FILTERS,
  updateFiltersResponse,
  SAVE_NOTES,
  SUCCESS_SAVE_NOTES,
  ERROR_SAVE_NOTES,
  saveNotesResponse,
  updateBasicDetailResponse,
  SUCCESS_UPDATE_BASIC_DETAIL,
  ERROR_UPDATE_BASIC_DETAIL,
  UPDATE_BASIC_DETAIL,
  UPDATE_OPERATIONS,
  updateOperationsResponse,
  UPDATE_CARRIER_CONTACT,
  updateCarrierContactResponse,
  SUCCESS_UPDATE_OPERATIONS,
  ERROR_UPDATE_OPERATIONS,
  UPDATE_DISPATCHER_CONTACT,
  updateDispatcherContactResponse,
  SUCCESS_UPDATE_CARRIER_CONTACT,
  ERROR_UPDATE_CARRIER_CONTACT,
  SUCCESS_UPDATE_DISPATCHER_CONTACT,
  ERROR_UPDATE_DISPATCHER_CONTACT,
  updateAuthorityDetailResponse,
  SUCCESS_UPDATE_AUTHORITY_DETAIL,
  ERROR_UPDATE_AUTHORITY_DETAIL,
  UPDATE_AUTHORITY_DETAIL,
  updateFocusAreaResponse,
  SUCCESS_UPDATE_FOCUS_AREA,
  ERROR_UPDATE_FOCUS_AREA,
  UPDATE_FOCUS_AREA,
  deleteMyCarrierResponse,
  MY_DELETE_CARRIER,
  ERROR_MY_DELETE_CARRIER,
  SUCCESS_MY_DELETE_CARRIER,
  SUCCESS_MY_CARRIER_GET,
  ERROR_MY_CARRIER_GET,
  MY_CARRIER_GET,
  getMyCarrierResponse,
  SUCCESS_UPDATE_MY_CARRIER_DETAIL,
  ERROR_UPDATE_MY_CARRIER_DETAIL,
  UPDATE_MY_CARRIER_DETAIL,
  SUCCESS_MY_CARRIER_ADD,
  ERROR_MY_CARRIER_ADD,
  addMyCarrierDetailResponse,
  MY_CARRIER_ADD,
  SUCCESS_GET_MY_CARRIER_BY_ID,
  getMyCarrierByIdResponse,
  ERROR_GET_MY_CARRIER_BY_ID,
  GET_MY_CARRIER_BY_ID,
  updateMyCarrierDetailResponse,
  SUCCESS_GET_MARKET_DATA_GRAPH,
  ERROR_GET_MARKET_DATA_GRAPH,
  getMarketDataGraphResponse,
  getAdditionalMarketDataResponse,
  GET_MARKET_DATA_GRAPH,
  SUCCESS_GET_ADDITIONAL_MARKET_DATA,
  ERROR_GET_ADDITIONAL_MARKET_DATA,
  GET_ADDITIONAL_MARKET_DATA,
  SUCCESS_EMAIL_SELECTALL_DOWNLOAD_CARRIER_SOURCING_EXCEL,
  ERROR_EMAIL_SELECTALL_DOWNLOAD_CARRIER_SOURCING_EXCEL,
  EMAIL_SELECTALL_DOWNLOAD_CARRIER_SOURCING_EXCEL,
  headquartersSearchListResponse,
  SUCCESS_HEADQUARTERS_SEARCH_LIST,
  ERROR_HEADQUARTERS_SEARCH_LIST,
  HEADQUARTERS_SEARCH_LIST,
  SUCCESS_ADVANCE_SEARCH_EQUIPMENT_LIST,
  ERROR_ADVANCE_SEARCH_EQUIPMENT_LIST,
  ADVANCE_SEARCH_EQUIPMENT_LIST,
  advanceSearchEquipmentListResponse,
  advanceMarketEquipmentListResponse,
  SUCCESS_ADVANCE_MARKET_EQUIPMENT_LIST,
  ERROR_ADVANCE_MARKET_EQUIPMENT_LIST,
  ADVANCE_MARKET_EQUIPMENT_LIST,
  dashboardDataSorceListResponse,
  SUCCESS_DASHBOARD_DATA_SOURCE_LIST,
  ERROR_DASHBOARD_DATA_SOURCE_LIST,
  DASHBOARD_DATA_SOURCE_LIST,
  dashboardUserListResonse,
  SUCCESS_DASHBOARD_USER_LIST,
  ERROR_DASHBOARD_USER_LIST,
  DASHBOARD_USER_LIST,
  carrierUserListResonse,
  SUCCESS_CARRIER_USER_LIST,
  ERROR_CARRIER_USER_LIST,
  CARRIER_USER_LIST,
  lanesAndMapListResponse,
  SUCCESS_LANES_AND_MAP_LIST,
  ERROR_LANES_AND_MAP_LIST,
  LANES_AND_MAP_LIST,
  notesDeleteDashboardListResponse,
  SUCCESS_NOTES_DELETE_DASHBOARD,
  ERROR_NOTES_DELETE_DASHBOARD,
  NOTES_DELETE_DASHBOARD,
  headquartersSearchValueListResponse,
  SUCCESS_HEADQUARTERS_SEARCH_VALUE_LIST,
  ERROR_HEADQUARTERS_SEARCH_VALUE_LIST,
  HEADQUARTERS_SEARCH_VALUE_LIST,
  destinationMarketSearchListResponse,
  SUCCESS_DESTINATION_MARKET_SEARCH_LIST,
  ERROR_DESTINATION_MARKET_SEARCH_LIST,
  DESTINATION_MARKET_SEARCH_LIST
} from "./dashboard.action";

import {
  carrierFiltersDeleteApi,
  carrierFiltersGetApi,
  carrierFiltersSaveApi,
  carrierSourcingDownloadExcelApi,
  carrierSourcingListApi,
  carrierUpdateDetailsApi,
  GetColumnFiltersDataApi,
  getOriginSearchListApi,
  getDestinationSearchListApi,
  saveCarrierRatingApi,
  saveColumnFilterOrderApi,
  templateListApi,
  getSearchCarrierListApi,
  downloadCarrierDataExcelApi,
  originDestinationDetailApi,
  carrierDetailApi,
  carrierFiltersUpdateApi,
  saveNotesApi,
  updateBasicDetailApi,
  updateOperationsApi,
  updateCarrierContactApi,
  updateDispatcherContactApi,
  updateAuthorityDetailApi,
  updateFocusAreaApi,
  mycarrierDeleteApi,
  mycarriergetApi,
  mycarrierAddApi,
  getMyCarrierByIdApi,
  getMarketDataGraphApi,
  getAdditionalMarketDataApi,
  advanceSearchEquipmentApi,
  advanceMarketEquipmentApi,
  dataSourceApi,
  dashboardUserListApi,
  carrierUserListApi,
  getLanesAndMapApi,
  noteDeleteApi,
  headquartersSearchListApi,
} from "../../../api/sdk/dashboard";

function* carrierSourcingList(userData) {
  let carrierSourcingListData = yield carrierSourcingListApi(userData);
  if (
    carrierSourcingListData.success &&
    _.has(carrierSourcingListData, "data.data")
  ) {
    yield put(
      carrierSourcingListResponse(
        SUCCESS_CARRIER_SOURCING_LIST,
        carrierSourcingListData.data
      )
    );
    //message.success(carrierSourcingListData.data.message)
  } else {
    yield put(
      carrierSourcingListResponse(
        ERROR_CARRIER_SOURCING_LIST,
        carrierSourcingListData.data
      )
    );
    //message.error(carrierSourcingListData.data.message)
  }
}
export function* carrierSourcingListWatcher() {
  yield takeLatest(CARRIER_SOURCING_LIST, carrierSourcingList);
}

function* saveFilters(userData) {
  let successSaveFiltersData = yield carrierFiltersSaveApi(userData);
  if (
    successSaveFiltersData.success &&
    _.has(successSaveFiltersData, "data.data")
  ) {
    yield put(
      saveFiltersResponse(SUCCESS_SAVE_FILTERS, successSaveFiltersData.data)
    );
    //message.success(successSaveFiltersData.data.message)
  } else {
    yield put(
      saveFiltersResponse(ERROR_SAVE_FILTERS, successSaveFiltersData.data)
    );
    //message.error(successSaveFiltersData.data.message)
  }
}
export function* saveFiltersWatcher() {
  yield takeLatest(SAVE_FILTERS, saveFilters);
}

//get filters
function* getFilters(userData) {
  let getFiltersData = yield carrierFiltersGetApi(userData);
  if (getFiltersData.success && _.has(getFiltersData, "data.data")) {
    yield put(getFiltersResponse(SUCCESS_GET_FILTERS, getFiltersData.data));
    //message.success(getFiltersData.data.message)
  } else {
    yield put(getFiltersResponse(ERROR_GET_FILTERS, getFiltersData.data));
    //message.error(getFiltersData.data.message)
  }
}
export function* getFiltersWatcher() {
  yield takeLatest(GET_FILTERS, getFilters);
}

//delete filters
function* deleteFilters(userData) {
  let deleteFiltersData = yield carrierFiltersDeleteApi(userData);
  if (deleteFiltersData.success && _.has(deleteFiltersData, "data.data")) {
    yield put(
      deleteFiltersResponse(SUCCESS_DELETE_FILTERS, deleteFiltersData.data)
    );
    //message.success(deleteFiltersData.data.message)
  } else {
    yield put(
      deleteFiltersResponse(ERROR_DELETE_FILTERS, deleteFiltersData.data)
    );
    //message.error(deleteFiltersData.data.message)
  }
}
export function* deleteFiltersWatcher() {
  yield takeLatest(DELETE_FILTERS, deleteFilters);
}

//save carriers Rating filters
function* saveCarrierRatings(userData) {
  let saveCarrierRatingsData = yield saveCarrierRatingApi(userData);
  if (
    saveCarrierRatingsData.success &&
    _.has(saveCarrierRatingsData, "data.data")
  ) {
    yield put(
      saveCarrierFiltersResponse(
        SUCCESS_SAVE_CARRIER_RATING,
        saveCarrierRatingsData.data
      )
    );
    //message.success(saveCarrierRatingsData.data.message)
  } else {
    yield put(
      saveCarrierFiltersResponse(
        ERROR_SAVE_CARRIER_RATING,
        saveCarrierRatingsData.data
      )
    );
    //message.error(saveCarrierRatingsData.data.message)
  }
}
export function* saveCarrierRatingsWatcher() {
  yield takeLatest(SAVE_CARRIER_RATING, saveCarrierRatings);
}

//Template List
function* templateListRequest(userData) {
  let getData = yield templateListApi(userData);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(templateListResponseData(SUCCESS_TEMPLATE_LIST_, getData.data));
    // message.success(getData.data.message)
  } else {
    yield put(templateListResponseData(ERROR_TEMPLATE_LIST_, getData.data));
    //message.error(getData.data.message)
  }
}
export function* templateListDataWatcher() {
  yield takeLatest(TEMPLATE_LIST_, templateListRequest);
}

//get carrierSourcingDownloadPdf
function* carrierSourcingDownloadExcel(userData) {
  let carrierSourcingDownloadExcelData = yield carrierSourcingDownloadExcelApi(
    userData
  );
  if (
    carrierSourcingDownloadExcelData.success &&
    _.has(carrierSourcingDownloadExcelData, "data.data")
  ) {
    yield put(
      downloadCarrierSourcingExcelResponse(
        SUCCESS_DOWNLOAD_CARRIER_SOURCING_EXCEL,
        carrierSourcingDownloadExcelData.data
      )
    );
    //message.success(carrierSourcingDownloadExcelData.data.message)
  } else {
    yield put(
      downloadCarrierSourcingExcelResponse(
        ERROR_DOWNLOAD_CARRIER_SOURCING_EXCEL,
        carrierSourcingDownloadExcelData.data
      )
    );
    //message.error(carrierSourcingDownloadExcelData.data.message)
  }
}
export function* carrierSourcingDownloadExcelWatcher() {
  yield takeLatest(
    DOWNLOAD_CARRIER_SOURCING_EXCEL,
    carrierSourcingDownloadExcel
  );
}
//carrierUpdateDetailsApi
function* carrierUpdateDetails(userData) {
  let carrierUpdateDetailsData = yield carrierUpdateDetailsApi(userData);
  if (
    carrierUpdateDetailsData.success &&
    _.has(carrierUpdateDetailsData, "data.data")
  ) {
    yield put(
      updateCarrierDetialsResponse(
        SUCCESS_UPDATE_CARRIER_DETAILS,
        carrierUpdateDetailsData.data
      )
    );
    //message.success(carrierUpdateDetailsData.data.message)
  } else {
    yield put(
      updateCarrierDetialsResponse(
        ERROR_UPDATE_CARRIER_DETAILS,
        carrierUpdateDetailsData.data
      )
    );
    //message.error(carrierUpdateDetailsData.data.message)
  }
}
export function* carrierUpdateDetailsWatcher() {
  yield takeLatest(UPDATE_CARRIER_DETAILS, carrierUpdateDetails);
}

//getColumnOrderFilter
function* getColumnOrderFilters(userData) {
  let getColumnOrderFilterData = yield GetColumnFiltersDataApi(userData);
  if (
    getColumnOrderFilterData.success &&
    _.has(getColumnOrderFilterData, "data.data")
  ) {
    yield put(
      getColumnOrderFilterResponse(
        SUCCESS_GET_COLUMN_ORDER_FILTER,
        getColumnOrderFilterData.data
      )
    );
    //message.success(getColumnOrderFilterData.data.message)
  } else {
    yield put(
      getColumnOrderFilterResponse(
        ERROR_GET_COLUMN_ORDER_FILTER,
        getColumnOrderFilterData.data
      )
    );
    //message.error(getColumnOrderFilterData.data.message)
  }
}
export function* getColumnOrderFiltersWatcher() {
  yield takeLatest(GET_COLUMN_ORDER_FILTER, getColumnOrderFilters);
}

//saveColumnOrder
function* saveColumnOrderFilters(userData) {
  let saveColumnOrderData = yield saveColumnFilterOrderApi(userData);
  if (saveColumnOrderData.success && _.has(saveColumnOrderData, "data.data")) {
    yield put(
      saveColumnOrderResponse(
        SUCCESS_SAVE_COLUMN_ORDER,
        saveColumnOrderData.data
      )
    );
    //message.success(saveColumnOrderData.data.message)
  } else {
    yield put(
      saveColumnOrderResponse(ERROR_SAVE_COLUMN_ORDER, saveColumnOrderData.data)
    );
    //message.error(saveColumnOrderData.data.message)
  }
}
export function* saveColumnOrderFiltersWatcher() {
  yield takeLatest(SAVE_COLUMN_ORDER, saveColumnOrderFilters);
}

//get origin list by searching keyword
function* originSearchList(data) {
  let originSearchListData = yield getOriginSearchListApi(data);
  if (
    originSearchListData.success &&
    _.has(originSearchListData, "data.data")
  ) {
    yield put(
      originSearchListResponse(
        SUCCESS_ORIGIN_SEARCH_LIST,
        originSearchListData.data
      )
    );
  } else {
    yield put(
      originSearchListData(ERROR_ORIGIN_SEARCH_LIST, originSearchListData.data)
    );
  }
}
export function* originSearchListWatcher() {
  yield takeLatest(ORIGIN_SEARCH_LIST, originSearchList);
}

//get destination list by searching keyword
function* destinationSearchList(data) {
  let destinationSearchListData = yield getDestinationSearchListApi(data);
  if (
    destinationSearchListData.success &&
    _.has(destinationSearchListData, "data.data")
  ) {
    yield put(
      destinationSearchListResponse(
        SUCCESS_DESTINATION_SEARCH_LIST,
        destinationSearchListData.data
      )
    );
  } else {
    yield put(
      destinationSearchListData(
        ERROR_DESTINATION_SEARCH_LIST,
        destinationSearchListData.data
      )
    );
  }
}
export function* destinationSearchListWatcher() {
  yield takeLatest(DESTINATION_SEARCH_LIST, destinationSearchList);
}

//get carriers list by filter search
function* searchCarriers(data) {
  let searchCarrierListData = yield getSearchCarrierListApi(data);
  if (
    searchCarrierListData.success &&
    _.has(searchCarrierListData, "data.data")
  ) {
    yield put(
      searchCarrierListResponse(
        SUCCESS_SEARCH_CARRIER_LIST,
        searchCarrierListData.data
      )
    );
  } else {
    yield put(
      searchCarrierListResponse(
        ERROR_SEARCH_CARRIER_LIST,
        searchCarrierListData.data
      )
    );
  }
}

export function* searchCarrierListWatcher() {
  yield takeLatest(SEARCH_CARRIER_LIST, searchCarriers);
}

//download carrier data in excel
function* downloadCarrierSourcingExcelData(data) {
  let downloadCarrierSourcingExcelData = yield downloadCarrierDataExcelApi(
    data
  );
  if (
    downloadCarrierSourcingExcelData.success &&
    _.has(downloadCarrierSourcingExcelData, "data.data")
  ) {
    yield put(
      downloadCarrierSourcingExcelResponse(
        SUCCESS_DOWNLOAD_CARRIER_SOURCING_EXCEL,
        downloadCarrierSourcingExcelData.data
      )
    );
  } else {
    yield put(
      downloadCarrierSourcingExcelResponse(
        ERROR_DOWNLOAD_CARRIER_SOURCING_EXCEL,
        downloadCarrierSourcingExcelData.data
      )
    );
  }
}

export function* downloadCarrierSourcingExcelDataWatcher() {
  yield takeLatest(
    DOWNLOAD_CARRIER_DATA_EXCEL,
    downloadCarrierSourcingExcelData
  );
}

//origin destination detail
function* originDestinationDetail(data) {
  let originDestinationDetail = yield originDestinationDetailApi(data);
  if (
    originDestinationDetail.success &&
    _.has(originDestinationDetail, "data.data")
  ) {
    yield put(
      originDestinationDetailResponse(
        SUCCESS_ORIGIN_DESTINATION_DETAIL,
        originDestinationDetail.data
      )
    );
  } else {
    yield put(
      originDestinationDetail(
        ERROR_ORIGIN_DESTINATION_DETAIL,
        originDestinationDetail.data
      )
    );
  }
}

export function* originDestinationDetailWatcher() {
  yield takeLatest(ORIGIN_DESTINATION_DETAIL, originDestinationDetail);
}

//carrier detail

function* carrierDetail(data) {
  let getData = yield carrierDetailApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(carrierDetailResponse(SUCCESS_CARRIER_DETAIL, getData.data));
    // message.success(getData.data.message)
  } else {
    yield put(carrierDetailResponse(ERROR_CARRIER_DETAIL, getData.data));
    // message.error(getData.data.message)
  }
}
export function* carrierDetailWatcher() {
  yield takeLatest(CARRIER_DETAIL, carrierDetail);
}

function* updateFilters(userData) {
  let successUpdateFiltersData = yield carrierFiltersUpdateApi(userData);
  if (
    successUpdateFiltersData.success &&
    _.has(successUpdateFiltersData, "data.data")
  ) {
    yield put(
      updateFiltersResponse(
        SUCCESS_UPDATE_FILTERS,
        successUpdateFiltersData.data
      )
    );
    //message.success(successSaveFiltersData.data.message)
  } else {
    yield put(
      updateFiltersResponse(ERROR_UPDATE_FILTERS, successUpdateFiltersData.data)
    );
    //message.error(successSaveFiltersData.data.message)
  }
}
export function* updateFiltersWatcher() {
  yield takeLatest(UPDATE_FILTERS, updateFilters);
}

function* saveNotes(userData) {
  let successSaveNotesData = yield saveNotesApi(userData);
  if (
    successSaveNotesData.success &&
    _.has(successSaveNotesData, "data.data")
  ) {
    yield put(saveNotesResponse(SUCCESS_SAVE_NOTES, successSaveNotesData.data));
    //message.success(successSaveFiltersData.data.message)
  } else {
    yield put(saveNotesResponse(ERROR_SAVE_NOTES, successSaveNotesData.data));
    //message.error(successSaveFiltersData.data.message)
  }
}
export function* saveNotesWatcher() {
  yield takeLatest(SAVE_NOTES, saveNotes);
}

function* updateBasicDetail(userData) {
  let successUpdateBasicDetailData = yield updateBasicDetailApi(userData);
  if (
    successUpdateBasicDetailData.success &&
    _.has(successUpdateBasicDetailData, "data.data")
  ) {
    yield put(
      updateBasicDetailResponse(
        SUCCESS_UPDATE_BASIC_DETAIL,
        successUpdateBasicDetailData.data
      )
    );
  } else {
    yield put(
      updateBasicDetailResponse(
        ERROR_UPDATE_BASIC_DETAIL,
        successUpdateBasicDetailData.data
      )
    );
  }
}
export function* updateBasicDetailWatcher() {
  yield takeLatest(UPDATE_BASIC_DETAIL, updateBasicDetail);
}

function* updateOperations(userData) {
  let successUpdateOperationsData = yield updateOperationsApi(userData);
  if (
    successUpdateOperationsData.success &&
    _.has(successUpdateOperationsData, "data.data")
  ) {
    yield put(
      updateOperationsResponse(
        SUCCESS_UPDATE_OPERATIONS,
        successUpdateOperationsData.data
      )
    );
  } else {
    yield put(
      updateOperationsResponse(
        ERROR_UPDATE_OPERATIONS,
        successUpdateOperationsData.data
      )
    );
  }
}
export function* updateOperationsWatcher() {
  yield takeLatest(UPDATE_OPERATIONS, updateOperations);
}

function* updateCarrierContact(userData) {
  let successUpdateCarrierContactData = yield updateCarrierContactApi(userData);
  if (
    successUpdateCarrierContactData.success &&
    _.has(successUpdateCarrierContactData, "data.data")
  ) {
    yield put(
      updateCarrierContactResponse(
        SUCCESS_UPDATE_CARRIER_CONTACT,
        successUpdateCarrierContactData.data
      )
    );
  } else {
    yield put(
      updateCarrierContactResponse(
        ERROR_UPDATE_CARRIER_CONTACT,
        successUpdateCarrierContactData.data
      )
    );
  }
}
export function* updateCarrierContactWatcher() {
  yield takeLatest(UPDATE_CARRIER_CONTACT, updateCarrierContact);
}

function* updateDispatcherContact(userData) {
  let successUpdateDispatcherContactData = yield updateDispatcherContactApi(
    userData
  );
  if (
    successUpdateDispatcherContactData.success &&
    _.has(successUpdateDispatcherContactData, "data.data")
  ) {
    yield put(
      updateDispatcherContactResponse(
        SUCCESS_UPDATE_DISPATCHER_CONTACT,
        successUpdateDispatcherContactData.data
      )
    );
  } else {
    yield put(
      updateDispatcherContactResponse(
        ERROR_UPDATE_DISPATCHER_CONTACT,
        successUpdateDispatcherContactData.data
      )
    );
  }
}
export function* updateDispatcherContactWatcher() {
  yield takeLatest(UPDATE_DISPATCHER_CONTACT, updateDispatcherContact);
}

function* updateAuthorityDetail(userData) {
  let successUpdateAuthorityDetailData = yield updateAuthorityDetailApi(
    userData
  );
  if (
    successUpdateAuthorityDetailData.success &&
    _.has(successUpdateAuthorityDetailData, "data.data")
  ) {
    yield put(
      updateAuthorityDetailResponse(
        SUCCESS_UPDATE_AUTHORITY_DETAIL,
        successUpdateAuthorityDetailData.data
      )
    );
  } else {
    yield put(
      updateAuthorityDetailResponse(
        ERROR_UPDATE_AUTHORITY_DETAIL,
        successUpdateAuthorityDetailData.data
      )
    );
  }
}

export function* updateAuthorityDetailWatcher() {
  yield takeLatest(UPDATE_AUTHORITY_DETAIL, updateAuthorityDetail);
}

function* updateFocusArea(userData) {
  let successUpdateFocusAreaData = yield updateFocusAreaApi(userData);
  if (
    successUpdateFocusAreaData.success &&
    _.has(successUpdateFocusAreaData, "data.data")
  ) {
    yield put(
      updateFocusAreaResponse(
        SUCCESS_UPDATE_FOCUS_AREA,
        successUpdateFocusAreaData.data
      )
    );
  } else {
    yield put(
      updateFocusAreaResponse(
        ERROR_UPDATE_FOCUS_AREA,
        successUpdateFocusAreaData.data
      )
    );
  }
}

export function* updateFocusAreaWatcher() {
  yield takeLatest(UPDATE_FOCUS_AREA, updateFocusArea);
}

//delete carrier
function* deleteMyCarrier(userData) {
  let deleteMyCarrierData = yield mycarrierDeleteApi(userData);
  if (deleteMyCarrierData.success && _.has(deleteMyCarrierData, "data.data")) {
    yield put(
      deleteMyCarrierResponse(
        SUCCESS_MY_DELETE_CARRIER,
        deleteMyCarrierData.data
      )
    );
  } else {
    yield put(
      deleteMyCarrierResponse(ERROR_MY_DELETE_CARRIER, deleteMyCarrierData.data)
    );
  }
}
export function* deleteMyCarrierWatcher() {
  yield takeLatest(MY_DELETE_CARRIER, deleteMyCarrier);
}
//get Carrier
function* getMyCarrier(userData) {
  let getMyCarrierData = yield mycarriergetApi(userData);
  if (getMyCarrierData.success && _.has(getMyCarrierData, "data.data")) {
    yield put(
      getMyCarrierResponse(SUCCESS_MY_CARRIER_GET, getMyCarrierData.data)
    );
    //message.success(getMyCarrierData.data.message)
  } else {
    yield put(
      getMyCarrierResponse(ERROR_MY_CARRIER_GET, getMyCarrierData.data)
    );
    //message.error(getMyCarrierData.data.message)
  }
}
export function* getMyCarrierWatcher() {
  yield takeLatest(MY_CARRIER_GET, getMyCarrier);
}

//carrierUpdateDetailsApi
function* carrierUpdateDetail(userData) {
  let carrierUpdateDetailData = yield carrierUpdateDetailsApi(userData);
  if (
    carrierUpdateDetailData.success &&
    _.has(carrierUpdateDetailData, "data.data")
  ) {
    yield put(
      updateMyCarrierDetailResponse(
        SUCCESS_UPDATE_MY_CARRIER_DETAIL,
        carrierUpdateDetailData.data
      )
    );
    //message.success(carrierUpdateDetailsData.data.message)
  } else {
    yield put(
      updateMyCarrierDetailResponse(
        ERROR_UPDATE_MY_CARRIER_DETAIL,
        carrierUpdateDetailData.data
      )
    );
    //message.error(carrierUpdateDetailsData.data.message)
  }
}
export function* carrierUpdateDetailWatcher() {
  yield takeLatest(UPDATE_MY_CARRIER_DETAIL, carrierUpdateDetail);
}

//mycarrierAddApi
function* carrierAddDetail(userData) {
  let carrierAddDetailData = yield mycarrierAddApi(userData);
  if (
    carrierAddDetailData.success &&
    _.has(carrierAddDetailData, "data.data")
  ) {
    yield put(
      addMyCarrierDetailResponse(
        SUCCESS_MY_CARRIER_ADD,
        carrierAddDetailData.data
      )
    );
    //message.success(carrierUpdateDetailsData.data.message)
  } else {
    yield put(
      addMyCarrierDetailResponse(
        ERROR_MY_CARRIER_ADD,
        carrierAddDetailData.data
      )
    );
    //message.error(carrierUpdateDetailsData.data.message)
  }
}
export function* carrierAddDetailWatcher() {
  yield takeLatest(MY_CARRIER_ADD, carrierAddDetail);
}
//MY_CARRIER_GET_LIST
function* getMyCarrierById(userData) {
  let carrierListData = yield getMyCarrierByIdApi(userData);
  if (carrierListData.success && _.has(carrierListData, "data.data")) {
    yield put(
      getMyCarrierByIdResponse(
        SUCCESS_GET_MY_CARRIER_BY_ID,
        carrierListData.data
      )
    );
  } else {
    yield put(
      getMyCarrierByIdResponse(ERROR_GET_MY_CARRIER_BY_ID, carrierListData.data)
    );
  }
}
export function* getMyCarrierByIdWatcher() {
  yield takeLatest(GET_MY_CARRIER_BY_ID, getMyCarrierById);
}

// MARKET_DATA_GRAPH
function* getMarketDataGraph(userData) {
  let marketGraphData = yield getMarketDataGraphApi(userData);
  if (marketGraphData.success && _.has(marketGraphData, "data.data")) {
    yield put(
      getMarketDataGraphResponse(
        SUCCESS_GET_MARKET_DATA_GRAPH,
        marketGraphData.data
      )
    );
  } else {
    yield put(
      getMarketDataGraphResponse(
        ERROR_GET_MARKET_DATA_GRAPH,
        marketGraphData.data
      )
    );
  }
}
export function* getMarketDataGraphWatcher() {
  yield takeLatest(GET_MARKET_DATA_GRAPH, getMarketDataGraph);
}

// ADDITIONAL_MARKET_DATA
function* getAdditionalMarketData(userData) {
  let additionalMarketGraphData = yield getAdditionalMarketDataApi(userData);
  if (
    additionalMarketGraphData.success &&
    _.has(additionalMarketGraphData, "data.data")
  ) {
    yield put(
      getAdditionalMarketDataResponse(
        SUCCESS_GET_ADDITIONAL_MARKET_DATA,
        additionalMarketGraphData.data
      )
    );
  } else {
    yield put(
      getAdditionalMarketDataResponse(
        ERROR_GET_ADDITIONAL_MARKET_DATA,
        additionalMarketGraphData.data
      )
    );
  }
}
export function* getAdditionalMarketDataWatcher() {
  yield takeLatest(GET_ADDITIONAL_MARKET_DATA, getAdditionalMarketData);
}


//download carrier data in excel
function* selectAllEmailDownloadCarrierSourcingExcelData(data) {
  let selectAllEmailDownloadCarrierSourcingExcelData = yield downloadCarrierDataExcelApi(
    data
  );
  if (
    selectAllEmailDownloadCarrierSourcingExcelData.success &&
    _.has(selectAllEmailDownloadCarrierSourcingExcelData, "data.data")
  ) {
    yield put(
      downloadCarrierSourcingExcelResponse(
        SUCCESS_EMAIL_SELECTALL_DOWNLOAD_CARRIER_SOURCING_EXCEL,
        selectAllEmailDownloadCarrierSourcingExcelData.data
      )
    );
  } else {
    yield put(
      downloadCarrierSourcingExcelResponse(
        ERROR_EMAIL_SELECTALL_DOWNLOAD_CARRIER_SOURCING_EXCEL,
        selectAllEmailDownloadCarrierSourcingExcelData.data
      )
    );
  }
}

export function* selectAllEmailDownloadCarrierSourcingExcelDataWatcher() {
  yield takeLatest(
    EMAIL_SELECTALL_DOWNLOAD_CARRIER_SOURCING_EXCEL,
    selectAllEmailDownloadCarrierSourcingExcelData
  );
}


// Get_Headquarters_List
function* getHeadquaterListData(userData) {
  let headquaterListData = yield getOriginSearchListApi(userData);
  if (headquaterListData.success && _.has(headquaterListData, "data.data")) {
    yield put(
      headquartersSearchListResponse(
        SUCCESS_HEADQUARTERS_SEARCH_LIST,
        headquaterListData.data
      )
    );
  } else {
    yield put(
      headquartersSearchListResponse(
        ERROR_HEADQUARTERS_SEARCH_LIST,
        headquaterListData.data
      )
    );
  }
}
export function* getHeadquaterListDataWatcher() {
  yield takeLatest(HEADQUARTERS_SEARCH_LIST, getHeadquaterListData);
}
//  advance search equipment list
function* advanceSearchEquipmentList(data) {
  let advanceSearchEquipmentTypeList = yield advanceSearchEquipmentApi(data);
  if (advanceSearchEquipmentTypeList.success &&
    _.has(advanceSearchEquipmentTypeList, "data.data")) {
    yield put(advanceSearchEquipmentListResponse(
      SUCCESS_ADVANCE_SEARCH_EQUIPMENT_LIST,
      advanceSearchEquipmentTypeList.data
    ))
  } else {
    yield put(advanceSearchEquipmentListResponse(
      ERROR_ADVANCE_SEARCH_EQUIPMENT_LIST,
      advanceSearchEquipmentTypeList.data
    ))
  }
}

export function* advanceSearchEquipmentListWatcher() {
  yield takeLatest(
    ADVANCE_SEARCH_EQUIPMENT_LIST,
    advanceSearchEquipmentList
  )
}

// advance market search equipment type 
function* advanceMarketEquipmentList(data) {
  let advanceMarketEquipmentTypeList = yield advanceMarketEquipmentApi(data)
  if (advanceMarketEquipmentTypeList.success && _.has(advanceMarketEquipmentTypeList, "data.data")) {
    yield put(
      advanceMarketEquipmentListResponse(
        SUCCESS_ADVANCE_MARKET_EQUIPMENT_LIST,
        advanceMarketEquipmentTypeList.data
      )
    )
  } else {
    yield put(
      advanceMarketEquipmentListResponse(
        ERROR_ADVANCE_MARKET_EQUIPMENT_LIST,
        advanceMarketEquipmentTypeList.data
      )
    )
  }
}
export function* advanceMarketEquipmentListWatcher() {
  yield takeLatest(
    ADVANCE_MARKET_EQUIPMENT_LIST,
    advanceMarketEquipmentList
  )
}


// data source list
function* dashboardDataSorceList(data) {
  let dashboardDataSorceList = yield dataSourceApi(data)
  if (dashboardDataSorceList.success && _.has(dashboardDataSorceList, "data.data")) {
    yield put(
      dashboardDataSorceListResponse(
        SUCCESS_DASHBOARD_DATA_SOURCE_LIST,
        dashboardDataSorceList.data
      )
    )
  } else {
    yield put(
      dashboardDataSorceListResponse(
        ERROR_DASHBOARD_DATA_SOURCE_LIST,
        dashboardDataSorceList.data
      )
    )
  }
}
export function* dashboardDataSorceListWatcher() {
  yield takeLatest(
    DASHBOARD_DATA_SOURCE_LIST,
    dashboardDataSorceList
  )
}

// user list 
function* dashboardUserList(data) {
  let dashboardUserListData = yield (dashboardUserListApi(data))
  if (dashboardUserListData.success && _.has(dashboardUserListData, "data.data")) {
    yield put(dashboardUserListResonse(
      SUCCESS_DASHBOARD_USER_LIST,
      dashboardUserListData.data
    ))
  } else {
    yield put(dashboardUserListResonse(
      ERROR_DASHBOARD_USER_LIST,
      dashboardUserListData.data
    ))
  }
}
export function* dashboardUserListWatcher() {
  yield takeLatest(
    DASHBOARD_USER_LIST,
    dashboardUserList
  )
}

// user list for the select carrier 
function* carrierUserList(data) {
  let carrierUserListData = yield carrierUserListApi(data)
  if (carrierUserListData.success && _.has(carrierUserListData, "data")) {
    yield put(carrierUserListResonse(
      SUCCESS_CARRIER_USER_LIST,
      carrierUserListData.data
    ))
  } else {
    yield put(carrierUserListResonse(ERROR_CARRIER_USER_LIST, carrierUserListData.data))
  }
}
export function* carrierUserListWatcher() {
  yield takeLatest(CARRIER_USER_LIST, carrierUserList)
}

//lanes & map data 
function* getLanesAndMap(data) {
  let getLanesAndMapData = yield getLanesAndMapApi(data)
  if (getLanesAndMapData.success && _.has(getLanesAndMapData, "data.data")) {
    yield put(
      lanesAndMapListResponse(
        SUCCESS_LANES_AND_MAP_LIST,
        getLanesAndMapData.data
      ))
  }
  else {
    yield put(
      lanesAndMapListResponse(
        ERROR_LANES_AND_MAP_LIST,
        getLanesAndMapData.data))
  }
}
export function* getLanesAndMapWatcher() {
  yield takeLatest(
    LANES_AND_MAP_LIST,
    getLanesAndMap
  )
}


// notes delete saga
function* noteDeleteDashboardList(data) {
  let noteDeleteDashboardList = yield noteDeleteApi(data)
  if (noteDeleteDashboardList.success && _.has(noteDeleteDashboardList, "data.data")) {
    yield put(
      notesDeleteDashboardListResponse(
        SUCCESS_NOTES_DELETE_DASHBOARD,
        noteDeleteDashboardList.data
      )
    )
  } else {
    yield put(
      notesDeleteDashboardListResponse(
        ERROR_NOTES_DELETE_DASHBOARD,
        noteDeleteDashboardList.data
      )
    )
  }
}
export function* noteDeleteDashboardListWatcher() {
  yield takeLatest(
    NOTES_DELETE_DASHBOARD,
    noteDeleteDashboardList
  )
}

// Get_Headquarters_List
function* getHeadquaterSearchValueListData(userData) {
  let headquaterListData = yield headquartersSearchListApi(userData);
  if (headquaterListData.success && _.has(headquaterListData, "data.data")) {
    yield put(
      headquartersSearchValueListResponse(
        SUCCESS_HEADQUARTERS_SEARCH_VALUE_LIST,
        headquaterListData.data
      )
    );
  } else {
    yield put(
      headquartersSearchValueListResponse(
        ERROR_HEADQUARTERS_SEARCH_VALUE_LIST,
        headquaterListData.data
      )
    );
  }
}
export function* getHeadquaterSearchValueListDataWatcher() {
  yield takeLatest(HEADQUARTERS_SEARCH_VALUE_LIST, getHeadquaterSearchValueListData);
}


//get destination market list by searching keyword
function* destinationMarketSearchList(data) {
  let destinationMarketSearchListData = yield getDestinationSearchListApi(data);
  if (
    destinationMarketSearchListData.success &&
    _.has(destinationMarketSearchListData, "data.data")
  ) {
    yield put(
      destinationMarketSearchListResponse(
        SUCCESS_DESTINATION_MARKET_SEARCH_LIST,
        destinationMarketSearchListData.data
      )
    );
  } else {
    yield put(
      destinationMarketSearchListData(
        ERROR_DESTINATION_MARKET_SEARCH_LIST,
        destinationMarketSearchListData.data
      )
    );
  }
}
export function* destinationMarketSearchListWatcher() {
  yield takeLatest(DESTINATION_MARKET_SEARCH_LIST, destinationMarketSearchList);
}