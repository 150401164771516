export const DEFAULT_STATE = {
  reloadcarrierData: {},
  reloadreportdownloadData: {},
  selectAllEmailReloadreportdownloadData: {},
  reloadEquipmentData: {},
  reloadDataSourceData: {},
  reloadcarrierdepartureData: {},
  reloadreportdownloaddepartureData:{},
  selectAllEmailReloadreportdownloaddepartureData: {},
  successSaveReloadFiltersData: {},
  getReloadFiltersData: {},
  successUpdateReloadFiltersData: {},
  deleteReloadFiltersData: {},
  defaultReloadFiltersData: {},
  reloadcarrierpcData: {},
  reloadreportdownloadPCListData:{},
  selectAllEmailReloadreportdownloadPCListData: {},
};
